import { Stack, SxProps, TextField } from '@mui/material';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

interface NumberInputProps {
  placeholder?: string;
  label?: string;
  value: string;
  onChange: (value: number | undefined) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  sx?: SxProps;
  prefix?: string;
  width?: string;
  min?: number;
  max?: number;
  step?: number;
  error?: boolean;
  allowNegative?: boolean;
}

const NumberInput = forwardRef<HTMLDivElement, NumberInputProps>(
  (
    { placeholder, label, value, onChange, onKeyDown, sx, prefix, width, min = 0, max = 99999, step = 2, error = false, allowNegative = false },
    ref
  ) => {
    const [inputValue, setInputValue] = useState<string>(value);

    const handleValueChange = useCallback(
      (values: { floatValue: number | undefined; formattedValue: string; value: string }) => {
        if (allowNegative) {
          if (values.value === '-') {
            setInputValue('-');
            onChange(-0);
            return;
          }
        }
        if (
          values.floatValue === undefined ||
          ((values.floatValue || values.floatValue === 0) && values.floatValue >= min && values.floatValue <= max)
        ) {
          setInputValue(values.formattedValue);
          onChange(values.floatValue);
        }
      },
      [onChange, min, max, inputValue]
    );

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    return (
      <Stack ref={ref} spacing={0.5} sx={{ width: width || '90px' }}>
        <NumericFormat
          inputRef={ref}
          customInput={TextField}
          variant="outlined"
          label={label}
          size="small"
          value={inputValue}
          onValueChange={(values, sourceInfo) => {
            //Only trigger on value change from user input
            if (sourceInfo.source === 'event') {
              handleValueChange(values);
            }
          }}
          placeholder={placeholder}
          thousandSeparator
          valueIsNumericString
          prefix={prefix}
          decimalScale={step}
          fixedDecimalScale
          onKeyDown={onKeyDown}
          allowNegative={allowNegative}
          sx={sx}
          autoComplete="off"
          isAllowed={values => {
            const { floatValue } = values;
            return (floatValue ?? 0) >= min && (floatValue ?? 0) <= max;
          }}
          error={error}
        />
      </Stack>
    );
  }
);

export default NumberInput;
