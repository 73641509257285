import { config } from '@services/context';
import { Product } from '@shared/protos/product';
import { useCallback, useMemo } from 'react';

export interface ProductSelectionProps {
  userProducts: Product[];
  selectedColIds: string[];
  selectedUserProducts: Product[];
  toggleColumn: (symbol: string) => void;
  addColumn?: (symbol: string) => void;
  maxProductsLimit?: number;
}

export function useProductSelection({
  userProducts,
  selectedColIds,
  selectedUserProducts,
  toggleColumn,
  addColumn,
  maxProductsLimit,
}: ProductSelectionProps) {
  const allProducts = userProducts.filter(
    product => !product.underlying_symbol || (product.calendar_type === 'spread' && product.tenor_frequency !== 'quarterly')
  );

  const allSelectedProducts = useMemo(() => allProducts.filter(elem => selectedColIds.includes(elem.symbol)), [selectedColIds, allProducts]);

  const getProductCount = useCallback(
    (product: Product): number => {
      let columnSymbol = product.symbol;
      if (product.underlying_symbol && product.calendar_type !== 'spread') {
        columnSymbol = product.underlying_symbol;
      }
      if (product.calendar_type === 'spread' && product.tenor_frequency === 'quarterly') {
        const tail = product.symbol.substring(product.underlying_symbol.length, product.symbol.length);
        const productName = product.underlying_symbol.substring(0, product.underlying_symbol.length - 1);
        columnSymbol = `${productName}${tail}`;
      }

      return userProducts.reduce((count, product) => {
        let productColumnSymbol = product.symbol;
        if (product.underlying_symbol && product.calendar_type !== 'spread') {
          productColumnSymbol = product.underlying_symbol;
        }
        if (product.calendar_type === 'spread' && product.tenor_frequency === 'quarterly') {
          const tail = product.symbol.substring(product.underlying_symbol.length, product.symbol.length);
          const productName = product.underlying_symbol.substring(0, product.underlying_symbol.length - 1);
          productColumnSymbol = `${productName}${tail}`;
        }

        return productColumnSymbol === columnSymbol ? count + 1 : count;
      }, 0);
    },
    [userProducts]
  );

  const isSelectionDisabled = useCallback(
    (product: Product) => {
      const isCurrentlySelected = allSelectedProducts.find(elem => elem.symbol === product.symbol);
      if (isCurrentlySelected) return false;

      const newProductCount = getProductCount(product);
      return selectedUserProducts.length + newProductCount > (maxProductsLimit ?? config.maxProductsLimit);
    },
    [allSelectedProducts, selectedUserProducts.length, getProductCount, maxProductsLimit]
  );

  const onChangeValues = useCallback(
    (selectedValues: Product[] | null) => {
      const deltaValues = allSelectedProducts.filter(({ symbol }) => !selectedValues?.find(elem => elem.symbol === symbol));
      if (deltaValues.length) {
        deltaValues.forEach(removedValue => {
          toggleColumn(removedValue.symbol);
        });
      }

      if (selectedValues?.length && addColumn) {
        selectedValues.forEach(selectedValue => {
          addColumn(selectedValue.symbol);
        });
      }
    },
    [toggleColumn, addColumn, allSelectedProducts]
  );

  return {
    allProducts,
    allSelectedProducts,
    getProductCount,
    isSelectionDisabled,
    onChangeValues,
  };
}
