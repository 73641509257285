import CircularProgress from '@mui/material/CircularProgress';

type Props = { size?: number };

export default function PageLoadProgress({ size = 24 }: Props) {
  return (
    <CircularProgress
      size={size}
      sx={{
        right: 'calc(50% - 12px)',
        position: 'absolute',
        top: '10%',
      }}
    />
  );
}
