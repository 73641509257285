import { orderMatchesFilters } from '@features/admin/trading/tradingAdminUtils';
import { useStreamV2Orders } from '@hooks/useStreamV2Orders';
import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { FilterType } from '@protos/filter';
import { Order, OrderState, OrderType } from '@protos/v2/order';
import { priceFormatterService } from '@services/PriceFormatterService';
import { BlotterWidget } from '@shared/components/BotterWidget';
import { GetRowIdParams, GridApi } from 'ag-grid-enterprise';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ALL_ORDERS_GRID_COLUMNS } from '../constants/AllOrdersGridConsts';
import { useLaddersApi } from '../hooks/useLaddersApi';
import { CancelOrderCellRenderer } from './CancelOrderCellRenderer';
import { toastifyService } from '@services/ToastifyService';
import { useTradableProductSymbolsContext } from '@shared/contexts/TradableProductSymbolsProvider';

const formatOrder = (order: Order) => ({
  ...order,
  amount: priceFormatterService.removeTrailZeros(order.amount),
  executed_amount: order.executed_amount ? priceFormatterService.removeTrailZeros(order.executed_amount) : '',
  price: order.price ? order.price : '',
  executed_avg_price: order.executed_avg_price ? order.executed_avg_price : '',
});

interface AllOrdersGridProps {
  selectedAccount: string;
  setOrderState: (orderState: OrderState[]) => void;
  setSelectedProductSymbol: (productSymbol: string) => void;
  maxHeight?: number;
}

const FILTER_KEYS = {
  ACCOUNT_ID: 'account_id',
  ORDER_STATE: 'order_state',
  PRODUCT_SYMBOL: 'product_symbol',
} as const;

export const AllOrdersGrid = React.memo(({ selectedAccount, setOrderState, setSelectedProductSymbol, maxHeight }: AllOrdersGridProps) => {
  const { getAllOrdersLoader } = useLaddersApi();
  const { urlParams } = useURLSearchParams();
  const products = useTradableProductSymbolsContext();
  const nonSpreadProducts = useMemo(() => products.filter(product => !product.includes('spr')), [products]);
  const userSelectedAccount = useMemo(() => selectedAccount, [selectedAccount]);
  const [isReadyToSubscribe, setIsReadyToSubscribe] = useState<boolean>(false);
  const blotterApiRef = useRef<GridApi<Order> | null>(null);
  const isFitCellContents = useMemo(() => window.innerWidth < 1900, []);
  const [playSound, setPlaySound] = useState(true);
  const lastPlayedRef = useRef<number>(0);
  const COOLDOWN_MS = 1000;

  const columns = useMemo(
    () => [
      ...ALL_ORDERS_GRID_COLUMNS,
      {
        field: 'cancel' as keyof Order,
        headerName: 'Cancel',
        width: 100,
        headerTooltip: 'Cancel',
        cellRenderer: CancelOrderCellRenderer,
        cellRendererParams: {
          accountId: userSelectedAccount,
        },
      },
    ],
    [userSelectedAccount]
  );

  const dataLoader = getAllOrdersLoader();

  const onGetGridApi = useCallback((gridApi: GridApi<Order>) => {
    blotterApiRef.current = gridApi;
    setIsReadyToSubscribe(true);
  }, []);
  const getRowId = useCallback((params: GetRowIdParams<Order>) => params.data?.id.toString(), []);

  const allOrdersDataTransformer = useCallback((data: Order[]) => data, [userSelectedAccount]);

  const handleSoundToggle = useCallback((enabled: boolean) => {
    setPlaySound(enabled);
  }, []);

  useEffect(() => {
    setOrderState(urlParams[FILTER_KEYS.ORDER_STATE] ? [urlParams[FILTER_KEYS.ORDER_STATE]] : [OrderState.NEW, OrderState.PARTIALLY_FILLED]);
    setSelectedProductSymbol(urlParams[FILTER_KEYS.PRODUCT_SYMBOL] || '');
  }, [urlParams]);

  const onStreamOrder = useCallback(
    (order: Order) => {
      const blotterApi = blotterApiRef.current;
      if (!blotterApi || order.order_type !== OrderType.LIMIT_MAKER || order.account_id !== userSelectedAccount) return;

      const node = blotterApi.getRowNode(order.id.toString());
      const isRowInGrid = !!node;
      if (!isRowInGrid && !orderMatchesFilters(order, urlParams)) {
        return;
      }

      const transformedOrder = formatOrder(order);
      if (isRowInGrid) {
        if (playSound && (transformedOrder.order_state === OrderState.FILLED || transformedOrder.order_state === OrderState.PARTIALLY_FILLED)) {
          toastifyService.showTradeConfirmation({
            id: order.id,
            method: 'order',
            error: false,
            message: {
              Order: order,
            },
          });
          const now = Date.now();
          if (now - lastPlayedRef.current >= COOLDOWN_MS) {
            try {
              const audio = new Audio('/assets/filled.mp3');
              audio.play().catch(error => {
                console.error(error);
              });
              lastPlayedRef.current = now;
            } catch (error) {
              console.error(error);
            }
          }
        }
        if (isRowInGrid && !orderMatchesFilters(order, urlParams)) {
          blotterApi.applyTransactionAsync({ remove: [transformedOrder] });
        } else {
          blotterApi.applyTransactionAsync({ update: [transformedOrder] });
        }
        return;
      }

      if (!isRowInGrid && orderMatchesFilters(order, urlParams)) {
        blotterApi.applyTransactionAsync({ add: [transformedOrder] });
      }

      const newRowNode = blotterApi.getRowNode(order.id.toString());
      if (newRowNode) {
        blotterApi.flashCells({ rowNodes: [newRowNode], fadeDuration: 2000 });
      }
    },
    [userSelectedAccount, playSound, urlParams]
  );

  useStreamV2Orders(onStreamOrder, isReadyToSubscribe);

  if (!dataLoader) return null;

  const filtersTransformer = (urlParamFilters: { [x: string]: any }) => {
    return {
      [FILTER_KEYS.ACCOUNT_ID]: userSelectedAccount,
      [FILTER_KEYS.ORDER_STATE]: urlParamFilters[FILTER_KEYS.ORDER_STATE] || [],
      [FILTER_KEYS.PRODUCT_SYMBOL]: urlParamFilters[FILTER_KEYS.PRODUCT_SYMBOL] || [],
    };
  };

  if (!nonSpreadProducts.length) {
    return null;
  }

  return (
    <BlotterWidget
      filters={[
        {
          type: FilterType.MULTISELECT,
          name: FILTER_KEYS.ORDER_STATE,
          label: 'Order State',
          options: [OrderState.FILLED, OrderState.PARTIALLY_FILLED, OrderState.CANCELLED, OrderState.EXPIRED, OrderState.CREATED, OrderState.NEW],
        },
        {
          type: FilterType.AUTOCOMPLETE,
          name: FILTER_KEYS.PRODUCT_SYMBOL,
          label: 'Product Symbol',
          options: nonSpreadProducts,
        },
      ]}
      onSoundToggle={handleSoundToggle}
      defaultSoundEnabled={true}
      filtersTransformer={filtersTransformer}
      getRowId={getRowId}
      getGridApi={onGetGridApi}
      dataLoader={dataLoader}
      columnDefs={columns}
      maxHeight={maxHeight ?? 500}
      autoSizeStrategy={isFitCellContents ? { type: 'fitCellContents' } : { type: 'fitGridWidth' }}
      dataTransformer={allOrdersDataTransformer}
    />
  );
});
