import { Box } from '@mui/material';
import { streamV2 } from '@services/context';
import theme from '@shared/themes/darkTheme';
import { useEffect, useState } from 'react';

export const unhandledErrorMessage =
  'An unexpected error has occurred. We apologise for the inconvenience and are working on a fix. Please refresh the page and try again.';

const AppErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const socketV2Error = streamV2.getSocketError();
  const [streamV2Error, setStreamV2Value] = useState(socketV2Error);

  useEffect(() => {
    const handleValueChange = (newValue: { hasError: boolean; message: string } | undefined) => {
      setStreamV2Value(newValue);
    };
    const unsubscribe = streamV2.errorSubscribe(handleValueChange);

    return () => {
      unsubscribe();
    };
  }, []);

  if (streamV2Error?.hasError && streamV2Error?.message.includes('429')) {
    return (
      <Box
        minHeight="100vh"
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color={theme.palette.text.disabled}
      >
        Too many active connections. Please close some tabs using this application and refresh this page to continue.
      </Box>
    );
  }
  return children;
};

export default AppErrorBoundary;
