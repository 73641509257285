import { Divider, Stack, Typography } from '@mui/material';
import { Order } from '@protos/v2/order';
import theme from '@shared/themes/darkTheme';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

type Props = {
  orders: Order[];
};

export const OrderBookChart = ({ orders }: Props) => {
  const aggregatedData = useMemo(() => {
    const groupedOrders: Record<string, Order[]> = {};
    orders.forEach(order => {
      const price = Number(order.price);
      if (!groupedOrders[price]) {
        groupedOrders[price] = [];
      }
      groupedOrders[price].push(order);
    });

    return Object.keys(groupedOrders).map(price => ({
      price: Number(price),
      orders: groupedOrders[price],
      totalAmount: groupedOrders[price].reduce((sum, order) => sum + Number(order.amount), 0),
      side: groupedOrders[price][0].side, // All stacked orders at one price should be at the same side
    }));
  }, [orders]);

  return (
    <Stack direction="column" flex={2}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={aggregatedData} margin={{ top: 10, right: 30, left: 0, bottom: 10 }}>
          <XAxis
            dataKey="price"
            type="number"
            domain={([dataMin, dataMax]) => {
              return [dataMin - 0.01, dataMax + 0.01];
            }}
            tickFormatter={value => value.toFixed(2)}
          />
          <YAxis dataKey="totalAmount" />
          <Tooltip
            cursor={{ fill: theme.palette.background.darker, fillOpacity: 0.5 }}
            content={({ active, payload }) => {
              if (!active || !payload || !payload.length) return null;
              const { orders } = payload[0].payload;
              return (
                <Stack sx={{ backgroundColor: theme.palette.background.darker, padding: 2, borderRadius: '5px' }}>
                  <Typography fontSize={13} fontWeight={800}>
                    Price: {payload[0].payload.price.toFixed(2)}
                  </Typography>
                  {orders.length > 1 && (
                    <Typography fontSize={13} fontWeight={800}>
                      Total Amount: {payload[0].payload.totalAmount}
                    </Typography>
                  )}
                  <Stack gap={'5px'} sx={{ marginTop: '5px' }}>
                    {(orders as Order[]).map(order => (
                      <div key={order.id}>
                        <Divider />
                        <Typography fontSize={10}>Account ID: {order.account_id}</Typography>
                        <Typography fontSize={10}>Amount: {order.amount}</Typography>
                        <Typography fontSize={10}>Expires: {format(new Date(order.expiry_timestamp!), 'yyyy-MM-dd HH:mm')}</Typography>
                      </div>
                    ))}
                  </Stack>
                </Stack>
              );
            }}
          />
          <Bar dataKey="totalAmount" barSize={aggregatedData.length < 2 ? '20%' : undefined}>
            {aggregatedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.side === 'buy' ? theme.palette.success.main : theme.palette.error.main} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  );
};
