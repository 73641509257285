import { useAdminApi } from '@hooks/useAdminApi';
import { useSnapshotsV2Streaming } from '@hooks/useSnapshotsV2Streaming';
import { Box, FormControlLabel, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Snapshot } from '@protos/v2/snapshots';
import theme from '@shared/themes/darkTheme';
import { formatDatetime } from '@utils/date';
import { useRef, useState } from 'react';
import ReactJson from 'react-json-view';
import { useTradingKillSwitch } from '../../hooks/useTradingKillSwitch';
import { KillSwitchButton } from '../KillSwitchButton';
import { TradingHedgerParamsForm } from './TradingHedgerParamsForm';
import { useTradingHedgerForm } from './useTradingHedgerForm';

interface Snapshots {
  products: Record<string, Snapshot>;
  selected: string;
}

export const TradingHedger = () => {
  const { apiClient } = useAdminApi();
  const { killSwitch, onToggleKillSwitch } = useTradingKillSwitch('hedger');
  const { formData } = useTradingHedgerForm();

  const snapshots = useRef<Snapshots>({ products: {}, selected: '' });
  const [_, render] = useState<any>({});

  const product = snapshots.current.selected;
  const snapshot = snapshots.current.products[product];
  const tabs = Object.keys(snapshots.current.products).sort();

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    snapshots.current.selected = newValue;
    render({});
  };

  useSnapshotsV2Streaming((snap: Snapshot) => {
    if (typeof snap === 'string') return; // Message about successful connection, skip it

    snapshots.current.products[snap.product] = snap;
    if (!snapshots.current.selected) {
      snapshots.current.selected = snap.product;
    }
    render({});
  }, !!apiClient);

  return (
    <Stack gap={2}>
      {killSwitch ? (
        <Box
          display="flex"
          flexDirection="row"
          flex={1}
          width="100%"
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{ backgroundColor: theme.palette.background.darker, padding: 1 }}
        >
          {formData && <Typography fontSize={11}>Params version: {formData.id}</Typography>}
          <Stack direction="column" alignItems="flex-end">
            <FormControlLabel
              control={
                <KillSwitchButton
                  sx={{ m: 1, right: -12 }}
                  checked={killSwitch.active}
                  onChange={(event, checked) => onToggleKillSwitch(event, checked)}
                />
              }
              label={`Kill Switch: ${killSwitch.active ? 'On' : 'Off'}`}
            />
            <Typography style={{ fontSize: 11, fontWeight: 800, textAlign: 'center' }}>
              Last triggered by {!killSwitch.user.full_name ? killSwitch.user.id : killSwitch.user.full_name} at{' '}
              {formatDatetime(killSwitch.timestamp)}
            </Typography>
          </Stack>
        </Box>
      ) : null}
      <Box display="flex" flex={1} justifyContent="center" gap={2}>
        <TradingHedgerParamsForm />
        <Box style={{ padding: 10, backgroundColor: theme.palette.background.darker, minWidth: '50%' }}>
          <Tabs value={product} onChange={onTabChange} variant="scrollable">
            {tabs.map(tab => (
              <Tab key={tab} label={tab} value={tab} style={{ fontSize: 11 }} />
            ))}
          </Tabs>
          {snapshot ? <ReactJson src={snapshot} theme="google" style={{ fontSize: 11 }} name="snapshot" /> : 'Loading snapshot...'}
        </Box>
      </Box>
    </Stack>
  );
};
