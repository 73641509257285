import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { VolumeOff, VolumeUp } from '@mui/icons-material';
import { Box, Divider, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconComponent } from '@shared/components/IconComponent';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { Filter, FilterType } from '@shared/protos/filter';
import { toLocaleISOString } from '@utils/date';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularLoader } from './CircularLoader';
import { FilterComponent } from './FilterComponent';
import { Search } from './Search';

interface FilterPanelProps {
  filters?: Filter[];
  disableSearch?: boolean;
  children?: React.ReactNode;
  title?: string;
  onRefreshClicked?: () => void;
  loading?: boolean;
  sticky?: boolean;
  onSoundToggle?: (enabled: boolean) => void;
  defaultSoundEnabled?: boolean;
  setInitialURL?: boolean;
  onMultiFilterChange?: (name: string, values: string[]) => void;
}

type FilterContainerProps = {
  stickyHeader?: boolean;
} & React.ComponentProps<typeof Paper>;

const FilterContainer = styled(Paper, {
  shouldForwardProp: prop => prop !== 'stickyHeader',
})<FilterContainerProps>(({ theme, stickyHeader }) => ({
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  ...(stickyHeader && {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  }),
}));

export const FilterPanel = ({
  filters,
  disableSearch,
  title,
  onRefreshClicked,
  children,
  loading,
  sticky,
  onSoundToggle,
  defaultSoundEnabled,
  setInitialURL,
  onMultiFilterChange,
}: FilterPanelProps) => {
  const { urlParams, resetURLSearchParams, setURLSearchParam } = useURLSearchParams();
  const user = useUserContext();

  const [isLoadingFilters, setIsLoadingFilters] = useState(true);
  const [soundEnabled, setSoundEnabled] = useState(defaultSoundEnabled);

  const filtersList = useMemo(
    () => filters?.filter(filter => !filter.restrictedByPermission || user?.permissions.includes(filter.restrictedByPermission)),
    [filters, user]
  );

  const defaultFilterParams = useMemo(
    () =>
      filtersList?.reduce((acc, filter) => {
        const { name, defaultValue } = filter;
        if (defaultValue && typeof defaultValue !== 'object') acc[name] = defaultValue;
        else if (defaultValue && typeof defaultValue === 'object') {
          const { start, end } = defaultValue;
          acc['start'] = toLocaleISOString(start);
          if (end) acc['end'] = toLocaleISOString(end);
        }

        return acc;
      }, {}),
    [filtersList]
  );

  const isValidURLParams = useMemo(
    () =>
      urlParams
        ? filtersList?.every(filter => {
            if ([FilterType.AUTOCOMPLETE, FilterType.SELECT].includes(filter.type)) {
              const filterValue = urlParams[filter.name];
              if (filterValue && !filter.options?.includes(filterValue)) {
                return false;
              }
              return true;
            }
            return true;
          })
        : true,
    [urlParams, filtersList]
  );

  const [filterParams, setFilterParams] = useState<Record<string, any>>(defaultFilterParams || {});

  const onFilterChange = useCallback(
    (newFilter: Record<string, any>) => {
      setIsLoadingFilters(true);
      const newFilterParams = { ...filterParams, ...newFilter };
      setFilterParams(newFilterParams);
      resetURLSearchParams(newFilterParams);
      setIsLoadingFilters(false);
    },
    [filterParams, resetURLSearchParams]
  );

  const onSearchChange = useCallback(
    (val: string) => {
      setIsLoadingFilters(true);
      const newFilterParams = { ...filterParams, search: val };
      setFilterParams(newFilterParams);
      setURLSearchParam('search', val);
      setIsLoadingFilters(false);
    },
    [filterParams, setURLSearchParam]
  );

  const handleSoundToggle = () => {
    const newState = !soundEnabled;
    setSoundEnabled(newState);
    onSoundToggle?.(newState);
  };

  useEffect(() => {
    if (setInitialURL) {
      resetURLSearchParams(defaultFilterParams || {});
    }
  }, [defaultFilterParams]);

  useEffect(() => {
    if (!isValidURLParams) {
      resetURLSearchParams(defaultFilterParams || {});
    } else {
      const transformedFilterParams = { ...defaultFilterParams, ...urlParams };

      if (JSON.stringify(filterParams) !== JSON.stringify(transformedFilterParams)) {
        setFilterParams(transformedFilterParams);
      }
    }

    setIsLoadingFilters(false);
  }, [urlParams, isValidURLParams, defaultFilterParams, resetURLSearchParams, filterParams]);

  return (
    <FilterContainer square stickyHeader={sticky} data-testid="filter-panel">
      <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px', marginBottom: '-3px' }}>
        <Stack direction="row" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center' }}>
          {title && <Typography variant="h4">{title}</Typography>}
          {title && <Divider orientation="vertical" style={{ marginLeft: 5 }} />}
          {filtersList?.map(filter => (
            <FilterComponent
              key={filter.name}
              filter={filter}
              filterParams={filterParams}
              onFilterChange={onFilterChange}
              onMultiFilterChange={onMultiFilterChange}
            />
          ))}
          {children}
          {onRefreshClicked && (
            <>
              <Divider orientation="vertical" style={{ marginLeft: 5 }} />
              <Tooltip title="Refresh">
                <IconButton onClick={onRefreshClicked} size="small" sx={{ padding: 0 }}>
                  <IconComponent name="ri-refresh-line" />
                </IconButton>
              </Tooltip>
            </>
          )}
          {onSoundToggle && (
            <IconButton onClick={handleSoundToggle} size="small" sx={{ ml: 1 }}>
              {soundEnabled ? <VolumeUp /> : <VolumeOff />}
            </IconButton>
          )}
        </Stack>
        {!disableSearch && (
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Search
              onSearch={onSearchChange}
              value={filterParams.search || ''}
              maxWidth="170px"
              endAdornment={<CircularLoader loading={loading || isLoadingFilters} />}
            />
          </Box>
        )}
      </Box>
    </FilterContainer>
  );
};
