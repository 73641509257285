import { useEffect, useRef } from 'react';

import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Filter } from '@protos/filter';
import { useDataLoader } from '@shared/hooks/useDataLoader';
import { DataApi } from '@utils/data';

interface UseURLFilteredDataParams<T> {
  dataLoader: DataApi<T>;
  filters: Filter[];
  disableURLSearchParams?: boolean;
  filtersTransformer?: (urlParamFilters: { [x: string]: any }) => { [x: string]: any };
}

export const useURLFilteredData = <T>({ dataLoader, filters, disableURLSearchParams = false, filtersTransformer }: UseURLFilteredDataParams<T>) => {
  const { urlParams } = useURLSearchParams();
  const { data, fetchData, fetchMoreData } = useDataLoader(dataLoader);

  const hasFetchedOnceRef = useRef(false);
  const lastParamsRef = useRef<string | null>(null);

  useEffect(() => {
    if (disableURLSearchParams && hasFetchedOnceRef.current) {
      return;
    }

    const { search: urlParamSearch, ...urlParamFilters } = urlParams;
    const newUrlParamFilters = filtersTransformer ? filtersTransformer(urlParamFilters) : urlParamFilters;
    const searchValue = urlParamSearch || '';

    const currentParams = JSON.stringify({ filters, searchValue, newUrlParamFilters });

    if (lastParamsRef.current === currentParams) {
      return;
    }

    lastParamsRef.current = currentParams;

    if (filters.length > 0) {
      fetchData(newUrlParamFilters, searchValue);
    } else {
      fetchData();
    }

    if (disableURLSearchParams) {
      hasFetchedOnceRef.current = true;
    }
  }, [urlParams, fetchData, filters, filtersTransformer]);

  return {
    data,
    fetchData,
    fetchMoreData,
  };
};
