import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Box, Stack } from '@mui/material';
import { toastifyService } from '@services/ToastifyService';
import { Select } from '@shared/components/Select';
import theme from '@shared/themes/darkTheme';
import { useEffect, useMemo, useState } from 'react';
import { OrderBookChart } from './OrderBookChart';
import { OrderBookTable } from './OrderBookTable';
import { useOnyxBookData } from './useOnyxBookData';

export const TradingOnyxBook = () => {
  const { urlParams, setURLSearchParam, resetURLSearchParams } = useURLSearchParams();
  const { products, symbolsPerProduct, ordersPerSymbol } = useOnyxBookData();
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedSymbol, setSelectedSymbol] = useState('');

  const ordersToDisplay = useMemo(() => ordersPerSymbol.get(selectedSymbol) || [], [selectedSymbol, ordersPerSymbol]);

  useEffect(() => {
    if (products.length > 0 && !selectedProduct) {
      const initParams: Record<string, string> = {};
      if (urlParams.product && products.includes(urlParams.product)) {
        initParams.product = urlParams.product;
      } else {
        initParams.product = products[0];
      }

      if (urlParams.symbol && symbolsPerProduct.get(initParams.product)?.includes(urlParams.symbol)) {
        initParams.symbol = urlParams.symbol;
      } else {
        initParams.symbol = symbolsPerProduct.get(initParams.product)![0];
      }

      setSelectedProduct(initParams.product);
      setSelectedSymbol(initParams.symbol);
      resetURLSearchParams(initParams);
    } else if (selectedProduct && !symbolsPerProduct.get(selectedProduct)) {
      setSelectedProduct(products[0]);
      setSelectedSymbol('');
      resetURLSearchParams({ product: products[0] });
      toastifyService.showError('Chosen product is no longer available');
    }
  }, [products]);

  const onProductChange = (newProduct: string) => {
    setSelectedProduct(newProduct);
    setURLSearchParam('product', newProduct);
  };

  const onSymbolChange = (newSymbol: string) => {
    setSelectedSymbol(newSymbol);
    setURLSearchParam('symbol', newSymbol);
  };

  return (
    <Stack gap={2} width="100%">
      <Box display="flex" flexDirection="row" sx={{ backgroundColor: theme.palette.background.darker, padding: 1, minWidth: '100%' }}>
        <Select label="Product" value={selectedProduct} options={products} onChange={onProductChange} />
        {selectedProduct && symbolsPerProduct.get(selectedProduct) && (
          <Select label="Symbol" value={selectedSymbol} options={symbolsPerProduct.get(selectedProduct)!} onChange={onSymbolChange} />
        )}
      </Box>
      <Box display="flex" flex={1} style={{ padding: 10, minWidth: '100%' }} justifyContent="center" gap={2}>
        {selectedSymbol && <OrderBookChart orders={ordersToDisplay} />}
        {selectedSymbol && <OrderBookTable data={ordersToDisplay} />}
      </Box>
    </Stack>
  );
};
