import { RollingRowSettings, Row } from '../shared/types';

export type PricesRowValue = {
  sell: string;
  mid: string;
  buy: string;
};
export type PricesRow = Row & {
  [K in Exclude<string, keyof Row>]: PricesRowValue;
};

export type PricesWidgetPayload = {
  selectedColumns?: string[];
  selectedRows?: string[];
  columnsOrder?: string[];
  userRollingRowSettings?: RollingRowSettings;
  isOverrideRolling?: boolean;
};

export enum BidAskType {
  Bid = 'bid',
  Ask = 'ask',
}
