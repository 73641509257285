import { Widget } from '@features/dashboard/types';

export type DashboardSettings = {
  layouts: Record<string, any>;
  widgets: Widget[];
};

export type Dashboard = {
  id: number;
  name: string;
  json: DashboardSettings;
  last_modified: string;
  websocket_uid: string;
  user_id: string;
};

// Handle dashboard updates
export class Dashboards {
  dashboards: Dashboard[];
  messageType: string;

  constructor(dashboards: Dashboard[] | Dashboard, messageType: string) {
    if (Array.isArray(dashboards)) {
      this.dashboards = dashboards;
      this.messageType = 'snapshot';
    } else {
      this.dashboards = [dashboards];
      this.messageType = messageType;
    }
  }

  get isSnapshot(): boolean {
    return this.messageType === 'snapshot';
  }

  get isUpdate(): boolean {
    return this.messageType === 'update';
  }

  get isCreate(): boolean {
    return this.messageType === 'create';
  }

  get isDelete(): boolean {
    return this.messageType === 'delete';
  }
}
