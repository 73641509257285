import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { memo, useEffect, useRef } from 'react';
import { Row } from '../types';

type FlashingCellProps = {
  value: ICellRendererParams<Row, number>['value'];
  valueFormatted: ICellRendererParams<Row, number>['valueFormatted'];
  eGridCell?: HTMLElement;
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: 11,
})) as typeof Typography;

const highValueClassName = 'high-value';
const lowValueClassName = 'low-value';

export function getCellClassName(prevValue: number | null | undefined, newValue: number | null | undefined): string | undefined {
  const prevValNum = Number(prevValue) || 0;
  const newValNum = Number(newValue) || 0;

  if (prevValNum < newValNum) {
    return highValueClassName;
  }

  if (prevValNum > newValNum) {
    return lowValueClassName;
  }

  return;
}

export function FlashingCell({ value, valueFormatted, eGridCell }: FlashingCellProps) {
  const lastValueRef = useRef<number | null | undefined>(null);
  const firstRender = useRef<boolean>(true);

  useEffect(() => {
    if (firstRender.current) {
      lastValueRef.current = value;
      firstRender.current = false;
      return;
    }

    if (value === null || value === undefined) {
      eGridCell?.classList.remove(highValueClassName, lowValueClassName, 'teal-cell');
      return;
    }

    const animationClassName = getCellClassName(lastValueRef.current, value);
    if (!animationClassName || !eGridCell) return;

    eGridCell.classList.remove(highValueClassName, lowValueClassName, 'teal-cell');

    let timeoutId: NodeJS.Timeout;

    //requestAnimationFrame used so the animation is added to the next frame after removing the existing classes
    const frameId = requestAnimationFrame(() => {
      eGridCell.classList.add(animationClassName);

      const resetAnimation = () => {
        eGridCell.classList.remove(animationClassName);
        eGridCell.classList.add('teal-cell');
      };
      timeoutId = setTimeout(resetAnimation, 2000);
    });

    lastValueRef.current = value;

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      cancelAnimationFrame(frameId);
      eGridCell.classList.remove(highValueClassName, lowValueClassName, 'teal-cell');
    };
  }, [eGridCell, value]);

  if (value == null) return null;

  return <StyledTypography>{valueFormatted ?? value}</StyledTypography>;
}
const MemoFlashingCell = memo(FlashingCell);

export default function FlashingCellRenderer({ value, valueFormatted, eGridCell }: ICellRendererParams<Row, number>) {
  return <MemoFlashingCell value={value} valueFormatted={valueFormatted} eGridCell={eGridCell} />;
}
