import { WidgetType } from '@features/dashboard/enums';
import Settings from '../settings/Settings';
import Widget, { Content, Header } from '../Widget';
import { ReportsWidgetPayload } from './types';
import { WidgetProps } from '@features/dashboard/types';
import { Box, CircularProgress } from '@mui/material';
import { ReportsList } from './components/ReportsList';
import { useReports } from './hooks/useReports';

type Props = WidgetProps & {
  payload?: ReportsWidgetPayload;
};

export const Reports = ({ payload, ...otherProps }: Props) => {
  const { id, title } = otherProps;
  const {
    availableReports,
    currentReport,
    currentSelected,
    isVisible,
    isLoading,
    onReportClick,
    onToggleReportsList,
    onIframeLoad,
    onParentReportClick,
    open,
  } = useReports(id, payload?.selectedReport);

  const renderContent = () => {
    if (!currentReport) {
      return (
        <Box width="100%" alignSelf="center" textAlign="center" justifySelf="center">
          Click a report to display it here
        </Box>
      );
    }

    return (
      <Box position="relative" width="100%" height="100%">
        {isLoading && (
          <Box
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            bgcolor="rgba(0, 0, 0, 0.7)"
            zIndex={1}
          >
            <CircularProgress />
          </Box>
        )}
        <iframe src={currentReport} width="100%" height="100%" style={{ border: 'none' }} onLoad={onIframeLoad} />
      </Box>
    );
  };

  return (
    <>
      <Widget id={id} type={WidgetType.Reports} title={title}>
        <Header title={title}>
          <Settings id={`reports-widget-${id}`} widgetId={id} />
        </Header>
        <Content>
          <Box display={'flex'} flexDirection={'row'} height={'100%'}>
            <ReportsList
              availableReports={availableReports}
              currentSelected={currentSelected}
              isVisible={isVisible}
              onReportClick={onReportClick}
              onToggleReportsList={onToggleReportsList}
              onParentReportClick={onParentReportClick}
              open={open}
            />
            {renderContent()}
          </Box>
        </Content>
      </Widget>
    </>
  );
};
