import { createFilterOptions, darken, lighten, styled } from '@mui/material';
import { Product, ProductTenor } from '@shared/protos/product';
import { RollingRowSettings } from '../shared/types';

export const getDisabledProductCheckboxTooltipText = (product: Product, currentCount: number, newCount: number, limit: number) => {
  return (
    `Adding ${product.full_name} would add ${newCount} products, bringing the total to ${currentCount + newCount}. ` +
    `The maximum limit is ${limit} products.`
  );
};

export const getRollingNumberInputMinMaxForSettings = (tenor: keyof RollingRowSettings) => {
  switch (tenor) {
    case 'daily':
      return { min: 0, max: 20 };
    case 'weekly':
      return { min: 0, max: 20 };
    case 'monthly':
      return { min: 0, max: 48 };
    case 'quarterly':
      return { min: 0, max: 16 };
    case 'yearly':
      return { min: 0, max: 4 };
    default:
      return { min: 0, max: 10 };
  }
};

export const ROLLING_LABEL_MAP = { daily: 'Days', weekly: 'Weeks', monthly: 'Months', quarterly: 'Quarters', yearly: 'Years' };

export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.primary.light, 0.85) : darken(theme.palette.primary.main, 0.8),
}));

export const GroupItems = styled('ul')({
  padding: 0,
});

export const filterOptions = createFilterOptions({
  stringify: (option: ProductTenor) => option.display + option.code + option.frequency,
});

export const productFilterOptions = createFilterOptions({
  stringify: (option: Product) => option.full_name + option.product_group + option.product_type + option.symbol,
});
