import { ORDER_STATE_OPTIONS, ORDER_TYPE_OPTIONS, SYMBOL_TYPE_OPTIONS, TRADING_GROUP_OPTIONS } from '@features/admin/trading/tradingAdminProtos';
import { Filter } from '@protos/filter';

import { FilterType } from '@protos/filter';
import { Order } from '@protos/v2/order';
import { priceFormatterService } from '@services/PriceFormatterService';
import { formatDatetimeWithMilliseconds } from '@utils/date';
import { originalAmountFormatter } from '@utils/formatter';
import { ColDef } from 'ag-grid-enterprise';

export const TRADING_ADMIN_ORDER_FILTERS: Filter[] = [
  { type: FilterType.AUTOCOMPLETE, name: 'symbol_type', label: 'Symbol Type', options: SYMBOL_TYPE_OPTIONS },
  { type: FilterType.TEXTFIELD, name: 'symbol', label: 'Symbol' },
  { type: FilterType.TEXTFIELD, name: 'account_id', label: 'Account ID' },
  { type: FilterType.AUTOCOMPLETE, name: 'trading_group', label: 'Trading Group', options: TRADING_GROUP_OPTIONS },
  { type: FilterType.AUTOCOMPLETE, name: 'order_state', label: 'Order State', options: ORDER_STATE_OPTIONS },
  { type: FilterType.AUTOCOMPLETE, name: 'order_type', label: 'Order Type', options: ORDER_TYPE_OPTIONS },
  { type: FilterType.DATETIMERANGE, name: 'date_range', label: 'Date Range' },
];

export const TRADING_ADMIN_ORDER_COLUMNS: ColDef<Order | null, any>[] = [
  {
    field: 'id',
    headerName: 'Order ID',
    headerTooltip: 'Id',
    resizable: true,
  },
  {
    field: 'created_timestamp',
    headerName: 'Created Timestamp',
    headerTooltip: 'Created Timestamp',
    valueFormatter: ({ value }) => (value ? formatDatetimeWithMilliseconds(value) : ''),
    resizable: true,
  },
  {
    field: 'exchange_created_timestamp',
    headerName: 'Timestamp',
    headerTooltip: 'Exchange Created Timestamp',
    valueFormatter: ({ value }) => (value ? formatDatetimeWithMilliseconds(value) : ''),
    resizable: true,
    sort: 'desc',
  },
  {
    field: 'exchange_last_updated_timestamp',
    headerName: 'Last Updated Timestamp',
    valueFormatter: ({ value }) => (value ? formatDatetimeWithMilliseconds(value) : ''),
    resizable: true,
    headerTooltip: 'Exchange Last Updated Timestamp',
  },
  {
    field: 'user.full_name',
    headerName: 'User Full Name',
    resizable: true,
    headerTooltip: 'User Full Name',
  },
  {
    field: 'side',
    headerName: 'Side',
    headerTooltip: 'Side',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
    cellStyle: ({ value }) => ({ color: value.toLowerCase().includes('buy') ? '#4189E8' : '#CD4B4B' }),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    headerTooltip: 'Amount',
    valueFormatter: ({ value, data }) => (value ? originalAmountFormatter(value, data ? +data.original_amount : undefined) : ''),
  },
  {
    field: 'executed_amount',
    headerName: 'Exec. Amount',
    headerTooltip: 'Executedd Amount',
    valueFormatter: ({ value }) => (value ? value.toLocaleString() : ''),
  },
  {
    field: 'symbol',
    headerName: 'Symbol',
    headerTooltip: 'Symbol',
    valueFormatter: ({ value }) => (value ? (typeof value === 'string' ? value.toUpperCase() : `${value.front} / ${value.back}`.toUpperCase()) : ''),
  },
  {
    field: 'price',
    headerName: 'Price',
    headerTooltip: 'Price',
    valueFormatter: ({ value }) => (value === null || value === undefined ? '' : priceFormatterService.formatProductPrice(value)),
  },
  {
    field: 'executed_avg_price',
    headerName: 'Exec. Price',
    headerTooltip: 'Executed Average Price',
    valueFormatter: ({ value }) => (value === null || value === undefined ? '' : priceFormatterService.formatProductPrice(value)),
  },
  {
    field: 'order_type',
    headerName: 'Order Type',
    headerTooltip: 'Order Type',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
  },
  {
    field: 'order_state',
    headerName: 'Order State',
    headerTooltip: 'Order State',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
  },
  {
    field: 'account_id',
    headerName: 'Account Id',
    headerTooltip: 'Account Id',
    resizable: true,
  },
  {
    field: 'trading_group',
    headerName: 'Venue',
    headerTooltip: 'Trading Group',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
  },
  {
    field: 'exchange',
    headerName: 'Exchange',
    headerTooltip: 'Exchange',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
  },
  {
    field: 'exchange_order_id',
    headerName: 'Exchange Order ID',
    headerTooltip: 'Exchange Order ID',
  },
  {
    field: 'exchange_order_state',
    headerName: 'Exchange Order State',
    headerTooltip: 'Exchange Order State',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
    resizable: true,
    minWidth: 500,
  },
  {
    field: 'product_symbol',
    hide: true,
    suppressHeaderFilterButton: true,
    filter: 'agTextColumnFilter',
    headerName: 'Product Symbol',
    headerTooltip: 'Product Symbol',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
  },
];
