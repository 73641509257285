import { Box, Stack, Typography } from '@mui/material';
import { MIN_SIZE_VALUE } from '../constants';
import { TradingNumberInput } from './TradingNumberInput';

interface TradeSizeInputProps {
  sizeInputRef: React.RefObject<HTMLDivElement>;
  tradeSize: number;
  isTradeLimitBreached: boolean;
  showUserInputError: boolean;
  onTradeSizeChange: (event: any, value: number | undefined) => void;
  rawTradingAmount?: string;
}

export const TradeSizeInput = ({
  sizeInputRef,
  tradeSize,
  isTradeLimitBreached,
  showUserInputError,
  rawTradingAmount,
  onTradeSizeChange,
}: TradeSizeInputProps) => {
  return (
    <Stack alignItems="center" justifyContent="end" position="relative">
      <TradingNumberInput
        ref={sizeInputRef}
        label="RFQ Lots"
        value={tradeSize}
        min={MIN_SIZE_VALUE}
        onChange={onTradeSizeChange}
        tabIndex={1}
        error={showUserInputError}
        allowClearable
        sizeSmall
        sx={{ width: '100%', paddingTop: 0.5 }}
      />
      {isTradeLimitBreached && (
        <Typography fontSize={9} color="error" tabIndex={-1}>
          Trade size exceeds trading limit
        </Typography>
      )}
      {rawTradingAmount && (
        <Box
          style={{
            fontSize: 8,
            position: 'absolute',
            color: '#585858',
            backgroundColor: '#FFFFFF',
            borderRadius: 3,
            alignSelf: 'center',
            zIndex: 1,
            paddingLeft: 1,
            paddingRight: 1,
            marginBottom: -12,
            display: 'flex',
            alignItems: 'center',
            maxHeight: 13,
          }}
        >
          {rawTradingAmount}
        </Box>
      )}
    </Stack>
  );
};
