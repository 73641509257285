import { DownloadCSV } from '@features/admin/trading/components/DownloadCSV';
import { useURLFilteredData } from '@hooks/useURLFilteredData';
import { Box, Stack, styled } from '@mui/material';
import { FilterPanel } from '@shared/components/FilterPanel';
import { Filter } from '@shared/protos/filter';
import { DataApi } from '@shared/utils/data';
import { filterColumnChooserFromMainMenu } from '@utils/contextMenuUtils';
import { AG_GRID_LICENCE_KEY } from '@utils/grid';
import { BodyScrollEndEvent, GridApi, GridReadyEvent, LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';
import { useAsync } from 'react-use';
import { useURLSearchParams } from '@hooks/useURLSearchParams';

export interface BlotterGridRef<T> {
  getGridApi: () => GridApi<T> | null;
}

interface BlotterWidgetProps<T> extends AgGridReactProps<T> {
  dataLoader: DataApi<T>;
  dataTransformer?: (data: T[]) => any[];
  filters?: Filter[];
  disableSearchFilter?: boolean;
  blotterTitle?: string;
  onRefreshClicked?: () => void;
  getGridApi?: (gridApi: GridApi<T>) => void;
  maxHeight?: number;
  csvDownloadUrl?: string;
  selectedProduct?: string;
  disableURLSearchParams?: boolean;
  filtersTransformer?: (urlParamFilters: { [x: string]: any }) => { [x: string]: any };
  domLayout?: 'normal' | 'autoHeight';
  onSoundToggle?: (enabled: boolean) => void;
  defaultSoundEnabled?: boolean;
}

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 26px)',
    height: 'calc(100vh - 250px)',
  },
}));

LicenseManager.setLicenseKey(AG_GRID_LICENCE_KEY);

export const BlotterWidget = <T,>({
  filters = [],
  dataLoader,
  disableSearchFilter = false,
  blotterTitle,
  onRefreshClicked,
  getGridApi,
  dataTransformer,
  maxHeight,
  csvDownloadUrl,
  disableURLSearchParams,
  filtersTransformer,
  selectedProduct,
  domLayout = 'autoHeight',
  onSoundToggle,
  defaultSoundEnabled,
  ...gridProps
}: BlotterWidgetProps<T>) => {
  const gridApiRef = useRef<GridApi<T> | null>(null);
  const { setURLSearchParam } = useURLSearchParams();

  const { data, fetchData, fetchMoreData } = useURLFilteredData<T>({
    dataLoader,
    filters,
    disableURLSearchParams,
    filtersTransformer,
  });

  const defaultColDef = useMemo(
    () => ({
      suppressHeaderMenuButton: true,
      ...gridProps.defaultColDef,
    }),
    [gridProps.defaultColDef]
  );
  const transformedData = useMemo(() => {
    return dataTransformer ? dataTransformer(data) : data;
  }, [data, dataTransformer]);

  const onBlotterScrollEnd = useCallback(
    (event: BodyScrollEndEvent<T, any>) => {
      const buffer = 5;
      const lastRowIndex = event.api.getLastDisplayedRowIndex();
      const totalRows = event.api.getDisplayedRowCount();

      if (event.direction === 'vertical' && lastRowIndex >= totalRows - buffer) {
        fetchMoreData();
      }
    },
    [data, fetchMoreData]
  );

  useAsync(async () => {
    if (selectedProduct) {
      await fetchData({ product_symbol: selectedProduct.toLowerCase() });
    }
  }, [selectedProduct, fetchData]);

  const onGridReady = useCallback(
    (params: GridReadyEvent<T, any>) => {
      gridApiRef.current = params.api;
      getGridApi?.(params.api);
    },
    [getGridApi]
  );

  const handleMultiFilterChange = useCallback(
    (name: string, values: string[]) => {
      const paramValue = values && values.length > 0 ? values.join(',') : undefined;
      setURLSearchParam(name, paramValue);
    },
    [setURLSearchParam]
  );

  return (
    <Stack direction="column" flex={1} position="relative" sx={{ width: '100%', height: '100%' }}>
      {!!filters.length && (
        <FilterPanel
          title={blotterTitle}
          filters={filters}
          disableSearch={disableSearchFilter}
          onRefreshClicked={onRefreshClicked}
          onSoundToggle={onSoundToggle}
          defaultSoundEnabled={defaultSoundEnabled}
          onMultiFilterChange={handleMultiFilterChange}
        >
          {csvDownloadUrl && <DownloadCSV csvUrl={csvDownloadUrl} style={{ marginLeft: '10px' }} />}
        </FilterPanel>
      )}
      <StyledBox className="ag-theme-balham-dark custom-grid">
        <AgGridReact<T>
          autoSizeStrategy={{ type: 'fitGridWidth' }}
          suppressDragLeaveHidesColumns
          suppressColumnMoveAnimation
          getMainMenuItems={filterColumnChooserFromMainMenu}
          {...gridProps}
          defaultColDef={{ ...(gridProps.defaultColDef || {}), ...defaultColDef }}
          rowData={transformedData}
          onBodyScrollEnd={onBlotterScrollEnd}
          tooltipShowDelay={0}
          onGridReady={onGridReady}
          domLayout={maxHeight ? 'normal' : domLayout}
          containerStyle={{ height: maxHeight ?? '100%', width: '100%' }}
          statusBar={{ statusPanels: [{ statusPanel: 'agAggregationComponent' }] }}
          cellSelection
        />
      </StyledBox>
    </Stack>
  );
};
