import { Product, ProductTenor } from '@protos/product';
import { OtcQuoteSymbol } from '@protos/v2/otcQuote';

export const getContractForSymbolInfo = (
  symbolId: string,
  rowData: any,
  productMap: Record<string, Product>,
  tenorMap: Record<string, ProductTenor>
): string | undefined => {
  const productId = symbolId.toLocaleLowerCase();
  const product = productMap[productId];
  const tenor = tenorMap[rowData.tenorId];

  let tenorId = rowData.tenorId;
  if (product?.calendar_type === 'spread') {
    tenorId = tenor?.spread_code;
  } else if (tenor?.frequency === 'quarterly' || tenor?.frequency === 'yearly') {
    tenorId = tenor?.frequency[0] + rowData.tenorId;
  }

  if (productId && tenorId) {
    const isQuarterlySpread = product?.calendar_type === 'spread' && tenor?.frequency === 'quarterly';
    let contractId = `${productId}${tenorId}`;

    if (isQuarterlySpread) {
      const tail = product?.symbol?.substring(product?.underlying_symbol?.length, product?.symbol?.length);
      contractId = `${product?.underlying_symbol}q${tail}${tenorId}`;
    }

    return contractId;
  }

  return undefined;
};

export const getQuoteSymbolString = (symbol: OtcQuoteSymbol) => {
  if (!symbol) return '';
  else if (typeof symbol === 'string') return symbol;
  else if ('middle' in symbol) return `${symbol.front}-${symbol.middle}-${symbol.back}`;
  else return `${symbol.front}-${symbol.back}`;
};

export const parseQuoteSymbolString = (symbol: string): OtcQuoteSymbol => {
  if (!symbol) return '';

  const parts = symbol.split('-');
  if (parts.length === 2) {
    return { front: parts[0], back: parts[1] };
  } else if (parts.length === 3) {
    return { front: parts[0], middle: parts[1], back: parts[2] };
  } else if (parts.length === 1) {
    return parts[0];
  } else {
    throw new Error(`Invalid format for symbol: ${symbol}`);
  }
};
