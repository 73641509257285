import { RollingRowSettings } from '@features/dashboard/widgets/shared/types';
import { getTenorsFromRollingSettings } from '@features/dashboard/widgets/shared/utils';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

interface SharedTenorSettingsContextType {
  selectedTenors: string[];
  rollingSettings: RollingRowSettings;
  isOverrideRolling: boolean;
  toggleTenor: (id: string) => void;
  updateRollingSettings: (settings: RollingRowSettings) => void;
  toggleIsOverrideRolling: () => void;
}

const SharedTenorSettingsContext = createContext<SharedTenorSettingsContextType | undefined>(undefined);

export function SharedTenorSettingsProvider({ children }: { children: ReactNode }) {
  const MIXED_TENORS = { monthly: 12 } as RollingRowSettings;
  const [selectedTenors, setSelectedTenors] = useState<string[]>([]);
  const [rollingSettings, setRollingSettings] = useState<RollingRowSettings>(MIXED_TENORS);
  const [isOverrideRolling, setIsOverrideRolling] = useState(false);
  const { tenors } = useUserProductsAndTenorsContext();

  const toggleTenor = useCallback((id: string) => {
    setSelectedTenors(current => {
      if (current.includes(id)) {
        return current.filter(t => t !== id);
      }
      return [...current, id];
    });
  }, []);

  const updateRollingSettings = useCallback((settings: RollingRowSettings) => {
    setRollingSettings(settings);
  }, []);

  const toggleIsOverrideRolling = useCallback(() => {
    setIsOverrideRolling(prev => !prev);
  }, []);

  useEffect(() => {
    if (!isOverrideRolling) {
      const rollingTenors = getTenorsFromRollingSettings(tenors, rollingSettings);
      setSelectedTenors(rollingTenors.map(tenor => tenor.code));
    }
  }, [isOverrideRolling, rollingSettings, tenors]);

  return (
    <SharedTenorSettingsContext.Provider
      value={{
        selectedTenors,
        rollingSettings,
        isOverrideRolling,
        toggleTenor,
        updateRollingSettings,
        toggleIsOverrideRolling,
      }}
    >
      {children}
    </SharedTenorSettingsContext.Provider>
  );
}

export function useSharedTenorSettings() {
  const context = useContext(SharedTenorSettingsContext);
  if (!context) {
    throw new Error('useSharedTenorSettings must be used within SharedTenorSettingsProvider');
  }
  return context;
}
