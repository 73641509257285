import { streamV2Product } from '@services/context';
import { TickerCallback } from '@services/v2/streamV2ProductService';
import { useCallback, useEffect, useRef } from 'react';

export const useStreamV2Product = (callback: TickerCallback, productsToSubscribe: string[]) => {
  const target = useRef<TickerCallback>(callback);
  const currentSubscribed = useRef<string[]>([]);

  const updateSubscriptions = useCallback(() => {
    const toUnsubscribe = currentSubscribed.current.filter(symbol => !productsToSubscribe.includes(symbol));
    const toSubscribe = productsToSubscribe.filter(symbol => !currentSubscribed.current.includes(symbol));

    if (toUnsubscribe.length > 0) {
      streamV2Product.unsubscribe(toUnsubscribe, target.current);
      currentSubscribed.current = currentSubscribed.current.filter(symbol => !toUnsubscribe.includes(symbol));
    }

    if (toSubscribe.length > 0) {
      streamV2Product.subscribe(toSubscribe, target.current);
      currentSubscribed.current = [...currentSubscribed.current, ...toSubscribe];
    }
  }, [productsToSubscribe]);

  useEffect(() => {
    target.current = callback;
  }, [callback]);

  useEffect(() => {
    updateSubscriptions();
    return () => {
      if (currentSubscribed.current.length > 0) {
        streamV2Product.unsubscribe(currentSubscribed.current, target.current);
        currentSubscribed.current = [];
      }
    };
  }, [productsToSubscribe, updateSubscriptions]);
};
