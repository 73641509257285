import { useAsync } from 'react-use';
import { useAdminApi } from './useAdminApi';

export function useUserById(id: string) {
  const { apiClient } = useAdminApi();

  const state = useAsync(async () => {
    const user = await apiClient?.getUserById(id);
    return user;
  }, [apiClient, id]);

  return {
    user: state.value,
    ...state,
  };
}
