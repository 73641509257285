import { Box, MenuItem, Select, Stack, styled } from '@mui/material';
import { ProductTenor } from '@protos/product';
import { TradeLimit } from '@protos/user';
import { OtcExchange } from '@protos/v2/otcQuote';
import { TradingPanel } from '../TradingContext';

interface TradingFormControlsProps {
  tradingForm: TradingPanel;
  allowedTradingProducts: string[];
  validTenors: ProductTenor[];
  validBackTenors: ProductTenor[];
  userTradeLimits: TradeLimit[];
  onProductChange: (value: string) => void;
  onTenorChange: (value: string, key?: 'left' | 'right') => void;
  onExchangeChange: (value: OtcExchange) => void;
  isDisabled?: boolean;
}

const CustomTradingMenuProps = { PaperProps: { sx: { maxHeight: 100, width: 70, borderRadius: 0 } } };
const CustomTradingMenuItemProps = { fontSize: 11, padding: 0, paddingLeft: 1 };
const TradingSelect = styled(Select)(({ theme }) => ({
  fontSize: 11,
  height: 20,
  width: '100%',
  borderRadius: 0,
  '& .MuiSelect-select': {
    padding: 0,
    paddingLeft: 4,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
    padding: 0,
  },
}));

const isSpreadTenor = (tenor: string | { left: string; right: string }): tenor is { left: string; right: string } => {
  return typeof tenor === 'object' && tenor !== null;
};

export const TradingFormControls = ({
  tradingForm,
  allowedTradingProducts,
  validTenors,
  validBackTenors,
  userTradeLimits,
  onProductChange,
  onTenorChange,
  onExchangeChange,
  isDisabled,
}: TradingFormControlsProps) => {
  const isSpreadProduct = tradingForm.selectedProduct?.toLowerCase().includes('spr');

  return (
    <Stack display="flex" gap={1} width="100%">
      <TradingSelect
        size="small"
        value={tradingForm.selectedProduct}
        onChange={(event: any) => onProductChange(event.target.value)}
        MenuProps={CustomTradingMenuProps}
        tabIndex={-1}
        disabled={isDisabled}
      >
        {allowedTradingProducts.map(option => (
          <MenuItem key={option} value={option} sx={CustomTradingMenuItemProps}>
            {option.toLocaleUpperCase()}
          </MenuItem>
        ))}
      </TradingSelect>

      {isSpreadProduct ? (
        <Box display="flex" width="100%">
          <TradingSelect
            size="small"
            value={isSpreadTenor(tradingForm.selectedTenor) ? tradingForm.selectedTenor.left : ''}
            onChange={(event: any) => onTenorChange(event.target.value, 'left')}
            MenuProps={CustomTradingMenuProps}
            tabIndex={-1}
            disabled={isDisabled}
          >
            {validTenors.map(option => (
              <MenuItem key={option.code} value={option.code} sx={CustomTradingMenuItemProps}>
                {option.display.toLocaleUpperCase()}
              </MenuItem>
            ))}
          </TradingSelect>
          <TradingSelect
            size="small"
            value={isSpreadTenor(tradingForm.selectedTenor) ? tradingForm.selectedTenor.right : ''}
            onChange={(event: any) => onTenorChange(event.target.value, 'right')}
            MenuProps={CustomTradingMenuProps}
            tabIndex={-1}
            disabled={isDisabled}
          >
            {validBackTenors.map(option => (
              <MenuItem key={option.code} value={option.code} sx={CustomTradingMenuItemProps}>
                {option.display.toLocaleUpperCase()}
              </MenuItem>
            ))}
          </TradingSelect>
        </Box>
      ) : (
        <TradingSelect
          size="small"
          value={tradingForm.selectedTenor}
          onChange={(event: any) => onTenorChange(event.target.value)}
          MenuProps={CustomTradingMenuProps}
          tabIndex={-1}
          disabled={isDisabled}
        >
          {validTenors.map(option => (
            <MenuItem key={option.code} value={option.code} sx={CustomTradingMenuItemProps}>
              {option.display.toLocaleUpperCase()}
            </MenuItem>
          ))}
        </TradingSelect>
      )}

      <TradingSelect
        size="small"
        value={tradingForm.selectedExchange}
        onChange={(event: any) => onExchangeChange(event.target.value)}
        MenuProps={CustomTradingMenuProps}
        tabIndex={-1}
        disabled={isDisabled}
      >
        {userTradeLimits
          .find(limit => limit.symbol === tradingForm.selectedProduct)
          ?.exchange.map(option => (
            <MenuItem key={option} value={option} sx={CustomTradingMenuItemProps}>
              {option.toLocaleUpperCase()}
            </MenuItem>
          ))}
      </TradingSelect>
    </Stack>
  );
};
