import { Grid } from '@features/dashboard/widgets/grid';
import { ContractPNL } from '@protos/trading';
import { TRADING_ADMIN_PNL_BLOTTER_COLUMNS } from '../../tradingAdminProtos';

const getPnlRowId = ({ data }): string => data?.period;

type Props = {
  pnlData: ContractPNL[];
};

export const TradingPnLGrid = ({ pnlData }: Props) => {
  return (
    <Grid<ContractPNL>
      rowData={pnlData}
      columnDefs={TRADING_ADMIN_PNL_BLOTTER_COLUMNS}
      getRowId={getPnlRowId}
      tooltipShowDelay={0}
      enableCellTextSelection
      defaultColDef={{ enableCellChangeFlash: true }}
      autoSizeStrategy={{ type: 'fitGridWidth' }}
      maxHeight="110px"
    />
  );
};
