import { NumberInput, NumberInputProps } from '@shared/components/NumberInput';
import { debounce } from 'lodash';
import { forwardRef, useCallback, useEffect, useState } from 'react';

export const TradingNumberInput = forwardRef<HTMLDivElement, NumberInputProps>((props, ref) => {
  const [displayValue, setDisplayValue] = useState<number | undefined>(props.value);

  const debouncedOnChange = useCallback(
    debounce((event: any, value: number | undefined) => {
      props.onChange(event, value);
    }, 50),
    [props.onChange]
  );

  const handleArrowClick = (event: any, value: number | undefined) => {
    setDisplayValue(value);
    debouncedOnChange(event, value);
  };

  useEffect(() => {
    setDisplayValue(props.value);
  }, [props.value]);

  return <NumberInput {...props} ref={ref} value={displayValue} onArrowClick={handleArrowClick} />;
});

TradingNumberInput.displayName = 'TradingNumberInput';
