import { useUserById } from '@hooks/useUserById';
import { useNavigate, useParams } from 'react-router-dom';

export const useUserPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useUserById(id!);

  return {
    user,
    navigate,
  };
};
