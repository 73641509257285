import { Calendar } from '@features/reference/calendar/components/Calendar';
import { Stack, Typography } from '@mui/material';
import { CalendarDay, CalendarTypeEnum } from '@shared/protos/calendar';
import { format, isSameMonth } from 'date-fns';
import { useMemo } from 'react';

interface MonthProps {
  month: Date;
  days: CalendarDay[];
  calendarType: CalendarTypeEnum;
}

export const Month = ({ days, month, calendarType }: MonthProps) => {
  const daysThisMonth = useMemo(() => {
    return days.filter(day => isSameMonth(month, new Date(day.Date)));
  }, [days, month]);

  const holidaysThisMonth = useMemo(() => {
    return daysThisMonth.reduce((holidays, day) => {
      if (day[calendarType] && day[calendarType] !== 'Weekend') return holidays.concat([day.Date]);
      return holidays;
    }, [] as string[]);
  }, [daysThisMonth, calendarType]);

  return (
    <Stack direction="column" alignItems="center">
      <Typography>{format(month, 'MMMM')}</Typography>
      <Calendar month={month} holidays={holidaysThisMonth} />
      <Stack direction="column" alignSelf="flex-start" sx={{ marginBottom: '25px', marginLeft: '25px' }}>
        <Typography>Holidays this month:</Typography>
        {holidaysThisMonth.length > 0 ? (
          holidaysThisMonth.map(holiday => (
            <Typography key={holiday} fontSize={11}>
              {holiday} {daysThisMonth.find(d => d.Date === holiday)![calendarType]}
            </Typography>
          ))
        ) : (
          <Typography fontSize={11}>-</Typography>
        )}
      </Stack>
    </Stack>
  );
};
