import { Filter, FilterType } from '@shared/protos/filter';
import { ColDef } from 'ag-grid-enterprise';

export type CalendarTypeEnum =
  | 'default'
  | 'argus-europe'
  | 'argus-lpg-asia'
  | 'argus-lpg-uk'
  | 'baltic-lpg'
  | 'cme'
  | 'ice'
  | 'opis-us'
  | 'platts-asia'
  | 'platts-europe'
  | 'platts-us'
  | 'argus-eu'
  | 'cme-nymex'
  | 'platts-eu';

export type CalendarDay = Record<'Date' | CalendarTypeEnum, string>;

const CALENDAR_TYPE_OPTIONS: CalendarTypeEnum[] = [
  'default',
  'argus-europe',
  'argus-lpg-asia',
  'argus-lpg-uk',
  'baltic-lpg',
  'cme',
  'ice',
  'opis-us',
  'platts-asia',
  'platts-europe',
  'platts-us',
  'argus-eu',
  'cme-nymex',
  'platts-eu',
];

export const CALENDAR_COLUMNS: ColDef<CalendarDay>[] = CALENDAR_TYPE_OPTIONS.map(o => ({ field: o, headerName: o }));

const YEARS: string[] = [];
for (let year = new Date().getFullYear() - 2; YEARS.length < 10; year++) {
  YEARS.push(year.toString());
}

export const CALENDAR_FILTERS: Filter[] = [
  { type: FilterType.SELECT, name: 'year', label: 'Year', options: YEARS, defaultValue: new Date().getFullYear().toString() },
  { type: FilterType.SELECT, name: 'calendar', label: 'Calendar Type', options: CALENDAR_TYPE_OPTIONS, defaultValue: 'default' },
  { type: FilterType.SWITCH, name: 'holidays', label: 'Holidays-Only View', defaultValue: 'false' },
];
