import { streamV2Orders } from '@services/context';
import { OrderCallback } from '@services/v2/streamV2OrderService';
import { useEffect, useRef } from 'react';

export const useStreamV2Orders = (callback: OrderCallback, isReadyToSubscribe: boolean) => {
  const target = useRef<OrderCallback>(callback);

  useEffect(() => {
    if (!isReadyToSubscribe) return;

    target.current = callback;
    streamV2Orders.subscribe(callback);

    return () => {
      streamV2Orders.unsubscribe(callback);
    };
  }, [isReadyToSubscribe, callback]);
};
