import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { ColDef, NavigateToNextCellParams, RowClassParams } from 'ag-grid-enterprise';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSharedTenorSettings } from './SharedTenorSettingsContext';

interface UseMixedGridProps {
  isShowTenors?: boolean;
  selectedAccount: string;
  gridId: string;
  savedData?: {
    [tenorId: string]: {
      value: string;
      spread: string;
      size: string;
    };
  };
  onNavigateToGrid?: (rowIndex: number) => void;
  onNavigateToPreviousGrid?: (rowIndex: number) => void;
}

export const useMixedGrid = ({
  isShowTenors = false,
  selectedAccount,
  gridId,
  savedData,
  onNavigateToGrid,
  onNavigateToPreviousGrid,
}: UseMixedGridProps) => {
  const { selectedTenors } = useSharedTenorSettings();
  const { tenors } = useUserProductsAndTenorsContext();
  const [rowData, setRowData] = useState<any[]>([]);
  const [settings, setSettings] = useState({
    symbol: '',
    validity: 10,
    size: 10,
  });

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'tenor',
        headerName: '',
        editable: false,
        hide: !isShowTenors,
        suppressKeyboardEvent: params => {
          return isShowTenors && params?.event?.code === 'Tab' && params?.event?.shiftKey;
        },
      },
      {
        field: 'value',
        headerName: 'Value',
        suppressKeyboardEvent: params => {
          if (params.event.key === 'Enter' && params.api.getCellEditorInstances().length > 0) return true;
          if (params.event.key === 'Tab' && params.api.getCellEditorInstances().length > 0) return true;
          return !isShowTenors && params?.event?.code === 'Tab' && params?.event?.shiftKey;
        },
      },
      {
        field: 'spread',
        headerName: 'Spread',
        suppressKeyboardEvent: params => {
          if (params.event.key === 'Enter' && params.api.getCellEditorInstances().length > 0) return true;
          if (params.event.key === 'Tab' && params.api.getCellEditorInstances().length > 0) return true;
          return false;
        },
      },
      {
        field: 'size',
        headerName: 'Size',
        suppressKeyboardEvent: params => {
          if (params.event.key === 'Enter' && params.api.getCellEditorInstances().length > 0) return true;
          if (params.event.key === 'Tab' && params.api.getCellEditorInstances().length > 0) return true;
          return params?.event?.code === 'Tab' && !params?.event?.shiftKey;
        },
      },
    ],
    [isShowTenors]
  );

  const navigateToNextCell = useCallback(
    (params: NavigateToNextCellParams) => {
      const { nextCellPosition, previousCellPosition } = params;
      if (nextCellPosition === null && previousCellPosition.column === params.api.getAllDisplayedColumns().slice(-1)[0]) {
        if (onNavigateToGrid) {
          onNavigateToGrid(previousCellPosition.rowIndex);
        }
        return null;
      }
      if (nextCellPosition === null && previousCellPosition.column === params.api.getAllDisplayedColumns()[0]) {
        if (onNavigateToPreviousGrid) {
          onNavigateToPreviousGrid(previousCellPosition.rowIndex);
        }
        return null;
      }
      return nextCellPosition;
    },
    [onNavigateToGrid, onNavigateToPreviousGrid]
  );

  const handleCellKeyDown = useCallback(
    (params: any) => {
      if (params.event?.code === 'Tab') {
        const isShiftTab = params.event.shiftKey;
        const allColumns = params.api.getAllDisplayedColumns();

        if (isShiftTab) {
          if (params.column === allColumns[0]) {
            if (onNavigateToPreviousGrid) {
              params.api.stopEditing();
              params.event.preventDefault();
              onNavigateToPreviousGrid(params?.node.rowIndex ?? 0);
            }
          }
        } else {
          if (params.column === allColumns[allColumns.length - 1]) {
            if (onNavigateToGrid) {
              params.api.stopEditing();
              params.event.preventDefault();
              onNavigateToGrid(params?.node.rowIndex ?? 0);
            }
          }
        }
      }
    },
    [onNavigateToGrid, onNavigateToPreviousGrid]
  );

  const isJuneDecRow = (params: RowClassParams) => {
    return params.data?.tenor.includes('JUN') || params.data?.tenor.includes('DEC');
  };

  const getRowStyle = useCallback(
    (params: RowClassParams) => {
      if (isJuneDecRow(params)) {
        return { borderBottom: '1px solid grey', borderTop: '1px solid grey' };
      }

      return undefined;
    },
    [isJuneDecRow]
  );

  useEffect(() => {
    const newRowData = selectedTenors.map((tenorCode, index) => {
      const tenor = tenors.find(t => t.code === tenorCode);
      const savedValues = savedData?.[tenorCode] ?? { value: '', spread: '', size: '' };

      return {
        tenor: index === 0 ? 'BALMO' : tenor?.display.toUpperCase() || '',
        value: savedValues.value,
        spread: savedValues.spread,
        size: savedValues.size,
        tenorId: tenorCode,
      };
    });
    setRowData(newRowData);
  }, [selectedTenors, tenors, savedData]);

  return {
    rowData,
    settings,
    setSettings,
    columnDefs,
    navigateToNextCell,
    handleCellKeyDown,
    getRowStyle,
  };
};
