import { Box, Button, ButtonProps, styled, Typography } from '@mui/material';
import { OtcExchange, OtcOrderType, OtcSide } from '@protos/v2/otcQuote';
import { config, streamV2RFQ } from '@services/context';
import { priceFormatterService } from '@services/PriceFormatterService';
import WhatsAppIcon from '@shared/assets/whatsapp-logo.png';
import theme from '@shared/themes/darkTheme';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTradingContext } from '../TradingContext';

interface TradingButtonsProps {
  rfqSubscriptionSymbol: string;
  rfqSubscriptionSize: number;
  rfSubscriptionExchange: OtcExchange;
  isDisabled: boolean;
  tradingAccount: string;
  tradeSize: number;
}

type TradingButtonProps = { isBuy?: boolean } & ButtonProps;
const TradingButton = styled(Button, { shouldForwardProp: propName => propName !== 'isBuy' })<TradingButtonProps>(({ theme, isBuy }) => ({
  borderRadius: 5,
  width: '100%',
  backgroundColor: isBuy ? theme.palette.background.buy : theme.palette.background.sell,
  display: 'flex',
  flexDirection: 'column',
  padding: 5,
  height: 45,
  marginBottom: 5,
  transition: 'background-color 0.3s ease, transform 0.3s ease',

  '&:hover': {
    backgroundColor: isBuy ? '#6096eb !important' : '#c96565 !important',
    transform: 'translateY(5%)',
    cursor: 'pointer',
  },
}));

export const TradingButtons = ({
  rfqSubscriptionSymbol,
  rfqSubscriptionSize,
  rfSubscriptionExchange,
  isDisabled,
  tradingAccount,
  tradeSize,
}: TradingButtonsProps) => {
  const { prices, isLoadingPrices } = useTradingContext();
  const [stableCanSell, setStableCanSell] = useState(false);
  const [stableCanBuy, setStableCanBuy] = useState(false);

  // Add validation for trade size
  const isValidTradeSize = rfqSubscriptionSize > 0 && Number.isInteger(rfqSubscriptionSize);

  // Update button disabled state to include trade size validation
  const isButtonDisabled = isDisabled || !isValidTradeSize || isLoadingPrices;

  const rawCanSell = useMemo(() => prices.sell && Number(prices.sell.amount) >= tradeSize, [prices, tradeSize]);
  const rawCanBuy = useMemo(() => prices.buy && Number(prices.buy.amount) >= tradeSize, [prices, tradeSize]);
  const priceDecimals = useMemo(
    () => (prices.buy ? prices.buy.price.split('.')[1]?.length : prices.sell ? prices.sell.price.split('.')[1]?.length : 3),
    [prices]
  );

  useEffect(() => {
    const updateCanTrade = debounce(() => {
      setStableCanSell(!!rawCanSell);
      setStableCanBuy(!!rawCanBuy);
    }, 500);

    updateCanTrade();

    return () => {
      updateCanTrade.cancel();
    };
  }, [rawCanSell, rawCanBuy]);

  const onPlaceTrade = useCallback(
    (key: OtcSide) => {
      if (!rfqSubscriptionSymbol) return;

      const [front, back] = rfqSubscriptionSymbol.split('-');
      const isSpreadSymbol = rfqSubscriptionSymbol.split('-').length === 2;
      const tradeSellPrice = prices.sell?.price.toString();
      const tradeBuyPrice = prices.buy?.price.toString();
      if (!tradeSellPrice || !tradeBuyPrice) return;

      streamV2RFQ.placeOrderQuote({
        symbol: isSpreadSymbol ? { front, back } : rfqSubscriptionSymbol,
        amount: rfqSubscriptionSize.toString(),
        side: key,
        price: key === 'buy' ? tradeBuyPrice : tradeSellPrice,
        exchange: rfSubscriptionExchange,
        account_id: tradingAccount,
        order_type: OtcOrderType.fill_or_kill,
      });
    },
    [prices, rfqSubscriptionSymbol, tradingAccount]
  );

  return rfqSubscriptionSymbol ? (
    <>
      {!stableCanBuy && !stableCanSell ? (
        <Box display="flex" flexDirection="column" gap={1} width="100%" height="50px" justifyContent="center" alignItems="center">
          <Typography fontSize={10} color="error" textAlign="center" marginTop={2}>
            Size not available
          </Typography>
          <a
            target="_blank"
            style={{ color: theme.palette.error.main, display: 'flex', alignItems: 'center' }}
            href={`https://wa.me/${config.whatsAppTelephone}`}
          >
            <img src={WhatsAppIcon} style={{ width: 10, height: 10, marginRight: 2 }} />
            <Typography fontSize={10} textAlign="center">
              Contact your trader
            </Typography>
          </a>
        </Box>
      ) : (
        <Box display="flex" gap={1} width="100%" height="50px" justifyContent="center" position="relative">
          <TradingButton
            key="sell"
            variant="contained"
            onClick={() => onPlaceTrade(OtcSide.sell)}
            tabIndex={2}
            disabled={isButtonDisabled || !stableCanSell}
            data-testid="place-order-button"
          >
            <Typography variant="body2" style={{ fontSize: 9, lineHeight: 0, marginBottom: 3 }} tabIndex={-1}>
              Sell
            </Typography>

            <Typography variant="body2" style={{ fontSize: 11, fontWeight: 800 }} tabIndex={-1}>
              {prices.sell ? priceFormatterService.formatProductPrice(prices.sell.price) : 'N/A'}
            </Typography>
            <Typography variant="body2" style={{ fontSize: 9, lineHeight: 0 }} tabIndex={-1}>
              {prices.sell ? `${prices.sell.amount} Lots` : ''}
            </Typography>
          </TradingButton>
          <Box
            style={{
              fontSize: 9,
              position: 'absolute',
              color: '#585858',
              backgroundColor: '#FFFFFF',
              borderRadius: 3,
              alignSelf: 'end',
              zIndex: 1,
              paddingLeft: 1,
              paddingRight: 1,
              display: 'flex',
              alignItems: 'center',
              maxHeight: 13,
              marginBottom: -5,
            }}
          >
            {prices.buy && prices.sell ? `${Math.abs(Number(prices.buy?.price) - Number(prices.sell?.price)).toFixed(priceDecimals)}` : 'N/A'}
          </Box>
          <TradingButton
            key="buy"
            variant="contained"
            isBuy
            onClick={() => onPlaceTrade(OtcSide.buy)}
            tabIndex={2}
            disabled={isButtonDisabled || !stableCanBuy}
            data-testid="place-order-button"
          >
            <Typography variant="body2" style={{ fontSize: 9, lineHeight: 0, marginBottom: 3 }} tabIndex={-1}>
              Buy
            </Typography>
            <Typography variant="body2" style={{ fontSize: 11, fontWeight: 800 }} tabIndex={-1}>
              {prices.buy ? priceFormatterService.formatProductPrice(prices.buy.price) : 'N/A'}
            </Typography>
            <Typography variant="body2" style={{ fontSize: 9, lineHeight: 0 }} tabIndex={-1}>
              {prices.buy ? `${prices.buy.amount} Lots` : ''}
            </Typography>
          </TradingButton>
        </Box>
      )}
    </>
  ) : null;
};
