import { useApi } from '@hooks/useApi';
import { useScreenSize } from '@hooks/useScreenSize';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { Product, ProductTenor, productMaps } from '@shared/protos/product';
import { ColumnMovedEvent } from 'ag-grid-enterprise';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GridRef } from '../grid';
import { DefaultGridState, GridHandlers, GridSettings, RollingRowSettings } from '../shared/types';
import { getProducts, getTenors, getTenorsFirstIndexes, getTenorsFromRollingSettings } from '../shared/utils';
import { BidAskType, PricesRow } from './types';
import { mapColumns, mapRows } from './utils';

export type PricingGridState = DefaultGridState & {
  selectedBidAskColumns: BidAskType[];
};
export type PricingGrid = GridSettings<PricesRow> &
  GridHandlers<PricesRow> & {
    productMap: Record<string, Product>;
    tenorMap: Record<string, ProductTenor>;
    setNewBidAskColumns: (newBidAskColumns: BidAskType[]) => void;
    selectedBidAskColumns: BidAskType[];
  };

export function usePricingGridState(
  selectedColumns: string[],
  selectedRows: string[],
  columnsOrder: string[],
  userRollingRowSettings: RollingRowSettings,
  isOverrideRolling: boolean,
  selectedBidAskColumns: BidAskType[]
): PricingGrid {
  const gridRef = useRef<GridRef<PricesRow>>(null);
  const { userProducts, tenors } = useUserProductsAndTenorsContext();
  const { isMobile } = useScreenSize();
  const { apiClient } = useApi();

  const [state, setState] = useState<PricingGridState>({
    selectedColIds: selectedColumns,
    selectedRowIds: selectedRows,
    rollingRowSettings: userRollingRowSettings,
    columnsOrder: columnsOrder,
    isOverrideRolling,
    selectedBidAskColumns: selectedBidAskColumns,
  });

  const selectedUserProducts = useMemo(() => getProducts(userProducts, state.selectedColIds), [userProducts, state.selectedColIds]);
  const viewableProductColumns = useMemo(
    () =>
      selectedUserProducts.filter(
        product => !product.underlying_symbol || (product.calendar_type === 'spread' && product.tenor_frequency !== 'quarterly')
      ),
    [selectedUserProducts]
  );
  const selectedTenors = useMemo(() => getTenors(tenors, state.selectedRowIds), [tenors, state.selectedRowIds]);
  const { firstWeekIndex, firstMonthIndex } = getTenorsFirstIndexes(selectedTenors, tenors);
  const { productMap, tenorMap } = useMemo(() => productMaps(selectedUserProducts, selectedTenors), [selectedUserProducts, selectedTenors]);

  const toggleColumn = useCallback(
    (id: string) =>
      setState(prevState => ({
        ...prevState,
        selectedColIds: prevState.selectedColIds.includes(id)
          ? prevState.selectedColIds.filter(colId => colId !== id)
          : [...prevState.selectedColIds, id],
      })),
    []
  );

  const setNewBidAskColumns = useCallback(
    (newBidAskColumns: BidAskType[]) =>
      setState(prevState => ({
        ...prevState,
        selectedBidAskColumns: newBidAskColumns,
      })),
    []
  );

  const addColumn = useCallback(
    (id: string) =>
      setState(prevState => ({
        ...prevState,
        selectedColIds: prevState.selectedColIds.includes(id) ? prevState.selectedColIds : [...prevState.selectedColIds, id],
      })),
    []
  );

  const toggleRow = useCallback((id: string) => {
    setState(prevState => ({
      ...prevState,
      selectedRowIds: prevState.selectedRowIds.includes(id)
        ? prevState.selectedRowIds.filter(rowId => rowId !== id)
        : [...prevState.selectedRowIds, id],
    }));
  }, []);

  const addRow = useCallback((id: string) => {
    setState(prevState => ({
      ...prevState,
      selectedRowIds: prevState.selectedRowIds.includes(id) ? prevState.selectedRowIds : [...prevState.selectedRowIds, id],
    }));
  }, []);

  const updateRollingSettings = useCallback(
    (newSettings: RollingRowSettings) => {
      const newSelectedRowIds = getTenorsFromRollingSettings(tenors, newSettings).map(elem => elem.code);

      setState(prevState => ({
        ...prevState,
        rollingRowSettings: newSettings,
        selectedRowIds: newSelectedRowIds,
      }));
    },
    [tenors]
  );

  const onColumnMoved = useCallback(
    debounce(async ({ api }: ColumnMovedEvent<PricesRow>) => {
      const columnsOrder = api.getColumnState().map(({ colId }) => colId);
      setState(prevState => ({
        ...prevState,
        columnsOrder,
      }));
    }, 500),
    [apiClient]
  );

  const toggleIsOverrideRolling = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      isOverrideRolling: !prevState.isOverrideRolling,
    }));
  }, []);

  // GET ROW IDS FROM ROLLING SETTINGS IF NOT OVERRIDE ROLLING
  useEffect(() => {
    const { rollingRowSettings, isOverrideRolling } = state;

    if (!isOverrideRolling) {
      const newSelectedRowIds = getTenorsFromRollingSettings(tenors, rollingRowSettings).map(elem => elem.code);

      setState(prevState => ({
        ...prevState,
        selectedRowIds: newSelectedRowIds,
      }));
    }
  }, [tenors]);

  return useMemo(
    () => ({
      ...state,
      columnDefs: {
        lockPinned: true,
        valueFormatter: params => {
          if (params.value === '0') return '0.00';
          if (params.value == null) return '';
          return params.value;
        },
      },
      gridRef,
      rows: mapRows(
        selectedTenors,
        selectedUserProducts.filter(({ symbol }) => state.selectedColIds.includes(symbol)),
        firstWeekIndex,
        firstMonthIndex
      ),
      columns: mapColumns(viewableProductColumns, state.selectedColIds, state.columnsOrder, isMobile, state.selectedBidAskColumns),
      onColumnMoved,
      toggleColumn,
      setNewBidAskColumns,
      addColumn,
      toggleRow,
      addRow,
      updateRollingSettings,
      toggleIsOverrideRolling,
      filteredSelectedTenors: selectedTenors,
      selectedUserProducts,
      productMap,
      tenorMap,
    }),
    [selectedTenors, selectedUserProducts, firstWeekIndex, firstMonthIndex, state, viewableProductColumns, productMap, tenorMap, isMobile]
  );
}
