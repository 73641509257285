import { AppLoggerService } from './AppLoggerService';
import AppConfig from './Config';
import { ChannelType, StreamEvent as StreamEventV2, StreamV2, Subscription as SubscriptionV2 } from './StreamV2';
import StringService from './StringService';
import { streamV2OrderBookTopService } from './v2/streamV2OrderBookTopService';
import { streamV2OrderService } from './v2/streamV2OrderService';
import { streamV2ProductRiskService } from './v2/streamV2ProductRisk';
import { streamV2ProductService } from './v2/streamV2ProductService';
import { streamV2RFQService } from './v2/streamV2RFQService';
import { streamV2SnapshotsService } from './v2/streamV2SnapshotsService';

export const config = AppConfig.fromDocument();
export const logger = new AppLoggerService(config);
export const stringService = new StringService();

//V2 Streams
export const streamV2 = new StreamV2(config.wsUrlV2);
export const streamV2RFQ = streamV2RFQService(streamV2);
export const streamV2Product = streamV2ProductService(streamV2);
export const streamV2OrderBookTop = streamV2OrderBookTopService(streamV2);
export const streamV2ProductRisk = streamV2ProductRiskService(streamV2);
export const streamV2Snapshots = streamV2SnapshotsService(streamV2);
export const streamV2Orders = streamV2OrderService(streamV2);

// Server Info setup for V2 Websocket
streamV2.onConnect(() => {
  streamV2.subscribe(SubscriptionV2.serverInfo());
}, ChannelType.ServerInfo);

streamV2.onEvent(ChannelType.ServerInfo, (event: StreamEventV2) => {
  streamV2.uid = event.message.socket_uid;
});
