import { User } from '@protos/user';
import { useEffect, useMemo, useState } from 'react';

export const useTradingPermissions = (user: User | undefined, payloadValue?: string) => {
  const [selectedTradingAccount, setSelectedTradingAccount] = useState<string | undefined>(undefined);

  const tradingAccounts = useMemo(() => {
    if (!user) return [];
    return user.abn_trad_accounts;
  }, [user]);

  const userTradeLimits = useMemo(() => {
    if (!user) return [];
    return user.trade_limits;
  }, [user]);

  useEffect(() => {
    const defaultTradingAccount = tradingAccounts[0];

    setSelectedTradingAccount(payloadValue || defaultTradingAccount);
  }, [tradingAccounts]);

  return {
    tradingAccounts,
    userTradeLimits,
    selectedTradingAccount,
    setSelectedTradingAccount,
  };
};
