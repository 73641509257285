import { CellClickedEvent } from 'ag-grid-enterprise';
import { NavigateFunction } from 'react-router-dom';

export const handleOrderIdCellClick = (params: CellClickedEvent, navigate: NavigateFunction) => {
  const colId = params.column.getColId();
  if (colId === 'id') {
    const id = params.data?.id;
    navigate(`/admin/trading/orders/${id}`);
  }
};
