import { useAdminApi } from '@hooks/useAdminApi';
import { useApi } from '@hooks/useApi';
import { styled } from '@mui/material';
import { Side } from '@protos/trading';
import { addSecondsToNow } from '@utils/date';
import { CustomCellEditorProps } from 'ag-grid-react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useLaddersApi } from '../hooks/useLaddersApi';

const EditorInput = styled('input')({
  width: '100%',
  height: '100%',
  padding: '0 8px',
  backgroundColor: 'white',
  color: 'black',
  border: 'none',
  outline: 'none',
  textAlign: 'center',
  fontSize: '12px',
});

interface MixedGridCellEditorProps extends CustomCellEditorProps {
  settings: {
    symbol: string;
    validity: number;
    size: number;
  };
  selectedAccount: string;
  gridId: string;
  midPriceValidity: string;
}

const isCharNumeric = (charStr: string) => {
  return !!/^-|\d|\./.test(charStr);
};

const isValidNumber = (value: string, columnId: string) => {
  if (value === '') return true;
  //can't have decimals for size
  if (columnId === 'size') {
    return /^\d*$/.test(value);
  }
  return /^-?\d*\.?\d*$/.test(value);
};

export const MixedGridCellEditor = memo((props: MixedGridCellEditorProps) => {
  const [value, setValue] = useState(props.value);
  const inputRef = useRef<HTMLInputElement>(null);
  const { apiClient } = useAdminApi();
  const { apiClient: client } = useApi();
  const { createLadder } = useLaddersApi();

  useEffect(() => {
    let startValue: string;
    if (props.eventKey === 'Backspace') {
      startValue = '';
    } else if (props.eventKey && props.eventKey.length === 1 && isCharNumeric(props.eventKey)) {
      startValue = props.eventKey;
      props.onValueChange(startValue);
    } else {
      startValue = value;
    }
    setValue(startValue);

    inputRef.current?.focus();
    inputRef.current?.select();
  }, []);

  const updateUserSettings = useCallback(
    async (val: string) => {
      const currentField = props.column.getColId();
      const value = currentField === 'value' ? val : props.data.value;
      const spread = currentField === 'spread' ? val : props.data.spread;
      const size = currentField === 'size' ? val : props.data.size;

      const currentSettings = await client?.getUserSettings();
      await client?.updateUserSettings({
        dashboard: currentSettings?.dashboard ?? {},
        widgets: currentSettings?.widgets ?? {},
        ocl_trading: {
          ...currentSettings?.ocl_trading,
          mixed: {
            mixedGridData: {
              ...currentSettings?.ocl_trading?.mixed?.mixedGridData,
              [props?.gridId]: {
                ...currentSettings?.ocl_trading?.mixed?.mixedGridData?.[props?.gridId],
                [props.data.tenorId]: { value, spread, size },
              },
            },
          },
        },
      });
    },
    [client, props.data.tenorId, props.gridId, props.selectedAccount]
  );

  const updateValue = useCallback(
    async (val: string) => {
      if (val === '') return;

      const currentField = props.column.getColId();
      const value = currentField === 'value' ? val : props.data.value;
      const spread = currentField === 'spread' ? val : props.data.spread;
      const size = currentField === 'size' ? val : props.data.size;

      setValue(val);
      props.onValueChange(val);

      if (!value || !spread || !size) return;

      try {
        await createLadder({
          symbol: `${props.settings.symbol}${props.data.tenorId}`,
          expiry_timestamp: addSecondsToNow(props.settings.validity),
          orders: [
            {
              price: Number((Number(value) - Number(spread)).toFixed(2)),
              side: Side.BUY,
              amount: Number(size),
              hidden: false,
            },
            {
              price: Number((Number(value) + Number(spread)).toFixed(2)),
              side: Side.SELL,
              amount: Number(size),
              hidden: false,
            },
          ],
          account_id: props.selectedAccount,
        });

        /* Removing Mid Price Creation for now, when infrastructure is in place we'll add it back 
        
        const newMid = {
          symbol: `${props.settings.symbol}${props.data.tenorId}`,
          price: props.data.value,
          expiry_timestamp: addSecondsToNow(Number(props.midPriceValidity)),
          is_live: true,
          created_timestamp: new Date().toISOString(),
        };

        
         await apiClient?.createMidPrice(newMid);
        */
      } catch (error) {
        console.error(error);
        setValue(props.initialValue);
        props.onValueChange(props.initialValue);
      }
    },
    [apiClient, client, createLadder, props.selectedAccount]
  );

  const handleKeyDown = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        !isCharNumeric(event.key) &&
        !['Backspace', 'Delete', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Enter', 'Tab'].includes(event.key)
      ) {
        event.preventDefault();
        return;
      }
      if (event.key === 'Enter') {
        props.stopEditing();
        await updateValue(value);
        if (value !== props.initialValue) {
          await updateUserSettings(value);
        }
      } else if (event.key === 'Escape') {
        event.preventDefault();
        props.stopEditing();
        props.api.clearFocusedCell();
      } else if (event.key === 'Tab') {
        event.preventDefault();
        const allColumns = props.api.getAllDisplayedColumns();
        const currentColumnIndex = allColumns.findIndex(column => column.getColId() === props.column.getColId());
        const rowIndex = props.api.getFocusedCell()?.rowIndex;
        if (event.shiftKey) {
          const previousColumn = allColumns[currentColumnIndex - 1];
          if (previousColumn && rowIndex !== undefined) {
            props.api.setFocusedCell(rowIndex, previousColumn.getColId());
            props.api.startEditingCell({
              rowIndex,
              colKey: previousColumn.getColId(),
            });
          }
        } else {
          const nextColumn = allColumns[currentColumnIndex + 1];
          if (nextColumn && rowIndex) {
            props.api.setFocusedCell(rowIndex, nextColumn.getColId());
            props.api.startEditingCell({
              rowIndex,
              colKey: nextColumn.getColId(),
            });
          }
        }
        if (props.value !== props.initialValue) {
          await updateUserSettings(value);
        }
      }
    },
    [props, value, updateValue]
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      const columnId = props.column.getColId();
      if (isValidNumber(newValue, columnId)) {
        setValue(newValue);
        props.onValueChange(newValue);
      }
    },
    [props.onValueChange, props.column]
  );

  return <EditorInput ref={inputRef} value={value ?? ''} onChange={onChange} onKeyDown={handleKeyDown} />;
});
