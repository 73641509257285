import { useApi } from '@hooks/useApi';
import { Product, ProductTenor } from '@protos/product';
import { TradeLimit } from '@protos/user';
import { OtcExchange } from '@protos/v2/otcQuote';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { useTradableProductSymbolsContext } from '@shared/contexts/TradableProductSymbolsProvider';
import { useTradingTenorContext } from '@shared/contexts/TradingTenorProvider';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_TRADE_SIZE } from '../constants';
import { TradingPanel } from '../TradingContext';

interface UseTradingFormProps {
  id: string;
  payload: TradingPanel;
  userTradeLimits: TradeLimit[];
  monthlyTenors: ProductTenor[];
}

export const useTradingForm = ({ id, payload, userTradeLimits, monthlyTenors }: UseTradingFormProps) => {
  const { apiClient } = useApi();
  const { editWidgetPayloadById } = useDashboardContext();
  const { getAllUsedTenors } = useTradingTenorContext();
  const tradableProducts = useTradableProductSymbolsContext();
  const [isLoadingPanel, setIsLoadingPanel] = useState<boolean>(true);
  const [isTradeLimitBreached, setIsTradeLimitBreached] = useState<boolean>(false);
  const [validTenors, setValidTenors] = useState<ProductTenor[]>(monthlyTenors);
  const [validBackTenors, setValidBackTenors] = useState<ProductTenor[]>(monthlyTenors);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined);
  const [underlyingProduct, setUnderlyingProduct] = useState<Product | undefined>(undefined);
  const [tenorsForProduct, setTenorsForProduct] = useState<Set<string>>(new Set());
  const [tenorsNeedUpdate, setTenorsNeedUpdate] = useState(false);

  const {
    selectedProduct: incomingSelectedProduct,
    selectedTenor: incomingSelectedTenor,
    selectedTradingAccount: incomingSelectedTradingAccount,
    selectedExchange: incomingSelectedExchange,
  } = payload;

  const allowedTradingProducts = useMemo(
    () => userTradeLimits.map(limit => limit.symbol).filter(symbol => tradableProducts.includes(symbol)),
    [userTradeLimits, tradableProducts]
  );

  const availableTradingTenors = useMemo(() => {
    if (tenorsForProduct.size === 0) return [];
    return monthlyTenors.filter(t => tenorsForProduct.has(t.code));
  }, [tenorsForProduct, monthlyTenors]);

  const initialSelectedTenor = useMemo(() => {
    if (!availableTradingTenors.length) return '';

    if (incomingSelectedTenor && typeof incomingSelectedTenor === 'object') {
      const indexOfLeftTenor = availableTradingTenors.findIndex(tenor => tenor?.code === incomingSelectedTenor.left);
      const availableTenors = availableTradingTenors.slice(indexOfLeftTenor + 1);
      setValidBackTenors(availableTenors);
      return {
        left: incomingSelectedTenor.left,
        right: availableTenors[0]?.code || incomingSelectedTenor.right,
      };
    }

    // Get currently used tenors for the default product and exchange
    const defaultExchange = userTradeLimits?.[0]?.exchange?.[0];
    const defaultProduct = allowedTradingProducts[0];
    const allUsedTenors = getAllUsedTenors(id, false, defaultExchange as OtcExchange, defaultProduct);

    const availableTenor = availableTradingTenors.find(tenor => !allUsedTenors.find(usedTenor => (usedTenor as ProductTenor)?.code === tenor?.code));

    return availableTenor?.code || availableTradingTenors[0]?.code;
  }, [getAllUsedTenors, availableTradingTenors, incomingSelectedExchange, id, allowedTradingProducts, userTradeLimits, tenorsForProduct]);

  const [tradingForm, setTradingForm] = useState<TradingPanel>({
    selectedProduct: payload.selectedProduct || allowedTradingProducts[0],
    selectedTenor: payload.selectedTenor || initialSelectedTenor,
    tradeSize: payload.tradeSize || DEFAULT_TRADE_SIZE,
    selectedTradingAccount: payload.selectedTradingAccount,
    selectedExchange: payload.selectedExchange || userTradeLimits?.[0]?.exchange?.[0],
  });

  const productTradingLimit = useMemo(
    () => userTradeLimits.find(limit => limit.symbol === incomingSelectedProduct?.toLowerCase())?.limit ?? 0,
    [userTradeLimits, incomingSelectedProduct]
  );

  const showUserInputError = useMemo(() => {
    const { selectedProduct, selectedTenor, selectedExchange, tradeSize } = tradingForm;
    const isValidTradeSize = tradeSize > 0 && Number.isInteger(tradeSize) && !isTradeLimitBreached;

    return !selectedProduct || !selectedTenor || !selectedExchange || !isValidTradeSize;
  }, [tradingForm]);

  const getExchangeFromProduct = useCallback(
    (product?: Product) => {
      return product?.exchange_symbols.find(ex => ex.exchange === tradingForm.selectedExchange);
    },
    [tradingForm.selectedExchange]
  );

  const rawTradingAmount = useMemo(() => {
    if (!selectedProduct || !tradingForm.tradeSize) return undefined;
    const contractSize = Number(
      getExchangeFromProduct(selectedProduct)?.contract_size ?? getExchangeFromProduct(underlyingProduct)?.contract_size ?? 0
    );
    const rawAmount = tradingForm.tradeSize * contractSize;
    return `${rawAmount.toLocaleString()} ${selectedProduct.quantity_unit.toUpperCase()}`;
  }, [selectedProduct, tradingForm.selectedExchange, tradingForm.tradeSize, underlyingProduct]);

  useEffect(() => {
    const fetchProduct = async () => {
      const prod = await apiClient?.getProductBySymbol(tradingForm.selectedProduct);
      if (prod) {
        setSelectedProduct(prod);
        if (prod.symbol.includes('spr')) {
          setTenorsForProduct(new Set(prod.contracts.map(c => [c.tenor_code.slice(0, 3), c.tenor_code.slice(3)]).flat()));
        } else {
          setTenorsForProduct(new Set(prod.contracts.map(c => c.tenor_code)));
        }
      }
      if (tradingForm.selectedProduct.includes('spr') && prod?.exchange_symbols.length === 0) {
        const underlying = await apiClient?.getProductBySymbol(tradingForm.selectedProduct.split('spr')[0]);
        if (underlying) setUnderlyingProduct(underlying);
      } else {
        setUnderlyingProduct(undefined);
      }
    };

    if (apiClient && tradingForm.selectedProduct !== selectedProduct?.symbol) {
      fetchProduct();
    }
  }, [apiClient, tradingForm.selectedProduct]);

  useEffect(() => {
    if (selectedProduct && tradingForm.selectedExchange) {
      const minTrade = Number(getExchangeFromProduct(selectedProduct)?.min_size ?? getExchangeFromProduct(underlyingProduct)?.min_size ?? 0);
      if (minTrade > tradingForm.tradeSize) {
        setTradingForm(prev => ({
          ...prev,
          tradeSize: minTrade,
        }));
      }
    }
  }, [selectedProduct, underlyingProduct, tradingForm.selectedExchange]);

  const onTradingProductChange = useCallback(
    (value: string) => {
      if (value === incomingSelectedProduct) return;
      if (!allowedTradingProducts.includes(value)) return;
      setIsLoadingPanel(true);
      setTenorsNeedUpdate(true);
      setTradingForm(prev => ({
        ...prev,
        selectedProduct: value,
      }));
    },
    [tradingForm, allowedTradingProducts]
  );

  const updateInitialTenors = useCallback(
    (tenorsList: ProductTenor[]) => {
      if (!selectedProduct) return;

      const isNewProductSpread = selectedProduct.symbol.toLocaleLowerCase().includes('spr');
      const exchange = incomingSelectedExchange || (userTradeLimits?.[0]?.exchange?.[0] as OtcExchange);
      const allUsedTenors = getAllUsedTenors(id, isNewProductSpread, exchange, selectedProduct.symbol);

      if (isNewProductSpread) {
        const leftTenorCode = tenorsList[0]?.code;
        const usedSpreadProductTenors = (allUsedTenors as { left: ProductTenor; right: ProductTenor }[]).filter(t => t.left?.code === leftTenorCode);
        let rightTenorCode = tenorsList[1]?.code;

        if (usedSpreadProductTenors.length) {
          const usedRightTenors = usedSpreadProductTenors.map(t => t.right?.code);
          const availableRightTenors = tenorsList.filter(tenor => !usedRightTenors.includes(tenor?.code) && tenor?.code !== leftTenorCode);
          rightTenorCode = availableRightTenors[0]?.code;
        }

        setTradingForm(prev => ({
          ...prev,
          selectedTenor: { left: leftTenorCode, right: rightTenorCode },
        }));
      } else {
        // Find first available tenor for the new product
        const availableTenors = tenorsList.filter(tenor => !allUsedTenors.find(usedTenor => (usedTenor as ProductTenor)?.code === tenor?.code));
        setTradingForm(prev => ({
          ...prev,
          selectedTenor: availableTenors[0]?.code,
        }));
      }

      setTenorsNeedUpdate(false);
    },
    [tradingForm, selectedProduct, incomingSelectedExchange, getAllUsedTenors, id, userTradeLimits]
  );

  const getAvailableRightTenors = useCallback(
    (leftTenorCode: string, usedRightTenors: string[]) => {
      const indexOfLeftTenor = availableTradingTenors.findIndex(tenor => tenor?.code === leftTenorCode);
      return availableTradingTenors.filter(
        (tenor, index) => !usedRightTenors.includes(tenor?.code) && tenor?.code !== leftTenorCode && index > indexOfLeftTenor
      );
    },
    [availableTradingTenors]
  );

  const onTenorChange = useCallback(
    (value: string, key?: 'left' | 'right') => {
      setIsLoadingPanel(true);
      const { selectedTenor, selectedExchange } = tradingForm;
      const isSpreadTenorChange = key !== undefined;

      if (!isSpreadTenorChange) {
        setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: value }));
      } else {
        if (typeof selectedTenor !== 'object') {
          //currently selected tenor is not a spread, so make it a spread
          const newTenor = { left: value, right: availableTradingTenors[1]?.code };
          setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: newTenor }));
        } else if (selectedTenor[key] === value) {
          return;
        } else if (key === 'right') {
          const newTenor = { ...selectedTenor, right: value };
          setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: newTenor }));
        } else {
          const allUsedTenors = getAllUsedTenors(id, true, selectedExchange, incomingSelectedProduct);
          const usedSpreadProductTenors = (allUsedTenors as { left: ProductTenor; right: ProductTenor }[]).filter(t => t.left?.code === value);
          const updatedRightTenors = usedSpreadProductTenors.map(t => t.right?.code);
          const availableRightTenors = getAvailableRightTenors(value, updatedRightTenors);
          const newTenor = { left: value, right: availableRightTenors[0]?.code };
          setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: newTenor }));
        }
      }
    },
    [tradingForm, getAllUsedTenors, availableTradingTenors]
  );

  const onExchangeChange = useCallback(
    (value: OtcExchange) => {
      setIsLoadingPanel(true);
      setTradingForm((prev: TradingPanel) => ({ ...prev, selectedExchange: value }));
    },
    [tradingForm]
  );

  const onTradeSizeChange = useCallback(
    debounce((_, newValue) => {
      setTradingForm((prev: TradingPanel) => ({ ...prev, tradeSize: newValue }));

      if (newValue > productTradingLimit) {
        setIsTradeLimitBreached(true);
      } else if (isTradeLimitBreached) {
        setIsTradeLimitBreached(false);
      }
    }, 350),
    [isTradeLimitBreached, productTradingLimit]
  );

  useEffect(() => {
    // Available tenors changed, update initial ones if needed
    if (tenorsNeedUpdate || !tradingForm.selectedTenor) updateInitialTenors(availableTradingTenors);
  }, [availableTradingTenors]);

  useEffect(() => {
    if (incomingSelectedTradingAccount) {
      setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTradingAccount: incomingSelectedTradingAccount }));
      editWidgetPayloadById(id, {
        ...payload,
        selectedTradingAccount: incomingSelectedTradingAccount,
      });
    }
  }, [incomingSelectedTradingAccount]);

  return {
    tradingForm,
    rawTradingAmount,
    isLoadingPanel,
    tenorsNeedUpdate,
    isTradeLimitBreached,
    showUserInputError,
    allowedTradingProducts,
    availableTradingTenors,
    validTenors,
    validBackTenors,
    onTradingProductChange,
    onTenorChange,
    onExchangeChange,
    onTradeSizeChange,
    setIsLoadingPanel,
    setValidTenors,
    setValidBackTenors,
    getAvailableRightTenors,
  };
};
