import { Stack, Typography } from '@mui/material';
import { ExecutionVolume } from '@protos/trading';
import theme from '@shared/themes/darkTheme';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

type Props = {
  volumes: ExecutionVolume[];
};

const LINES_COLORS = {
  outright: theme.palette.info.main,
  spread: theme.palette.warning.light,
  total: theme.palette.success.main,
};

export const VolumeChart = ({ volumes }: Props) => {
  const formattedVolumes = useMemo(() => {
    return volumes.map(vol => ({
      outright_volume: Number(vol.outright_volume),
      spread_volume: Number(vol.spread_volume),
      total_volume: Number(vol.total_volume),
      timestamp: new Date(vol.timestamp).getTime(),
    }));
  }, [volumes]);

  if (formattedVolumes.length === 0) {
    return (
      <Typography align="center" color={theme.palette.grey[500]} marginTop={10}>
        No data found for chosen filters
      </Typography>
    );
  }

  return (
    <Stack direction="column" flex={2}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={formattedVolumes} margin={{ top: 10, right: 30, left: 0, bottom: 10 }}>
          <XAxis
            scale="time"
            type="number"
            dataKey="timestamp"
            domain={['dataMin', 'dataMax']}
            tickFormatter={date => format(new Date(date), 'yyyy-MM-dd')}
          />
          <YAxis />
          <Legend formatter={value => value[0].toUpperCase() + value.slice(1).replace('_', ' ')} />
          <Tooltip
            cursor={{ fill: theme.palette.background.darker, fillOpacity: 0.5 }}
            content={({ active, payload }) => {
              if (!active || !payload || !payload.length) return null;
              return (
                <Stack sx={{ backgroundColor: theme.palette.background.darker, padding: 2, borderRadius: '5px' }}>
                  <Typography fontSize={13} fontWeight={800} marginBottom={1}>
                    {format(new Date(payload[0].payload.timestamp), 'yyyy-MM-dd HH:mm')}
                  </Typography>
                  <Stack direction="row">
                    <Typography fontSize={11} fontWeight={800} color={LINES_COLORS.outright} marginRight={1}>
                      Outright Volume:
                    </Typography>
                    <Typography fontSize={11}>{payload[0].payload.outright_volume}</Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography fontSize={11} fontWeight={800} color={LINES_COLORS.spread} marginRight={1}>
                      Spread Volume:
                    </Typography>
                    <Typography fontSize={11}>{payload[0].payload.spread_volume}</Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography fontSize={11} fontWeight={800} color={LINES_COLORS.total} marginRight={1}>
                      Total Volume:
                    </Typography>
                    <Typography fontSize={11}>{payload[0].payload.total_volume}</Typography>
                  </Stack>
                </Stack>
              );
            }}
          />

          <Line dataKey="outright_volume" stroke={LINES_COLORS.outright} />
          <Line dataKey="spread_volume" stroke={LINES_COLORS.spread} />
          <Line dataKey="total_volume" stroke={LINES_COLORS.total} />
        </LineChart>
      </ResponsiveContainer>
    </Stack>
  );
};
