import { useAdminApi } from '@hooks/useAdminApi';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { TradeLimit, User } from '@protos/user';
import { IconComponent } from '@shared/components/IconComponent';
import { TextField } from '@shared/components/TextField';
import { TextInputSelectBox } from '@shared/components/TextFieldSelectBox';
import { StyledInputLabel } from '@shared/components/TextInput';
import { useTradableProductSymbolsContext } from '@shared/contexts/TradableProductSymbolsProvider';
import { useEffect, useState } from 'react';
import { StyledIconButton } from './StyledIconButton';

interface EditTradeLimitsDialogProps {
  user: User;
  isOpen: boolean;
  onClose: () => void;
  onUserUpdated: (user: User) => void;
}

const NumberRegex = new RegExp('^[0-9]*$');
const exchangeOptions = ['cme', 'ice'];
const icon = <IconComponent name="ri-checkbox-blank-line" />;
const checkedIcon = <IconComponent name="ri-checkbox-line" />;

export const EditTradeLimitsDialog: React.FC<EditTradeLimitsDialogProps> = ({ user, isOpen, onClose, onUserUpdated }) => {
  const { apiClient } = useAdminApi();
  const products = useTradableProductSymbolsContext();
  const [tradeLimits, setTradeLimits] = useState<TradeLimit[]>([...user.trade_limits]);
  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
  }, [tradeLimits]);

  const onSave = async () => {
    setError('');
    if (apiClient && user) {
      try {
        await apiClient.updateUserLimits(user.id, { trade_limits: tradeLimits }).then(data => {
          setTradeLimits(data.trade_limits);
          onUserUpdated(data);
        });
      } catch (e) {
        setError('Some input values are not valid.');
        return;
      }
    }
    onClose();
  };

  const onTradeLimitAdd = () => {
    setTradeLimits([...tradeLimits, { symbol: '', limit: 0, exchange: [] }]);
  };

  const onTradeLimitRemove = (tradeId: number) => {
    const updatedTrades = [...tradeLimits];
    updatedTrades.splice(tradeId, 1);
    setTradeLimits(updatedTrades);
  };

  const onTradeLimitUpdate = (tradeId: number, updatedValue: TradeLimit) => {
    const updatedTrades = [...tradeLimits];
    updatedTrades.splice(tradeId, 1, updatedValue);
    setTradeLimits(updatedTrades);
  };

  const onExchangeCheckboxClick = (tradeId: number, value: string, tradeLimit: TradeLimit) => {
    let updatedTradeExchanges = [...tradeLimits[tradeId].exchange];
    if (updatedTradeExchanges.includes(value)) {
      updatedTradeExchanges = updatedTradeExchanges.filter(ex => ex !== value);
    } else {
      updatedTradeExchanges.push(value);
    }
    onTradeLimitUpdate(tradeId, { ...tradeLimit, exchange: updatedTradeExchanges });
  };

  useEffect(() => {
    setTradeLimits(user.trade_limits);
  }, [user, isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      disableEnforceFocus={false}
      disableAutoFocus={false}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          onSave();
        },
      }}
    >
      <DialogTitle>Edit Trade Limits</DialogTitle>
      <DialogContent>
        <Box display="flex" marginRight={2} gap={1}>
          <Stack direction="column" alignItems="flex-start">
            {tradeLimits.map((tradeLimit, id) => (
              <Stack key={`trade_limit_${tradeLimit.symbol}`} direction="row">
                <TextInputSelectBox
                  label="Symbol"
                  value={tradeLimit.symbol}
                  sx={{ width: '100px' }}
                  onChange={(value: string) => onTradeLimitUpdate(id, { ...tradeLimit, symbol: value })}
                  options={products.filter(p => p === tradeLimit.symbol || !tradeLimits.map(t => t.symbol).includes(p))}
                />
                <div style={{ margin: '0 15px' }}>
                  <TextField
                    label="Limit"
                    value={tradeLimit.limit.toString()}
                    error={tradeLimit.limit === 0}
                    sx={{ width: '100px' }}
                    onChange={(value: string) => {
                      if (NumberRegex.test(value)) onTradeLimitUpdate(id, { ...tradeLimit, limit: Number(value) });
                    }}
                  />
                </div>
                <div style={{ padding: '8px 10px 13px' }}>
                  <StyledInputLabel>Exchange</StyledInputLabel>
                  <Stack direction="row">
                    {exchangeOptions.map(exOption => (
                      <Stack direction="row" alignItems="center" key={`checkbox-${exOption}-${id}`} style={{ marginRight: 8 }}>
                        <Checkbox
                          size="small"
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={tradeLimit.exchange.includes(exOption)}
                          onChange={() => onExchangeCheckboxClick(id, exOption, tradeLimit)}
                        />
                        <Typography fontSize={11}>{exOption}</Typography>
                      </Stack>
                    ))}
                  </Stack>
                </div>
                <StyledIconButton onClick={() => onTradeLimitRemove(id)} size={'small'} style={{ marginLeft: '5px' }}>
                  <i className={'ri-delete-bin-line'} />
                </StyledIconButton>
              </Stack>
            ))}
            <StyledIconButton onClick={onTradeLimitAdd}>
              <i className={'ri-add-line'} />
            </StyledIconButton>
            {error && (
              <Typography color="red" fontSize={11} style={{ alignSelf: 'center' }}>
                {error}
              </Typography>
            )}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} size="small" style={{ fontSize: 11 }}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" size="small" style={{ fontSize: 11 }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
