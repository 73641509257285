import { MobileNavBarButton } from '@features/base/private/MobileNavBar';
import { SidebarButton } from '@features/base/private/Sidebar';
import { Badge } from '@mui/material';
import { streamV2 } from '@services/context';
import { PageId } from '@shared/protos/routes';
import { useMemo, useState } from 'react';
import { UserAccountPopup } from '../../../user-account/UserAccountPopup';

type Props = {
  mobile?: boolean;
};

const NETWORK_COLOR = { connected: 'success', reconnecting: 'warning', disconnected: 'error' };
type BadgeColor = 'success' | 'warning' | 'error';

export default function ManageAccountNavItem({ mobile }: Props) {
  const [userAccountAnchorEl, setUserAccountAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openUserAccountPopover = Boolean(userAccountAnchorEl);
  const userAccountPopoverId = openUserAccountPopover ? 'user-account-popover' : undefined;
  const badgeColor: BadgeColor = useMemo(() => NETWORK_COLOR[streamV2.status] as BadgeColor, [streamV2.status]);

  const handleOpenUserAccount = (event: React.MouseEvent<HTMLButtonElement>) => setUserAccountAnchorEl(event.currentTarget);

  const handleCloseUserAccount = () => setUserAccountAnchorEl(null);

  return (
    <>
      <Badge color={badgeColor} variant="dot" overlap="circular">
        {mobile ? (
          <MobileNavBarButton
            onClick={handleOpenUserAccount}
            icon="ri-user-3-line"
            label="Profile"
            pageId={PageId.manageAccount}
            data-testid="manage-account-nav-item-mobile"
          />
        ) : (
          <SidebarButton title="Manage user account" icon="ri-user-3-line" onClick={handleOpenUserAccount} data-testid="manage-account-nav-item" />
        )}
      </Badge>

      <UserAccountPopup id={userAccountPopoverId} anchorEl={userAccountAnchorEl} open={openUserAccountPopover} onClose={handleCloseUserAccount} />
    </>
  );
}
