import { PermissionsEnum } from './user';

export enum FilterType {
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  TEXTFIELD = 'TEXTFIELD',
  DATETIME = 'DATETIME',
  DATE = 'DATE',
  SELECT = 'SELECT',
  SWITCH = 'SWITCH',
  DATETIMERANGE = 'DATETIMERANGE',
  MULTISELECT = 'MULTISELECT',
}

export interface Filter {
  name: string;
  label: string;
  type: FilterType;
  options?: string[];
  defaultValue?: string | { start: Date; end?: Date };
  restrictedByPermission?: PermissionsEnum;
}
