import { Grid } from '@features/dashboard/widgets/grid';
import { ContractRisk } from '@protos/trading';
import { RowClassParams } from 'ag-grid-community';
import { useMemo } from 'react';
import { TRADING_ADMIN_RISK_RECON_BLOTTER_COLUMNS } from '../../tradingAdminProtos';
import { RiskRecon } from './types';

const getRowId = ({ data }): string => data?.tenorCode;

type Props = {
  liveData: ContractRisk[];
  dbData: ContractRisk[];
};

const getRowStyle = (params: RowClassParams) => {
  if (params.data?.tenorCode === 'SUM') {
    return { borderBottom: '1px solid grey', borderTop: '1px solid grey', fontWeight: 'bold' };
  }

  return undefined;
};

export const TradingRiskReconGrid: React.FC<Props> = ({ liveData, dbData }) => {
  const combinedData = useMemo(() => {
    const data = liveData.map(risk => ({
      tenorCode: risk.tenorCode,
      tenorDisplay: risk.tenorDisplay,
      liveTenor: risk.tenor,
      liveCmeTenor: risk.cmeTenor,
      liveIceTenor: risk.iceTenor,
      dbTenor: 0,
      dbCmeTenor: 0,
      dbIceTenor: 0,
      totalDiff: risk.tenor,
    }));

    dbData.forEach(risk => {
      const matchingRisk = data.find(d => d.tenorCode === risk.tenorCode);
      if (matchingRisk) {
        matchingRisk.dbTenor = risk.tenor;
        matchingRisk.dbCmeTenor = risk.cmeTenor;
        matchingRisk.dbIceTenor = risk.iceTenor;
      } else {
        data.push({
          tenorCode: risk.tenorCode,
          tenorDisplay: risk.tenorDisplay,
          liveTenor: 0,
          liveCmeTenor: 0,
          liveIceTenor: 0,
          dbTenor: risk.tenor,
          dbCmeTenor: risk.cmeTenor,
          dbIceTenor: risk.iceTenor,
          totalDiff: -risk.tenor,
        });
      }
    });

    return data.map(d => ({
      ...d,
      totalDiff: d.liveTenor - d.dbTenor,
    }));
  }, [liveData, dbData]);

  return (
    <>
      <Grid<RiskRecon>
        rowData={combinedData}
        getRowStyle={getRowStyle}
        columnDefs={TRADING_ADMIN_RISK_RECON_BLOTTER_COLUMNS}
        getRowId={getRowId}
        tooltipShowDelay={0}
        enableCellTextSelection
        defaultColDef={{ enableCellChangeFlash: true }}
        autoSizeStrategy={{ type: 'fitGridWidth' }}
      />
    </>
  );
};
