import { ExpandMore } from '@mui/icons-material';

import { ListItemText } from '@mui/material';

import { ExpandLess } from '@mui/icons-material';
import { Collapse } from '@mui/material';

import { List } from '@mui/material';
import { SingleReport } from './SingleReport';
import { ReportListItem } from '../styles';
import { ReportItem } from '../types';
export const ReportGroup = ({
  category,
  reports,
  isOpen,
  onParentClick,
  onReportClick,
  currentSelected,
}: {
  category: string;
  reports: ReportItem[];
  isOpen: boolean;
  onParentClick: () => void;
  onReportClick: (report: ReportItem) => void;
  currentSelected?: ReportItem;
}) => {
  const formattedCategory = category.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  return (
    <>
      <ReportListItem onClick={onParentClick}>
        <ListItemText primary={formattedCategory} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ReportListItem>
      <Collapse in={isOpen} unmountOnExit>
        <List>
          {reports.map(report => (
            <SingleReport
              key={`${category}-${report.report_link}`}
              category={category}
              report={report}
              onReportClick={onReportClick}
              isSelected={
                currentSelected?.report_name === report.report_name &&
                currentSelected?.report_link === report.report_link &&
                currentSelected?.category === category
              }
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};
