import { useApi } from '@shared/hooks/useApi';
import { createContext, useContext, useMemo } from 'react';
import { useAsync } from 'react-use';

export const TradableProductSymbolsContext = createContext<string[]>([]);
TradableProductSymbolsContext.displayName = 'TradableProductSymbolsContext';

export default function TradableProductSymbolsContextProvider(props: any) {
  const { apiClient } = useApi();

  const state = useAsync(async () => {
    const products = await apiClient?.productsLoader({ tradable: true }).loadAllData();
    return products?.map(product => product.symbol.toLowerCase()) || [];
  }, [apiClient]);

  return <TradableProductSymbolsContext.Provider value={state.value || []} {...props} />;
}

export function useTradableProductSymbolsContext(): string[] {
  const products = useContext(TradableProductSymbolsContext);

  return useMemo(() => products, [products]);
}
