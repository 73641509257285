import { Autocomplete, AutocompleteRenderInputParams, Stack, Switch, TextField, Typography } from '@mui/material';
import { Filter, FilterType } from '@shared/protos/filter';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateInput } from './DateInput';
import { DateTime } from './DateTime';
import { IconComponent } from './IconComponent';
import { Select } from './Select';

interface FilterComponentProps {
  filter: Filter;
  filterParams: Record<string, any>;
  onFilterChange: (newFilter: Record<string, any>) => void;
  onMultiFilterChange?: (name: string, values: string[]) => void;
}

export const FilterComponent: React.FC<FilterComponentProps> = ({ filter, filterParams, onFilterChange, onMultiFilterChange }) => {
  const { type, name, label, options = [] } = filter;

  const [filterValue, setFilterValue] = useState<any>(
    type === FilterType.MULTISELECT ? (Array.isArray(filterParams[name]) ? filterParams[name] : []) : filterParams[name] || ''
  );
  const [textFieldValue, setTextFieldValue] = useState<any>(filterParams[name] || '');
  const sortedOptions = useMemo(() => [...options].sort((a, b) => a.localeCompare(b)), [options]);

  const onTextFieldUpdate = useCallback(
    debounce((query: string) => {
      onFilterChange({ [name]: query });
    }, 500),
    [name, onFilterChange]
  );

  useEffect(() => {
    if (type === FilterType.MULTISELECT) {
      setFilterValue(Array.isArray(filterParams[name]) ? filterParams[name] : filterParams[name]?.split(',') || []);
    } else {
      setFilterValue(filterParams[name] || '');
    }
    setTextFieldValue(filterParams[name] || '');
  }, [filterParams, name, type]);

  switch (type) {
    case FilterType.AUTOCOMPLETE:
      return (
        <Autocomplete
          key={`blotter-filter-autocomplete-${label}`}
          disablePortal
          onChange={(_, newValue: string | null) => onFilterChange({ [name]: newValue })}
          id={`blotter-filter-autocomplete-${label}`}
          options={sortedOptions}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              size="small"
              label={label}
              sx={{
                minWidth: 170,
                '& .MuiInputBase-input': {
                  fontSize: 11,
                },
              }}
              slotProps={{
                inputLabel: {
                  style: {
                    fontSize: 11,
                  },
                },
              }}
            />
          )}
          popupIcon={<IconComponent name="ri-arrow-down-s-line" />}
          value={filterValue}
          ListboxProps={{ style: { fontSize: 11 } }}
          isOptionEqualToValue={(option, value) => option === value || value === ''}
          getOptionLabel={option => option}
        />
      );
    case FilterType.TEXTFIELD:
      return (
        <TextField
          id="outlined-basic"
          key={`blotter-filter-textfield-${label}`}
          label={label}
          variant="outlined"
          size="small"
          onChange={e => {
            const newValue = e.target.value;
            setTextFieldValue(newValue);
            onTextFieldUpdate(newValue);
          }}
          value={textFieldValue}
          sx={{
            minWidth: 250,
            '& .MuiInputBase-input': {
              fontSize: 11,
            },
          }}
          slotProps={{
            inputLabel: {
              style: {
                fontSize: 11,
              },
            },
          }}
        />
      );
    case FilterType.DATETIME:
      return (
        <DateTime
          key={`blotter-filter-datetime-${label}`}
          label={label}
          value={filterValue}
          onChange={newVal => onFilterChange({ [name]: newVal })}
        />
      );
    case FilterType.DATETIMERANGE:
      return (
        <>
          <DateTime
            key={`blotter-filter-datetime-range-${label}-start`}
            label="Start"
            value={filterParams.start}
            maxDateTime={filterParams.end}
            onChange={newVal => onFilterChange({ start: newVal })}
            clearable
          />
          <DateTime
            key={`blotter-filter-datetime-${label}-end`}
            label="End"
            value={filterParams.end}
            minDateTime={filterParams.start}
            onChange={newVal => onFilterChange({ end: newVal })}
            clearable
          />
        </>
      );
    case FilterType.DATE:
      return (
        <DateInput
          key={`blotter-filter-date-${label}`}
          value={filterValue}
          onChange={newDate => onFilterChange({ [name]: newDate })}
          label={label}
          clearable
        />
      );
    case FilterType.SELECT:
      return (
        <Select
          key={`blotter-filter-select-${label}`}
          options={sortedOptions}
          label={label}
          value={filterValue}
          onChange={newVal => onFilterChange({ [name]: newVal })}
        />
      );
    case FilterType.SWITCH:
      return (
        <Stack direction="row" alignItems="center" key={`blotter-filter-toggle-${label}`}>
          <Switch checked={filterValue === 'true'} size="small" onChange={(_, checked) => onFilterChange({ [name]: checked ? 'true' : 'false' })} />
          <Typography fontSize={11}>{label}</Typography>
        </Stack>
      );
    case FilterType.MULTISELECT:
      return (
        <Autocomplete
          key={`blotter-filter-multiselect-${label}`}
          multiple
          disablePortal
          onChange={(_, newValues: string[]) => {
            setFilterValue(newValues);
            if (onMultiFilterChange) {
              onMultiFilterChange(name, newValues);
            }
          }}
          id={`blotter-filter-multiselect-${label}`}
          options={sortedOptions}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              size="small"
              label={label}
              sx={{
                minWidth: 170,
                '& .MuiInputBase-input': {
                  fontSize: 11,
                },
                '& .MuiChip-root': {
                  fontSize: 11,
                  height: 14,
                },
                '& .MuiSvgIcon-root': {
                  height: 12,
                },
              }}
              slotProps={{
                inputLabel: {
                  style: {
                    fontSize: 11,
                  },
                },
              }}
            />
          )}
          popupIcon={<IconComponent name="ri-arrow-down-s-line" />}
          value={filterValue}
          ListboxProps={{ style: { fontSize: 11 } }}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={option => option}
        />
      );
    default:
      return null;
  }
};
