type Fee = {
  currency: string;
  rate: string;
  signed_amount?: string | null;
};

export type SpreadSymbol = {
  front: string;
  back: string;
};

export type ButterflySymbol = {
  front: string;
  back: string;
  middle: string;
};

export type OrderSymbol = string | SpreadSymbol | ButterflySymbol;

type User = {
  id: string;
  account_name: string;
  full_name: string;
  email: string;
};

export type Execution = {
  id: string;
  order_id: string;
  client_order_id: string;
  account_id: string;
  exchange?: string;
  symbol: string;
  product_symbol: string;
  price: string;
  amount: string;
  side?: string;
  exchange_timestamp: string;
  local_received_timestamp: string;
  fee: Fee;
  trading_group?: string;
  user: User;
  reconcile_timestamp: string | null;
  exchange_execution_id: string;
  report_id?: string | number;
  report_ack_timestamp?: string;
  correlation_id: string;
};

export enum AlgoOrderMode {
  UNSPECIFIED = 'unspecified',
  NORMAL = 'normal',
  AGGRESSIVE = 'aggressive',
  PASSIVE = 'passive',
}

export enum Side {
  UNSPECIFIED = 'unspecified',
  BUY = 'buy',
  SELL = 'sell',
}

export type TradingGroup = 'otc' | 'otc_client' | 'exchange';

export enum OrderType {
  UNSPECIFIED = 'unspecified',
  MARKET = 'market',
  LIMIT = 'limit',
  LIMIT_MAKER = 'limit_maker',
  LIQUIDATION = 'liquidation',
  INSTANT = 'instant',
  STOP = 'stop',
  STOP_LIMIT = 'stop_limit',
  FILL_OR_KILL = 'fill_or_kill',
  MARKET_TO_LIMIT = 'market_to_limit',
  VWAP = 'vwap',
  ALGO_QB = 'algo_qb',
  ALGO_CQG_ARRIVAL = 'algo_cqg_arrival',
}

export enum OrderState {
  UNSPECIFIED = 'unspecified',
  PENDING_CREATED = 'pending_created',
  CREATED = 'created',
  NEW = 'new',
  PARTIALLY_FILLED = 'partially_filled',
  FILLED = 'filled',
  CANCEL_CREATED = 'cancel_created',
  PENDING_UPDATE = 'pending_update',
  PENDING_CANCEL = 'pending_cancel',
  UPDATE_CREATED = 'update_created',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
  ERRORED = 'errored',
}

export type Order = {
  id: string;
  client_order_id: string;
  account_id: string;
  user: User;
  exchange: string;
  product_symbol: string;
  symbol: OrderSymbol;
  order_type: OrderType;
  order_state: OrderState;
  price: string;
  amount: string;
  visible_amount: string;
  side: Side;
  reduce_only: boolean;
  original_amount: string;
  created_timestamp: string;
  exchange_created_timestamp: string;
  exchange_last_updated_timestamp: string;
  local_received_timestamp: string;
  expiry_timestamp: string | null;
  hidden: boolean;
  executed_avg_price: string;
  executed_amount: string;
  exchange_order_id: string;
  exchange_order_state: string;
  executions: Execution[];
  trading_group: TradingGroup;
  duration_seconds: number | null;
  params_version: number | null;
  algo_order_mode: AlgoOrderMode;
  correlation_id: '';
};
