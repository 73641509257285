import { Box, Paper, Typography } from '@mui/material';
import InlineGroup from '@shared/components/InlineGroup';
import MultilineGroup from '@shared/components/MultilineGroup';
import RoundedBox from '@shared/components/RoundedBox';
import { TextInputReadOnlyBox } from '@shared/components/TextInputReadOnlyBox';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { User } from '@shared/protos/user';
import { chunkArray } from '@shared/utils/array';
import React from 'react';
import { ApiTokenBox } from './components/ApiTokenBox';
import { UserInfoList } from './components/UserInfoList';
import { convertName, getExpiryDateLabel } from './utils';

type Props = {
  user: User;
};

const generateUserInfo = (user: User) => [
  {
    label: 'Full name',
    value: user.full_name,
  },
  {
    label: 'Account name',
    value: `${user.account_name}`,
  },
  {
    label: 'Email',
    value: user.email,
  },
  {
    label: 'Whatsapp number',
    value: user.whatsapp_number,
  },
  {
    label: 'Flux admin',
    value: `${user.is_flux_admin}`,
  },
  {
    label: 'Trading admin',
    value: `${user.is_trading_admin}`,
  },
  {
    label: 'Access historical data',
    value: `${user.access_historical_data}`,
  },
  {
    label: 'Access live data',
    value: `${user.access_live_data}`,
  },
  {
    label: 'Allow trading',
    value: `${user.allow_trading}`,
  },
  {
    label: 'Websocket limit',
    value: `${user.websocket_limit}`,
  },
  {
    label: 'Client type',
    value: `${user.client_type}`,
  },
];

const getInputFields = (user: User) => {
  return generateUserInfo(user).map((data, idx) => <TextInputReadOnlyBox label={data.label} value={data.value || '-'} key={`user_info_${idx}`} />);
};

export const UserProfileForm: React.FC<Props> = ({ user }) => {
  const { isMobile } = useScreenSize();
  const inputs = getInputFields(user);

  const numberOfColumns = isMobile ? 1 : 2;

  const chunks = chunkArray(inputs, numberOfColumns);
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <Paper style={{ padding: 20 }}>
        <MultilineGroup>
          <Typography variant="h3" gutterBottom sx={{ marginBottom: '20px' }}>
            Account Info
          </Typography>
          {chunks.map((chunk, idx) => (
            <InlineGroup key={`group_${idx}`}>{chunk}</InlineGroup>
          ))}
        </MultilineGroup>
      </Paper>

      <Paper style={{ padding: 20 }}>
        {user.client_type_list.includes('api') && (
          <MultilineGroup>
            <InlineGroup>
              <ApiTokenBox label={'API token'} apiToken={user.api_token} key={'api_token'} />
            </InlineGroup>
          </MultilineGroup>
        )}
        <MultilineGroup>
          {chunkArray(
            [
              <TextInputReadOnlyBox label={'Licence type'} value={convertName(user.license_type)} key={'licence_type'} />,
              <TextInputReadOnlyBox
                label={'License expiration date'}
                value={getExpiryDateLabel(user.license_expiry, isMobile)}
                key={'licence_expiration_date'}
              />,
            ],
            numberOfColumns
          ).map((chunk, idx) => (
            <InlineGroup key={`group_licence_${idx}`}>{chunk}</InlineGroup>
          ))}
        </MultilineGroup>
      </Paper>
      <Paper style={{ padding: 20 }}>
        <RoundedBox>
          <UserInfoList list={user.permissions} header={'Permissions'} showDivider={true} />
          <UserInfoList list={user.product_groups} header={'Product Groups'} showDivider={true} />
          <UserInfoList list={user.cot_product_groups} header={'COT Product Groups'} showDivider={true} />
          <UserInfoList list={user.client_type_list} header={'Client type'} showDivider={true} />
          <UserInfoList list={user.snapshot_periods} header={'Snapshot periods'} showDivider={true} />
          {user.permissions.includes('ladder') && user.ladder_limits.length > 0 && (
            <UserInfoList
              list={user.ladder_limits.map(({ symbol, limit }) => `${symbol.toLocaleUpperCase()} : ${limit}`)}
              header={'Ladder Limits'}
              helperText={'Below is formatted as - "Symbol : Ladder Input Limit"'}
              showDivider={true}
            />
          )}
          {user.allow_trading && user.abn_trad_accounts.length > 0 && Object.keys(user.trade_limits).length > 0 && (
            <>
              <UserInfoList list={user.abn_trad_accounts.map(elem => elem.toLocaleUpperCase())} header={'Trading Accounts'} showDivider={true} />
              <UserInfoList
                list={user.trade_limits.map(
                  ({ symbol, limit, exchange }) => `${symbol.toLocaleUpperCase()} : ${limit} : ${String(exchange).toLocaleUpperCase()}`
                )}
                header={'Trading Limits'}
                helperText={'Below is formatted as - "Symbol : Trading Size Limit : Exchanges"'}
              />
            </>
          )}
        </RoundedBox>
      </Paper>
    </Box>
  );
};
