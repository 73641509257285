import { Order } from './v2/order';

type Fee = {
  currency: string;
  rate: string;
  signed_amount?: string | null;
};

export type SpreadSymbol = {
  front: string;
  back: string;
};

export type OrderSymbol = string | SpreadSymbol;

export interface OtcOrder {
  symbol: OrderSymbol;
  side: string;
  amount: string;
  price: string;
  timestamp_millis: number;
  exchange?: string;
  account_id?: string;
}

type User = {
  id: string;
  account_name: string;
  full_name: string;
  email: string;
};

export type Execution = {
  id: string;
  order_id: string;
  client_order_id: string;
  account_id: string;
  exchange?: string;
  symbol: string;
  product_symbol: string;
  price: string;
  amount: string;
  side?: string;
  exchange_timestamp: string;
  local_received_timestamp: string;
  fee: Fee;
  trading_group?: string;
  user: User;
  reconcile_timestamp: string | null;
  exchange_execution_id: string;
  report_id?: string | number;
  report_ack_timestamp?: string;
  correlation_id: string;
};

export type ExecutionReport = {
  version: number;
  n_executions: number;
  file_name: string;
  file_url: string;
  created_at: string;
  id?: number;
  acknowledged_at?: string;
};

export enum AlgoOrderMode {
  UNSPECIFIED = 'unspecified',
  NORMAL = 'normal',
  AGGRESSIVE = 'aggressive',
  PASSIVE = 'passive',
}

export enum Side {
  UNSPECIFIED = 'unspecified',
  BUY = 'buy',
  SELL = 'sell',
}

export enum OrderType {
  UNSPECIFIED = 'unspecified',
  MARKET = 'market',
  LIMIT = 'limit',
  LIMIT_MAKER = 'limit_maker',
  LIQUIDATION = 'liquidation',
  INSTANT = 'instant',
  STOP = 'stop',
  STOP_LIMIT = 'stop_limit',
  FILL_OR_KILL = 'fill_or_kill',
  MARKET_TO_LIMIT = 'market_to_limit',
  VWAP = 'vwap',
  ALGO_QB = 'algo_qb',
  ALGO_CQG_ARRIVAL = 'algo_cqg_arrival',
}

export enum OrderState {
  UNSPECIFIED = 'unspecified',
  PENDING_CREATED = 'pending_created',
  CREATED = 'created',
  NEW = 'new',
  PARTIALLY_FILLED = 'partially_filled',
  FILLED = 'filled',
  CANCEL_CREATED = 'cancel_created',
  PENDING_UPDATE = 'pending_update',
  PENDING_CANCEL = 'pending_cancel',
  UPDATE_CREATED = 'update_created',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
  ERRORED = 'errored',
}

export interface OrderResponse {
  id: string;
  method: 'order';
  error: boolean;
  message: { Message?: string; Order: Order };
}

export type ProductRiskValue = { symbol: string; value: string; value_cme: string; value_ice: string };

export type ProductRisk = {
  product_symbol: string;
  version: number;
  account_id: string;
  tenors: ProductRiskValue[];
  exchange_tenors: ProductRiskValue[];
  timestamp?: string;
};

export type ContractRisk = { tenorCode: string; tenorDisplay: string; tenor: number; cmeTenor: number; iceTenor: number };
export type ContractPNL = { period: string; cme: number; ice: number; total: number; fees: number | null };

export type KillSwitchStrategy = 'otc' | 'hedger';

export type KillSwitch = {
  account_id: string;
  active: boolean;
  timestamp: string;
  user: User;
};

export type ParamsResponse = {
  id: number;
  timestamp: string;
  user: User;
  strategy: string;
  params: Record<string, any>;
};

type Pnl = { realized: number; unrealized: number; total: number };
type TenorPnl = { symbol: string; ice_pnl: Pnl; cme_pnl: Pnl; pnl: Pnl; timestamp: string };
export type PeriodicPnLResponse = {
  product_symbol: string;
  account_id: string;
  pnl: Pnl;
  cme_pnl: Pnl;
  ice_pnl: Pnl;
  timestamp: string;
  tenors: TenorPnl[];
};

export type TradingGroup = 'otc' | 'otc_client' | 'exchange';
export type ExecutionVolume = {
  timestamp: string;
  product_symbol: string;
  trading_group: TradingGroup;
  total_volume: string;
  outright_volume: string;
  spread_volume: string;
  period: 'daily' | 'hourly';
};

export type MidPrice = {
  symbol: string;
  expiry_timestamp: string;
  price: string;
  created_timestamp?: string;
  is_live?: boolean;
};
