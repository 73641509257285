import { EventsAlertsDialog } from '@features/admin/alerts/EventsAlertsDialog';
import { useAdminApi } from '@hooks/useAdminApi';
import { Box, Divider, Paper, Typography } from '@mui/material';
import { Alert } from '@protos/alerts';
import { Execution, Order } from '@protos/v2/order';
import { BlotterCard } from '@shared/components/BlotterCard';
import { usePageTitle } from '@shared/hooks/usePageTitle';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { TRADING_ADMIN_EXECUTIONS_COLUMNS } from '../../tradingAdminProtos';
import { OrderInfo } from './OrderInfo';

type BoxProps = {
  order: Order;
  isMain?: boolean;
};

const OrderBox = ({ order, isMain }: BoxProps) => {
  return (
    <Paper>
      <Box display="flex" justifyContent="space-between" flex={1} alignItems="end">
        <Box display="flex" flexDirection="column" padding={2} paddingBottom={0} flex={1}>
          <Typography variant={isMain ? 'h3' : 'h5'}>{order.id}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={2} padding={2} sx={{ width: '100%' }}>
        <OrderInfo order={order} />
      </Box>
      <Box flexDirection="column" gap={2} margin={2}>
        <BlotterCard<Execution>
          title="Executions"
          columns={TRADING_ADMIN_EXECUTIONS_COLUMNS}
          data={order.executions}
          fullWidth
          domLayout="autoHeight"
        />
      </Box>
    </Paper>
  );
};

export const OrderPage = () => {
  usePageTitle('Order details');
  const { apiClient } = useAdminApi();
  const { id } = useParams();

  const state = useAsync(async () => {
    if (!apiClient || !id) return [];
    return await apiClient?.getRelatedOrdersForOrder(id);
  }, [apiClient, id]);

  if (!state.value || state.value.length === 0) return null;

  return (
    <Box display="flex" flexDirection="column" flex={1} padding={2} gap={2}>
      <OrderBox order={state.value[0]} isMain />

      {state.value.length > 1 && (
        <>
          <Typography variant="h4">Related orders</Typography>
          <Divider />
          {state.value.slice(1).map(order => (
            <OrderBox key={order.id} order={order} />
          ))}
        </>
      )}

      {/* It doesn't make any sense but when this dialog is removed from code, all tables accross the app have different colors ¯\_(ツ)_/¯ */}
      {false && <EventsAlertsDialog selectedEventsAlert={{} as Alert} onClose={() => {}} />}
    </Box>
  );
};
