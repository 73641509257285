import { Box, Typography } from '@mui/material';
import { ListItemButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ReportsContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'isVisible',
})(({ isVisible }: { isVisible: boolean }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: isVisible ? '30%' : 'fit-content',
}));

export const HeaderContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'isVisible',
})(({ isVisible }: { isVisible: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  paddingTop: isVisible ? 0 : '8px',
  paddingRight: '8px',
}));

export const TitleGroup = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const ReportTitle = styled(Typography)({
  padding: '16px',
  paddingRight: '8px',
  fontWeight: 'bold',
});

export const ReportListItem = styled(ListItemButton)({
  cursor: 'pointer',
  '& .MuiListItemText-primary': {
    fontSize: 11,
  },
  '& .MuiListItemText-secondary': {
    fontSize: 10,
  },
});
