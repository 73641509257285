import { RollingRowSettings } from '@features/dashboard/widgets/shared/types';
import { useApi } from '@hooks/useApi';
import { useStreamV2Product } from '@hooks/useStreamV2Product';
import { Ticker, tickerMeta } from '@protos/v2/ticker';
import { debounce } from 'lodash';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { PricingSheetGrid, usePricingSheetGridState } from './hooks/usePricingSheetGridState';

type Props = {
  children: ReactNode;
  initialValues: {
    selectedColumns: string[];
    selectedRows: string[];
    columnsOrder: string[];
    userRollingRowSettings: RollingRowSettings;
    isOverrideRolling: boolean;
  };
};

const PricingSheetContext = createContext<PricingSheetGrid | undefined>(undefined);
PricingSheetContext.displayName = 'PricingSheetContext';

export default function PricingSheetProvider({ initialValues, ...otherProps }: Props) {
  const { apiClient } = useApi();
  const {
    selectedColumns,
    selectedRows,
    columnsOrder: initialColumnsOrder,
    userRollingRowSettings,
    isOverrideRolling: initialIsOverrideLoading,
  } = { ...initialValues };

  const gridState = usePricingSheetGridState(selectedColumns, selectedRows, initialColumnsOrder, userRollingRowSettings, initialIsOverrideLoading);

  const { selectedColIds, selectedRowIds, columnsOrder, rollingRowSettings, isOverrideRolling, gridRef, productMap, tenorMap, selectedUserProducts } =
    gridState;

  const saveUserSettings = useCallback(
    async (newPricingSheetSettings: Record<string, any>) => {
      if (!apiClient) return;

      const currentUserSettings = await apiClient.getUserSettings();

      await apiClient.updateUserSettings({
        ...currentUserSettings,
        ocl_trading: {
          ...(currentUserSettings.ocl_trading || {}),
          pricingSheet: {
            ...(currentUserSettings.ocl_trading?.pricingSheet || {}),
            ...newPricingSheetSettings,
          },
        },
      });
    },
    [apiClient]
  );

  useEffect(() => {
    const debouncedSaveUserSettings = debounce((selectedColIds, selectedRowIds, columnsOrder, rollingRowSettings, isOverrideRolling) => {
      saveUserSettings({
        selectedColumns: selectedColIds,
        selectedRows: selectedRowIds,
        columnsOrder: columnsOrder,
        userRollingRowSettings: rollingRowSettings,
        isOverrideRolling,
      });
    }, 1000);

    debouncedSaveUserSettings(selectedColIds, selectedRowIds, columnsOrder, rollingRowSettings, isOverrideRolling);

    return () => {
      debouncedSaveUserSettings.cancel();
    };
  }, [selectedColIds, selectedRowIds, columnsOrder, rollingRowSettings, isOverrideRolling]);

  const tickerCallback = useMemo(() => {
    return (ticker: Ticker) => {
      const meta = tickerMeta(ticker, productMap, tenorMap);
      if (!meta || !gridRef.current) return;

      const gridApi = gridRef.current.getGridApi();
      const { tenor, productSymbolRoot } = meta;

      const rowNode = gridApi?.getRowNode(tenor.code);
      const colId = gridApi?.getColumnDef(productSymbolRoot)?.colId;

      if (!rowNode || !rowNode.data || !colId) return;

      rowNode.setDataValue(colId, ticker.mid);

      gridApi.refreshClientSideRowModel();
    };
  }, [productMap, tenorMap, gridRef]);

  useStreamV2Product(
    tickerCallback,
    selectedUserProducts.map(product => product.symbol)
  );

  const value = useMemo(() => ({ ...gridState }), [gridState]);

  return <PricingSheetContext.Provider value={value} {...otherProps} />;
}

export function usePricingSheetSettings(): PricingSheetGrid {
  const context = useContext(PricingSheetContext);

  if (!context) {
    throw new Error('usePricingSheetSettings must be used within PricingSheetProvider');
  }

  return context;
}
