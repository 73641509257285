import { ListItemText } from '@mui/material';
import { ReportListItem } from '../styles';
import { ReportItem } from '../types';
export const SingleReport = ({
  category,
  report,
  onReportClick,
  isSelected,
}: {
  category: string;
  report: ReportItem;
  onReportClick: (report: ReportItem) => void;
  isSelected: boolean;
}) => {
  const formattedDate = new Date(report.latest_publish_date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  return (
    <ReportListItem key={`${category}-${report.report_link}`} onClick={() => onReportClick({ ...report, category })} selected={isSelected}>
      <ListItemText primary={report.report_name} secondary={formattedDate} />
    </ReportListItem>
  );
};
