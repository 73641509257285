import { useApi } from '@hooks/useApi';
import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Box, Divider, Stack } from '@mui/material';
import { LadderSettings } from '@protos/ladders';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { GridApi } from 'ag-grid-enterprise';
import { useCallback, useEffect, useState } from 'react';
import { OCLGrid } from './components/non-spread/OCLGrid';
import { SpreadOCLGrid } from './components/spread/SpreadOCLGrid';

interface AllSettings {
  allSettingsSymbol: string;
  validity: string;
  size: string;
}

interface AllSpreadSettings {
  spreadSymbol: string;
  spreadValidity: string;
  spreadSize: string;
}

interface BidOfferOnlyProps {
  selectedAccount: string;
  setSelectedAccount: (account: string) => void;
}

export const BidOfferOnly = ({ selectedAccount, setSelectedAccount }: BidOfferOnlyProps) => {
  const { apiClient } = useApi();
  const user = useUserContext();
  const { urlParams, resetURLSearchParams } = useURLSearchParams();
  const [gridSettings, setGridSettings] = useState<any>(null);

  const [allSettings, setAllSettings] = useState<AllSettings>();
  const [allSpreadSettings, setAllSpreadSettings] = useState<AllSpreadSettings>();
  const [nonSpreadGridApi, setNonSpreadGridApi] = useState<GridApi | null>(null);
  const [spreadGridApi, setSpreadGridApi] = useState<GridApi | null>(null);

  useEffect(() => {
    const loadSettings = async () => {
      const settings = await apiClient?.getUserSettings();
      setGridSettings(settings?.ocl_trading?.BidOffer ?? {});
    };

    loadSettings();
  }, [apiClient]);

  const updateGridSettings = useCallback(
    async (isSpread: boolean, symbol: string, tenorKey: string, values: { bid: number | ''; offer: number | '' }) => {
      const currentSettings = await apiClient?.getUserSettings();
      const settingsPath = isSpread ? 'spread' : 'nonSpread';
      const settingsKey = `${symbol}_${tenorKey}`;
      await apiClient?.updateUserSettings({
        dashboard: currentSettings?.dashboard ?? {},
        widgets: currentSettings?.widgets ?? {},
        ocl_trading: {
          ...currentSettings?.ocl_trading,
          BidOffer: {
            ...currentSettings?.ocl_trading?.BidOffer,
            [settingsPath]: {
              ...currentSettings?.ocl_trading?.BidOffer?.[settingsPath],
              [settingsKey]: values,
            },
          },
        },
      });

      setGridSettings(prev => ({
        ...prev,
        [settingsPath]: {
          ...prev?.[settingsPath],
          [settingsKey]: values,
        },
      }));
    },
    [apiClient]
  );

  const getNonSpreadSettings = useCallback(
    (settings: LadderSettings) => {
      const { symbol, validity, size } = settings;

      if (
        allSettings &&
        allSettings.allSettingsSymbol === symbol &&
        allSettings.validity === validity.toString() &&
        allSettings.size === size.toString()
      )
        return;

      setAllSettings({ ...allSettings, allSettingsSymbol: symbol, validity: validity.toString(), size: size.toString() });
    },
    [allSettings]
  );

  const getSpreadSettings = useCallback(
    (settings: LadderSettings) => {
      const { symbol, validity, size } = settings;

      if (
        allSpreadSettings &&
        allSpreadSettings.spreadSymbol === symbol &&
        allSpreadSettings.spreadValidity === validity.toString() &&
        allSpreadSettings.spreadSize === size.toString()
      )
        return;

      setAllSpreadSettings({ ...allSpreadSettings, spreadSymbol: symbol, spreadValidity: validity.toString(), spreadSize: size.toString() });
    },
    [allSpreadSettings]
  );

  useEffect(() => {
    if (!allSettings || !allSpreadSettings) return;

    const newParams = {
      allSettingsSymbol: allSettings.allSettingsSymbol,
      validity: allSettings.validity,
      size: allSettings.size,
      spreadSymbol: allSpreadSettings.spreadSymbol,
      spreadValidity: allSpreadSettings.spreadValidity,
      spreadSize: allSpreadSettings.spreadSize,
      account_id: selectedAccount,
    };
    resetURLSearchParams({ ...urlParams, ...newParams });
  }, [allSettings, allSpreadSettings, urlParams, selectedAccount]);

  const stopEditing = (isSpread: boolean) => {
    if (isSpread) {
      spreadGridApi?.stopEditing(true);
    } else {
      nonSpreadGridApi?.stopEditing(true);
    }
  };

  const handleNonSpreadProductChange = useCallback(() => {
    stopEditing(false);
  }, [stopEditing]);

  const handleSpreadProductChange = useCallback(() => {
    stopEditing(true);
  }, [stopEditing]);

  useEffect(() => {
    if (selectedAccount || !user?.abn_trad_accounts.length) return;

    if (urlParams?.account_id && user.abn_trad_accounts.includes(urlParams.account_id)) {
      setSelectedAccount(urlParams.account_id);
    } else {
      setSelectedAccount(user.abn_trad_accounts[0]);
    }
  }, [urlParams, selectedAccount, user]);

  return (
    <Stack width="100%" height="100%" padding={1} gap={1}>
      {apiClient ? (
        <>
          <Box display="flex" flex={1} alignItems="center" justifyContent="end" sx={{ bgcolor: 'background.paper', padding: 1 }}>
            <Box minWidth="90%" />
          </Box>
          <Box display="flex" flex={1} width="100%" height="100%" gap={1} alignItems="start">
            <OCLGrid
              getNonSpreadSettings={getNonSpreadSettings}
              selectedAccount={selectedAccount}
              savedSettings={gridSettings?.nonSpread}
              onUpdateSettings={(symbol, tenorKey, values) => updateGridSettings(false, symbol, tenorKey, values)}
              getGridApi={(gridApi: GridApi) => setNonSpreadGridApi(gridApi)}
              stopEditing={handleNonSpreadProductChange}
            />
            <Divider orientation="vertical" flexItem />
            <SpreadOCLGrid
              getSpreadSettings={getSpreadSettings}
              selectedAccount={selectedAccount}
              savedSettings={gridSettings?.spread}
              onUpdateSettings={(symbol, tenorKey, values) => updateGridSettings(true, symbol, tenorKey, values)}
              getGridApi={(gridApi: GridApi) => setSpreadGridApi(gridApi)}
              stopEditing={handleSpreadProductChange}
            />
          </Box>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </Stack>
  );
};
