import { useAdminApi } from '@hooks/useAdminApi';
import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Stack, Typography } from '@mui/material';
import { Filter, FilterType } from '@protos/filter';
import { FilterPanel } from '@shared/components/FilterPanel';
import PageLoadProgress from '@shared/components/PageLoadProgress';
import { useTradableProductSymbolsContext } from '@shared/contexts/TradableProductSymbolsProvider';
import theme from '@shared/themes/darkTheme';
import { useMemo } from 'react';
import { useAsync } from 'react-use';
import { useTradingPeriodicPnl } from '../../hooks/useTradingPeriodicPnl';
import { TRADING_ADMIN_PNL_FILTERS } from '../../tradingAdminProtos';
import { TradingPnLGrid } from './TradingPnLGrid';
import { VolumeChart } from './VolumeChart';

const DEFAULT_PRODUCT_SYMBOL = 'brt';

export const TradingVolumePnL = () => {
  const { apiClient } = useAdminApi();
  const { urlParams } = useURLSearchParams();
  const products = useTradableProductSymbolsContext();
  const { pnlData, isPnLLoading } = useTradingPeriodicPnl(urlParams.product_symbol);

  const areFiltersReady = useMemo(() => !!(urlParams.trading_group && urlParams.period && urlParams.product_symbol), [urlParams]);

  const filters: Filter[] = useMemo(
    () => [
      { type: FilterType.AUTOCOMPLETE, name: 'product_symbol', label: 'Product Symbol', options: products, defaultValue: DEFAULT_PRODUCT_SYMBOL },
      ...TRADING_ADMIN_PNL_FILTERS,
    ],
    [products]
  );

  const volumes = useAsync(async () => {
    if (!areFiltersReady) return [];
    const vol = await apiClient?.executionVolumeLoader(urlParams).loadAllData();
    return vol || [];
  }, [areFiltersReady, urlParams, apiClient]);

  if (filters[0].options?.length === 0) return null;

  return (
    <Stack width="100%">
      <FilterPanel filters={filters} disableSearch setInitialURL={Object.values(urlParams).length === 0} />

      {isPnLLoading ? (
        <div style={{ position: 'relative', marginTop: 20 }}>
          <PageLoadProgress size={48} />
        </div>
      ) : (
        <>
          <TradingPnLGrid pnlData={pnlData} />
          {areFiltersReady && volumes.value && (!volumes.loading || volumes.value.length > 0) ? (
            <VolumeChart volumes={volumes.value} />
          ) : (
            <Typography align="center" color={theme.palette.grey[500]} marginTop={10}>
              Select Trading Group and Period to see the Volume Chart
            </Typography>
          )}
        </>
      )}
    </Stack>
  );
};
