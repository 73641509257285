import { alpha, styled } from '@mui/material/styles';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { format, isToday } from 'date-fns';
import '../styles/calendar-styles.css';

interface CalendarProps {
  month: Date;
  holidays: string[];
}

const WEEKENDS = [0, 6];

const CustomDay = styled(PickersDay<Date>, {
  shouldForwardProp: prop => prop !== 'isWeekend' && prop !== 'isHoliday',
})<{ isWeekend: boolean; isHoliday: boolean; isToday: boolean }>(({ isWeekend, isHoliday, isToday, theme }) => ({
  width: '39px',
  height: '39px',
  borderRadius: 0,
  border: `1px solid ${alpha(theme.palette.border, 0.1)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 0,
  marginBottom: -2,
  ...(isWeekend && { backgroundColor: `${theme.palette.grey[400]} !important`, color: '#fff !important' }),
  ...(isHoliday && { backgroundColor: `${theme.palette.error.main} !important`, color: '#fff !important' }),
  ...(isToday && { backgroundColor: `${theme.palette.info.main} !important`, color: '#fff !important' }),
  ...(!isWeekend && !isHoliday && !isToday && { backgroundColor: `${theme.palette.success.dark} !important`, color: '#fff !important' }),
  '&.Mui-selected': { opacity: '1 !important' },
}));

const renderDay = (dayProps: PickersDayProps<Date>, holidays: string[]) => {
  const { day } = dayProps;
  const isWeekend = WEEKENDS.includes(day.getDay());
  const isHoliday = holidays.includes(format(day, 'yyyy-MM-dd'));
  const isDayToday = isToday(day);

  return <CustomDay {...dayProps} isWeekend={isWeekend} isHoliday={isHoliday} isToday={isDayToday} />;
};

export const Calendar = ({ month, holidays }: CalendarProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateCalendar
        value={month}
        disableHighlightToday
        fixedWeekNumber={6}
        disabled
        slots={{ day: dayProps => renderDay(dayProps, holidays) }}
        classes={{ root: 'month-container' }}
        slotProps={{ calendarHeader: { sx: { display: 'none' } } }}
      />
    </LocalizationProvider>
  );
};
