import { HolidayView } from '@features/reference/calendar/HolidayView';
import { YearView } from '@features/reference/calendar/YearView';
import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Stack } from '@mui/material';
import { CALENDAR_FILTERS } from '@protos/calendar';
import { FilterPanel } from '@shared/components/FilterPanel';
import { useApi } from '@shared/hooks/useApi';
import { usePageTitle } from '@shared/hooks/usePageTitle';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import { endOfYear, format, startOfYear } from 'date-fns';
import { useMemo } from 'react';
import { useAsync } from 'react-use';

export const CalendarPage = () => {
  usePageTitle('Calendar');

  const { apiClient } = useApi();
  const { urlParams } = useURLSearchParams();

  const chosenYear = useMemo(() => (urlParams.year ? Number(urlParams.year) : new Date().getFullYear()), [urlParams.year]);

  const state = useAsync(async () => {
    const dateWithYear = new Date();
    dateWithYear.setFullYear(chosenYear);
    const response = await apiClient?.getCalendar({
      start: format(startOfYear(dateWithYear), 'yyyy-MM-dd'),
      end: format(endOfYear(dateWithYear), 'yyyy-MM-dd'),
    });
    return response;
  }, [apiClient, urlParams.year]);

  if (!state.value) return <></>;

  return (
    <Stack direction="column" flex={1} position="relative" sx={{ width: '100%', height: '100%' }}>
      <FilterPanel title="Calendar" filters={CALENDAR_FILTERS} disableSearch setInitialURL />

      {urlParams.holidays && JSON.parse(urlParams.holidays) ? <HolidayView data={state.value} /> : <YearView data={state.value} />}
    </Stack>
  );
};
