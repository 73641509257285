import { ORDER_STATE_OPTIONS, SYMBOL_TYPE_OPTIONS } from '@features/admin/trading/tradingAdminProtos';
import { WidgetProps } from '@features/dashboard/types';
import { Filter, FilterType } from '@protos/filter';
import { TradingOrdersGrid } from '@shared/components/TradingOrdersGrid/TradingOrdersGrid';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { useTradableProductSymbolsContext } from '@shared/contexts/TradableProductSymbolsProvider';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { ColumnVisibleEvent } from 'ag-grid-enterprise';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import Settings from '../settings/Settings';
import Widget, { Content, Header } from '../Widget';
import { TradingBlotterWidgetPayload } from './tradingBlotterTypes';

type TradingBlotterProps = WidgetProps & {
  payload?: TradingBlotterWidgetPayload;
};

export const TradingBlotter = ({ payload, ...otherProps }: TradingBlotterProps) => {
  const { id, title } = otherProps;
  const { editWidgetPayloadById } = useDashboardContext();
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(payload?.hiddenColumns ?? []);
  const [isColumnPanelVisible, setIsColumnPanelVisible] = useState(false);
  const user = useUserContext();
  const products = useTradableProductSymbolsContext();

  const debouncedEditWidgetPayloadById = debounce(editWidgetPayloadById, 500);

  const filters: Filter[] = useMemo(
    () => [
      { type: FilterType.AUTOCOMPLETE, name: 'symbol_type', label: 'Symbol Type', options: SYMBOL_TYPE_OPTIONS },
      { type: FilterType.AUTOCOMPLETE, name: 'order_state', label: 'Order State', options: ORDER_STATE_OPTIONS },
      { type: FilterType.AUTOCOMPLETE, name: 'product_symbol', label: 'Product Symbol', options: products },
      { type: FilterType.DATETIMERANGE, name: 'date_range', label: 'Date Range' },
    ],
    [products]
  );

  const sideBarConfig = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        },
      ],
      defaultToolPanel: isColumnPanelVisible ? 'columns' : '',
    }),
    [isColumnPanelVisible]
  );

  const onColumnVisible = useCallback(
    (event: ColumnVisibleEvent) => {
      const { column, visible } = event;
      const columnId = column?.getColId();

      if (!columnId) return;

      const newHidden = visible ? hiddenColumns.filter(id => id !== columnId) : [...hiddenColumns, columnId];

      setHiddenColumns(newHidden);
      debouncedEditWidgetPayloadById(id, { hiddenColumns: newHidden });
    },
    [id, hiddenColumns, editWidgetPayloadById]
  );

  const onToolPanelVisibilityChanged = useCallback(({ visible }: { visible: boolean }) => {
    setIsColumnPanelVisible(visible);
  }, []);

  return (
    <Widget {...otherProps}>
      <Header title={title}>
        <Settings id={`trading-settings-${id}`} title={title} widgetId={id} />
      </Header>
      <Content>
        <TradingOrdersGrid
          domLayout="normal"
          isAdmin={false}
          filters={filters}
          sideBar={sideBarConfig}
          onColumnVisible={onColumnVisible}
          hiddenColumns={hiddenColumns}
          onToolPanelVisibilityChanged={onToolPanelVisibilityChanged}
          limitedToAccounts={user?.abn_trad_accounts}
        />
      </Content>
    </Widget>
  );
};
