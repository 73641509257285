import { useAdminApi } from '@hooks/useAdminApi';
import { ContractPNL } from '@protos/trading';
import { config } from '@services/context';
import { useEffect, useState } from 'react';

const PeriodicPnLPeriods = ['daily', 'weekly', 'monthly', 'yearly'];

export const useTradingPeriodicPnl = (productSymbol: string) => {
  const { apiClient } = useAdminApi();

  const [pnlData, setPnlData] = useState<ContractPNL[]>([]);
  const [isPnLLoading, setIsPnLLoading] = useState(true);

  useEffect(() => {
    if (!apiClient || !productSymbol) return;

    const fetchPnlData = async () => {
      const pnlResponseData = await Promise.allSettled(
        PeriodicPnLPeriods.map(period => apiClient.getContractPeriodicPnlData(config.otcAccountId, period, productSymbol.toLowerCase()))
      );
      const newPnlData: ContractPNL[] = [];
      pnlResponseData.forEach((data, index) => {
        if (data.status === 'fulfilled') {
          if (!data.value) return;

          if (data.value) {
            const transformedPnlData = {
              period: PeriodicPnLPeriods[index],
              cme: data.value.cme_pnl.total,
              ice: data.value.ice_pnl.total,
              total: data.value.pnl.total,
              fees: null,
            };
            newPnlData.push(transformedPnlData);
          }
        }
      });

      setPnlData(newPnlData);
    };

    setIsPnLLoading(true);
    fetchPnlData().then(() => {
      setIsPnLLoading(false);
    });
    const interval = setInterval(fetchPnlData, 1000 * 5);

    return () => clearInterval(interval);
  }, [apiClient, productSymbol]);

  return { pnlData, isPnLLoading };
};
