import { Order } from '@protos/v2/order';
import { StreamEvent, StreamV2, Subscription } from '../StreamV2';

export type OrderCallback = (order: Order) => void;

export const streamV2OrderService = (stream: StreamV2) => {
  const eventCallbacks = new Set<OrderCallback>();

  stream.onEvent('internal_orders', (event: StreamEvent) => {
    const order = event.asOrder();
    if (order) eventCallbacks.forEach(callback => callback(order));
  });

  const subscribe = (callback: OrderCallback) => {
    stream.subscribe(Subscription.order());
    eventCallbacks.add(callback);
  };

  const unsubscribe = (callback: OrderCallback) => {
    eventCallbacks.delete(callback);
    if (eventCallbacks.size === 0) stream.unsubscribe(Subscription.order());
  };

  return {
    subscribe,
    unsubscribe,
  };
};
