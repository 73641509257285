import { WidgetType } from '@features/dashboard/enums';
import { Widget } from '@features/dashboard/types';
import { useWidgets } from '@features/dashboard/widgets/useWidgets';
import { useStreamV2DashboardService } from '@hooks/useStreamV2DashboardService';
import { Dashboard } from '@shared/protos/v2/dashboard';
import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const DefaultDashboardWidgets = [WidgetType.News, WidgetType.Chart, WidgetType.Pricing, WidgetType.Settlements];

type DashboardState = {
  currentDashboard: Dashboard | undefined;
  allDashboards: Dashboard[];
  setCurrentDashboardById: (id: string) => void;
  addDashboard: (overrideDashboardName?: string) => Promise<Dashboard>;
  deleteDashboard: (id: string) => void;
  updateDashboard: (dashboard: Dashboard) => Promise<Dashboard>;
  editWidgetPayloadById: (id: string, newPayload: Record<string, any>) => void;
  deleteWidgetById: (id: string) => void;
  addNewWidgets: (newWidgets: Widget[]) => void;
  clearCurrentDashboard: () => void;
  isDashboardLoading: boolean;
  editDashboardLayouts: (layouts: any) => void;
};

type Props = {
  children: ReactNode;
};

const DashboardContext = createContext<DashboardState | undefined>(undefined);
DashboardContext.displayName = 'DashboardContext';

export const DashboardProvider: React.FC<Props> = ({ ...props }) => {
  const navigate = useNavigate();
  const { menuItems } = useWidgets();
  const { dashboardId } = useParams();
  const {
    currentDashboard,
    allDashboards,
    setCurrentDashboardById,
    addDashboard,
    updateDashboard,
    deleteDashboard,
    editWidgetPayloadById,
    deleteWidgetById,
    addNewWidgets,
    clearCurrentDashboard,
    isDashboardLoading,
    editDashboardLayouts,
    createDefaultDashboard,
    subscribeToDashboards,
  } = useStreamV2DashboardService();

  const value: DashboardState = {
    currentDashboard,
    allDashboards,
    setCurrentDashboardById,
    addDashboard,
    deleteDashboard,
    updateDashboard,
    editWidgetPayloadById,
    deleteWidgetById,
    addNewWidgets,
    clearCurrentDashboard,
    isDashboardLoading,
    editDashboardLayouts,
  };

  useEffect(() => {
    const { unsubscribe } = subscribeToDashboards();

    return () => {
      unsubscribe();
    };
  }, [subscribeToDashboards]);

  useEffect(() => {
    if (!window.location.pathname.includes('dashboard')) return;

    if (allDashboards.length) {
      if (dashboardId) {
        const URLDashboard = allDashboards.find(d => d.id === +dashboardId);
        if (URLDashboard) {
          if (URLDashboard.id !== currentDashboard?.id) {
            setCurrentDashboardById(URLDashboard.id.toString());
          }
        } else {
          setCurrentDashboardById(allDashboards[0].id.toString());
          navigate(`/dashboard/${allDashboards[0].id}`);
        }
      } else {
        setCurrentDashboardById(allDashboards[0].id.toString());
        navigate(`/dashboard/${allDashboards[0].id}`);
      }
    } else {
      createDefaultDashboard();
    }
  }, [currentDashboard, dashboardId, allDashboards, setCurrentDashboardById, createDefaultDashboard]);

  useEffect(() => {
    if (currentDashboard && currentDashboard.name === 'Default' && allDashboards.length === 1 && !currentDashboard.json?.widgets?.length) {
      const widgets = menuItems
        .filter(widget => DefaultDashboardWidgets.includes(widget.type))
        .map(widget => ({
          id: uuidv4(),
          type: widget.type,
          payload: widget.defaultPayload,
        }));

      if (widgets.length) {
        addNewWidgets(widgets);
      }
    }
  }, [currentDashboard, menuItems, addNewWidgets]);

  return <DashboardContext.Provider value={value} {...props} />;
};

export const useDashboardContext = (): DashboardState => {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error('useDashboardContext must be used within a DashboardProvider');
  }

  return context;
};
