import { SymbolInfo } from '@protos/charts';
import { Ticker } from '@protos/v2/ticker';
import { logger, streamV2Product } from '@services/context';
import { Bar, ResolutionString, SubscribeBarsCallback } from '@tradingview/types';
import { isSameDay } from 'date-fns';

const channelToSubscription: Map<string, SubscriptionItem> = new Map();

type SubscriptionItem = {
  listenerGuid: string;
  resolution: ResolutionString;
  ticker: string;
  productSymbol: string;
  lastDailyBar: Bar;
  handlers: {
    id: string;
    callback: SubscribeBarsCallback;
  }[];
};

function createBar(price: string, tradeTime: number, lastDailyBar: Bar) {
  return {
    time: tradeTime,
    open: parseFloat(price),
    high: parseFloat(price),
    low: parseFloat(price),
    close: parseFloat(price),
  };
}

function updateExistingBar(price: string, lastDailyBar: Bar) {
  return {
    ...lastDailyBar,
    high: Math.max(lastDailyBar.high, parseFloat(price)),
    low: Math.min(lastDailyBar.low, parseFloat(price)),
    close: parseFloat(price),
  };
}

const isNewTimeInterval = (currentTime: number, lastBarTime: number, interval: number) =>
  Math.floor(currentTime / interval) > Math.floor(lastBarTime / interval);

const createResolutionHandler = (interval: number) => {
  return (price: any, lastDailyBar: Bar, tradeTime: number) => {
    if (isNewTimeInterval(tradeTime, lastDailyBar.time, interval)) {
      return createBar(price, tradeTime, lastDailyBar);
    } else {
      return updateExistingBar(price, lastDailyBar);
    }
  };
};

const resolutionHandlers = {
  '1S': createResolutionHandler(1000),
  '1': createResolutionHandler(60 * 1000),
  '60': createResolutionHandler(60 * 60 * 1000),
  '1D': (price: any, lastDailyBar: Bar, tradeTime: number) => {
    return isSameDay(tradeTime, lastDailyBar.time) ? updateExistingBar(price, lastDailyBar) : createBar(price, tradeTime, lastDailyBar);
  },
};

const onTickerMessage = (ticker: Ticker) => {
  channelToSubscription.forEach((subscriptionItem, _channel) => {
    if (subscriptionItem.ticker.toLocaleLowerCase() === ticker.symbol) {
      const lastDailyBar = subscriptionItem.lastDailyBar;
      const tradeTime: number = new Date(ticker.timestamp).getTime();

      const handler = resolutionHandlers[subscriptionItem.resolution];
      if (!handler) {
        throw new Error(`Unsupported resolution: ${subscriptionItem.resolution}`);
      }

      const bar = handler(ticker.mid, lastDailyBar, tradeTime);
      if (bar) {
        subscriptionItem.lastDailyBar = bar;
        subscriptionItem.handlers.forEach(handler => handler.callback(bar));
      }
    }
  });
};

export function subscribeOnStream(
  symbolInfo: SymbolInfo,
  resolution: ResolutionString,
  onTick: SubscribeBarsCallback,
  listenerGuid: string,
  _onResetCacheNeededCallback: any,
  lastDailyBar: Bar | undefined,
  widgetId: string
) {
  // Note: We need the position of the widget id to be at the end so when we unsubscribe we can find the widget id (string.endWith)
  const channelString = `0~${symbolInfo.ticker}~${resolution}~${widgetId}`;

  if (!lastDailyBar) {
    logger.error('lastDailyBar is undefined');
    return;
  }

  const handler = {
    id: listenerGuid,
    callback: onTick,
  };

  let subscriptionItem: SubscriptionItem | undefined = channelToSubscription.get(channelString);

  if (subscriptionItem) {
    subscriptionItem.handlers.push(handler);
    return;
  }

  subscriptionItem = {
    listenerGuid,
    resolution,
    ticker: symbolInfo.ticker,
    productSymbol: symbolInfo.productSymbol,
    lastDailyBar,
    handlers: [handler],
  };

  channelToSubscription.set(channelString, subscriptionItem);
  logger.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);
  streamV2Product.subscribe([symbolInfo.productSymbol], onTickerMessage);
}

export function unsubscribeFromStream(listenerGuid: string, widgetId: string) {
  let isUnsubscribed = false;

  for (const [channelString, subscriptionItem] of channelToSubscription.entries()) {
    // Check if the subscription matches both listenerGuid and widgetId
    if (subscriptionItem.listenerGuid === listenerGuid && channelString.endsWith(widgetId)) {
      const handlerIndex = subscriptionItem.handlers.findIndex(handler => handler.id === listenerGuid);

      if (handlerIndex !== -1) {
        // Remove from handlers
        subscriptionItem.handlers.splice(handlerIndex, 1);

        if (subscriptionItem.handlers.length === 0) {
          // Unsubscribe from the channel if it is the last handler
          logger.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:', channelString);
          channelToSubscription.delete(channelString);

          if (channelToSubscription.size >= 1) {
            for (const [_, innerSubscriptionItem] of channelToSubscription.entries()) {
              if (innerSubscriptionItem.productSymbol === subscriptionItem.productSymbol) {
                isUnsubscribed = true;
                break;
              }
            }

            if (isUnsubscribed) break;
          }

          streamV2Product.unsubscribe([subscriptionItem.productSymbol], onTickerMessage);
        }

        isUnsubscribed = true;
        break; // Break the loop as we have found and handled the subscription
      }
    }
  }

  if (!isUnsubscribed) {
    logger.error(`No subscription found for listenerGuid: ${listenerGuid} and widgetId: ${widgetId}`);
  }
}
