import { useAdminApi } from '@hooks/useAdminApi';
import { Order } from '@protos/v2/order';
import { useEffect, useState } from 'react';

export const useOnyxBookData = () => {
  const { apiClient } = useAdminApi();

  const [orders, setOrders] = useState<Order[]>([]);
  const [products, setProducts] = useState<string[]>([]);
  const [symbolsPerProduct, setSymbolsPerProduct] = useState<Map<string, string[]>>(new Map());
  const [ordersPerSymbol, setOrdersPerSymbol] = useState<Map<string, Order[]>>(new Map());

  useEffect(() => {
    const fetchOrders = async () => {
      const orders = await apiClient?.ladderLoader({ is_live: true }).loadAllData();
      if (!orders) return;

      setOrders(orders);
    };

    fetchOrders();

    // Refetch data every 5s
    const interval = setInterval(fetchOrders, 5000);
    return () => clearInterval(interval);
  }, [apiClient]);

  useEffect(() => {
    const newProducts: string[] = [];
    const productMap = new Map<string, string[]>();
    const ordersMap = new Map<string, Order[]>();

    orders.forEach(order => {
      const stringifiedSymbol = typeof order.symbol === 'string' ? order.symbol : `${order.symbol.front}-${order.symbol.back}`;

      if (!productMap.has(order.product_symbol)) {
        newProducts.push(order.product_symbol);
        productMap.set(order.product_symbol, []);
      }
      if (!productMap.get(order.product_symbol)!.includes(stringifiedSymbol)) productMap.get(order.product_symbol)!.push(stringifiedSymbol);
      if (!ordersMap.has(stringifiedSymbol)) ordersMap.set(stringifiedSymbol, []);
      ordersMap.get(stringifiedSymbol)!.push(order);
    });

    setProducts(newProducts);
    setSymbolsPerProduct(productMap);
    setOrdersPerSymbol(ordersMap);
  }, [orders]);

  return { orders, products, symbolsPerProduct, ordersPerSymbol };
};
