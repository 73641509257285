import { ListItem, ListItemButton, Typography } from '@mui/material';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

type AppBarButtonProps = {
  path: string;
  name: string;
  icon: ReactNode;
};

export const AppBarButton = ({ path, name, icon }: AppBarButtonProps) => {
  const navigate = useNavigate();
  const { clearCurrentDashboard } = useDashboardContext();

  return (
    <ListItem key={name.replaceAll(' ', '-')} disablePadding>
      <ListItemButton
        selected={location.pathname.startsWith(path)}
        onClick={() => {
          clearCurrentDashboard();
          navigate(path);
        }}
        sx={{
          padding: 1,
          '&:hover': { backgroundColor: '#142535 !important' },
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          alignItems: 'center',
          gap: 1,
        }}
      >
        {icon}
        <Typography variant="body1" sx={{ fontSize: 11 }}>
          {name}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
};
