import { Box, Stack, styled } from '@mui/material';
import { Order } from '@protos/v2/order';
import theme from '@shared/themes/darkTheme';
import { filterColumnChooserFromMainMenu } from '@utils/contextMenuUtils';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { format } from 'date-fns';
import { useMemo } from 'react';

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 26px)',
    height: 'calc(100vh - 250px)',
  },
}));

const defaultColDef = {
  sortable: true,
  filter: false,
  floatingFilter: false,
  resizable: true,
} as ColDef;

const ORDERBOOK_COLUMNS: ColDef[] = [
  {
    field: 'account_id',
    headerName: 'Account ID',
  },
  {
    field: 'user',
    headerName: 'User',
  },
  {
    field: 'price',
    headerName: 'Price',
  },
  {
    field: 'amount',
    headerName: 'Amount',
  },
  {
    field: 'executed_amount',
    headerName: 'Ex. Amount',
    headerTooltip: 'Executed Amount',
  },
  {
    field: 'side',
    headerName: 'Side',
  },
  {
    field: 'created_timestamp',
    headerName: 'Creation Date',
    valueFormatter: ({ value }) => (value ? format(new Date(value), 'yyyy-MM-dd HH:mm') : ''),
  },
  {
    field: 'expiry_timestamp',
    headerName: 'Expiry Date',
    valueFormatter: ({ value }) => (value ? format(new Date(value), 'yyyy-MM-dd HH:mm') : ''),
  },
];

interface HolidayViewProps {
  data: Order[];
}

export const OrderBookTable = ({ data }: HolidayViewProps) => {
  const formattedData = useMemo(() => {
    return data
      .map(d => ({
        account_id: d.account_id,
        user: d.user.full_name,
        price: Number(d.price),
        amount: Number(d.amount),
        executed_amount: Number(d.executed_amount),
        side: d.side.toUpperCase(),
        created_timestamp: d.exchange_created_timestamp,
        expiry_timestamp: d.expiry_timestamp,
      }))
      .sort((a, b) => a.price - b.price || new Date(b.created_timestamp).getTime() - new Date(a.created_timestamp).getTime());
  }, [data]);

  return (
    <Stack direction="column" flex={3} position="relative">
      <StyledBox className="ag-theme-balham-dark custom-grid">
        <AgGridReact
          autoSizeStrategy={{ type: 'fitGridWidth' }}
          suppressDragLeaveHidesColumns
          suppressColumnMoveAnimation
          columnDefs={ORDERBOOK_COLUMNS}
          defaultColDef={defaultColDef}
          getMainMenuItems={filterColumnChooserFromMainMenu}
          rowData={formattedData}
          getRowStyle={params => ({ color: params.data.side === 'BUY' ? theme.palette.success.main : theme.palette.error.main })}
          containerStyle={{ height: 'calc(100vh - 250px)', width: '100%' }}
        />
      </StyledBox>
    </Stack>
  );
};
