import { localUrl, localWsUrl } from '@shared/utils/url';

class AppConfig {
  readonly apiUrl: string;
  readonly adminApiUrl: string;
  readonly wsUrl: string;
  readonly wsUrlV2: string;
  readonly env: Record<string, string>;
  readonly whatsAppTelephone: string;
  readonly otcAccountId: string;
  readonly otcSwapsAccountId: string;
  readonly websocketMaxTickerSubscriptionsPerRequest: number;
  readonly websocketMaxOrderBookTopSubscriptionsPerRequest: number;

  constructor(env: Record<string, string>) {
    this.apiUrl = localUrl(env.QUANT_REST_API_URL || '/v1').href;
    this.adminApiUrl = localUrl(env.ADMIN_REST_API_URL || '/a1').href;
    this.wsUrl = localWsUrl(env.QUANT_WS_URL || '/stream/v1').href;
    this.wsUrlV2 = localWsUrl(env.QUANT_WS_URL_V2 || '/stream/v2').href;
    this.env = env;
    this.whatsAppTelephone = env.WHATSAPP_TELEPHONE || 'unknown';
    this.otcAccountId = env.OTC_ACCOUNT_ID || '';
    this.otcSwapsAccountId = env.OTC_SWAPS_ACCOUNT_ID || '';
    this.websocketMaxTickerSubscriptionsPerRequest = Number(env.WEBSOCKET_MAX_TICKER_SUBSCRIPTIONS_PER_REQUEST || 100);
    this.websocketMaxOrderBookTopSubscriptionsPerRequest = Number(env.WEBSOCKET_MAX_ORDER_BOOK_TOP_SUBSCRIPTIONS_PER_REQUEST || 100);
  }

  get isProd(): boolean {
    return this.env.ENV === 'prod';
  }

  get isDev(): boolean {
    return this.env.ENV === 'dev' || this.env.ENV === 'local';
  }

  get isUAT(): boolean {
    return this.env.ENV === 'uat';
  }

  get maxProductsLimit(): number {
    return Math.min(this.websocketMaxTickerSubscriptionsPerRequest, this.websocketMaxOrderBookTopSubscriptionsPerRequest);
  }

  static fromDocument(): AppConfig {
    const encoded = document.querySelector("meta[name='onyx:session']") as any;
    let decoded = fromProcess();

    if (encoded) {
      try {
        const extra = JSON.parse(atob(encoded.getAttribute('content')));
        decoded = { ...decoded, ...extra };
      } catch (ignored) {
        // ignore
      }
    }

    return new AppConfig(decoded);
  }
}

const fromProcess = () =>
  Object.keys(process.env).reduce((o: any, key: string) => {
    let name = key;
    if (key.startsWith('REACT_APP_')) {
      name = key.substring(10);
    }
    o[name] = process.env[key];
    return o;
  }, {});

export default AppConfig;
