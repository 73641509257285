import { WidgetType } from '@features/dashboard/enums';
import { GetMainMenuItemsParams } from 'ag-grid-enterprise';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';

export const convertIconToHTMLString = (widgetType: WidgetType, getWidgetIcon: (widgetType: WidgetType) => JSX.Element | null) => {
  const icon = getWidgetIcon(widgetType);
  const div = document.createElement('div');
  const root = createRoot(div);
  flushSync(() => {
    root.render(icon);
  });
  return div.innerHTML;
};

export const filterColumnChooserFromMainMenu = (params: GetMainMenuItemsParams) => {
  const defaultMenuItems = params.defaultItems;
  return defaultMenuItems.filter(item => item !== 'columnChooser' && item !== 'resetColumns');
};
