import { StyledBox } from '@features/user-account/manage/components/ApiTokenBox';
import { Box, Stack } from '@mui/material';
import { filterColumnChooserFromMainMenu } from '@utils/contextMenuUtils';
import { RowClassParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { MixedGridCellEditor } from './MixedGridCellEditor';
import { MixedGridSettings } from './MixedGridSettings';
import { useMixedGrid } from './useMixedGrid';

interface MixedGridProps {
  isShowTenors?: boolean;
  selectedAccount: string;
  gridId: string;
  savedData?: {
    [tenorId: string]: {
      value: string;
      spread: string;
      size: string;
    };
  };
  midPriceValidity: string;
  onNavigateToGrid: (rowIndex: number) => void;
  onNavigateToPreviousGrid: (rowIndex: number) => void;
  gridRef?: React.RefObject<AgGridReact>;
  selectedRowIndex: number;
  onRowIndexChange: (rowIndex: number) => void;
}

export const MixedGrid = ({
  isShowTenors = false,
  selectedAccount,
  gridId,
  savedData,
  midPriceValidity,
  onNavigateToGrid,
  onNavigateToPreviousGrid,
  selectedRowIndex,
  onRowIndexChange,
  gridRef,
}: MixedGridProps) => {
  const { rowData, settings, setSettings, columnDefs, navigateToNextCell, handleCellKeyDown, getRowStyle } = useMixedGrid({
    isShowTenors,
    selectedAccount,
    gridId,
    savedData,
    onNavigateToGrid,
    onNavigateToPreviousGrid,
  });

  return (
    <Stack spacing={2} width="100%" height="100%">
      <MixedGridSettings settings={settings} onSettingsChange={setSettings} />
      <Box display="flex" flex={1} width="100%" height="100%">
        <StyledBox className="ag-theme-balham-dark custom-grid" sx={{ flex: 1, width: '100%', height: '100%' }}>
          <AgGridReact<any>
            ref={gridRef}
            columnDefs={columnDefs}
            rowData={rowData}
            suppressRowHoverHighlight
            rowClassRules={{
              'ag-row-focus': (params: RowClassParams) => {
                return params.node.rowIndex === selectedRowIndex;
              },
            }}
            getMainMenuItems={filterColumnChooserFromMainMenu}
            onCellKeyDown={handleCellKeyDown}
            getRowStyle={getRowStyle}
            navigateToNextCell={navigateToNextCell}
            enterNavigatesVerticallyAfterEdit={true}
            onCellFocused={event => {
              if (event.rowIndex !== undefined && event.rowIndex !== null) {
                onRowIndexChange?.(event.rowIndex);
              }
            }}
            defaultColDef={{
              editable: true,
              suppressHeaderMenuButton: true,
              valueFormatter: params => {
                if (
                  !Number.isNaN(Number(params.value)) &&
                  params.value !== '' &&
                  params.column.getColId() !== 'size' &&
                  params.column.getColId() !== 'tenor'
                ) {
                  return Number(params.value).toFixed(2);
                }
                return params.value;
              },
              cellEditor: MixedGridCellEditor,
              cellEditorParams: {
                settings,
                selectedAccount,
                gridId,
                midPriceValidity,
              },
            }}
            domLayout="autoHeight"
            containerStyle={{ width: '100%', height: '100%' }}
            autoSizeStrategy={{ type: 'fitGridWidth' }}
          />
        </StyledBox>
      </Box>
    </Stack>
  );
};
