import { OrderBookTopMessage } from '@protos/v2/orderBookTop';
import { ChannelType, StreamEvent, StreamV2, Subscription } from '@services/StreamV2';

export type OrderBookTopCallback = (orderBookTop: OrderBookTopMessage) => void;

export const streamV2OrderBookTopService = (stream: StreamV2) => {
  const subscribers: Map<string, Set<OrderBookTopCallback>> = new Map();

  stream.onEvent(ChannelType.OrderBookTop, (event: StreamEvent) => {
    const orderBookTopQuotes = event.asOrderBookTop();

    if (!Array.isArray(orderBookTopQuotes)) return;

    orderBookTopQuotes?.forEach(orderBookTopQuote => {
      const symbol = orderBookTopQuote.product_symbol;
      const callbacks = subscribers.get(symbol);
      if (callbacks) {
        callbacks.forEach(callback => callback(orderBookTopQuote));
      }
    });
  });

  stream.onConnect(() => {
    const allSymbols = Array.from(subscribers.keys());
    if (allSymbols.length > 0) {
      stream.subscribe(Subscription.orderBookTop({ products: allSymbols }));
    }
  }, ChannelType.OrderBookTop);

  const subscribe = (products: string[], callback: OrderBookTopCallback) => {
    const toSubscribe: string[] = [];

    products.forEach(product => {
      if (!subscribers.has(product)) {
        subscribers.set(product, new Set());
        toSubscribe.push(product);
      }
      subscribers.get(product)?.add(callback);
    });

    if (toSubscribe.length > 0) {
      stream.subscribe(Subscription.orderBookTop({ products: toSubscribe }));
    }
  };

  const unsubscribe = (products: string[], callback: OrderBookTopCallback) => {
    const toUnsubscribe: string[] = [];

    products.forEach(product => {
      const callbacks = subscribers.get(product);
      if (callbacks) {
        callbacks.delete(callback);
        if (callbacks.size === 0) {
          subscribers.delete(product);
          toUnsubscribe.push(product);
        }
      }
    });

    if (toUnsubscribe.length > 0) {
      stream.unsubscribe(Subscription.orderBookTop({ products: toUnsubscribe }));
    }
  };

  return { subscribe, unsubscribe };
};
