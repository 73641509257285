import { Divider, IconButton, List, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ResearchReportsResponse, ReportItem } from '../types';
import { ReportGroup } from './ReportGroup';
import { SingleReport } from './SingleReport';
import { ReportsContainer, ReportTitle, TitleGroup } from '../styles';
import { HeaderContainer } from '../styles';
import { useState, useMemo } from 'react';
import SwapVertIcon from '@mui/icons-material/SwapVert';
interface ReportsListProps {
  availableReports: ResearchReportsResponse | undefined;
  currentSelected: ReportItem | undefined;
  isVisible: boolean;
  onReportClick: (report: ReportItem) => void;
  onToggleReportsList: () => void;
  onParentReportClick: (report: string) => void;
  open: Record<string, boolean>;
}

export const ReportsList = ({
  availableReports,
  currentSelected,
  isVisible,
  onReportClick,
  onToggleReportsList,
  onParentReportClick,
  open,
}: ReportsListProps) => {
  const Header = ({
    isVisible,
    onToggleReportsList,
    onSortReports,
  }: {
    isVisible: boolean;
    onToggleReportsList: () => void;
    onSortReports: (newestFirst: boolean) => void;
  }) => (
    <HeaderContainer isVisible={isVisible}>
      {isVisible && (
        <TitleGroup>
          <ReportTitle variant="h6">Report List</ReportTitle>
          <IconButton onClick={() => onSortReports(!newestFirst)} title={newestFirst ? 'sort by oldest' : 'sort by newest'}>
            <SwapVertIcon />
          </IconButton>
        </TitleGroup>
      )}
      <IconButton onClick={onToggleReportsList} title={isVisible ? 'hide reports' : 'show reports'}>
        {isVisible ? <CloseIcon /> : <KeyboardArrowRightIcon />}
      </IconButton>
    </HeaderContainer>
  );

  const [newestFirst, setNewestFirst] = useState(true);
  const sortedReports = useMemo(() => {
    if (!availableReports) return undefined;
    return [...availableReports].sort(
      (a, b) => new Date(b.report[0].latest_publish_date).getTime() - new Date(a.report[0].latest_publish_date).getTime()
    );
  }, [availableReports]);

  const sortReports = () => {
    setNewestFirst(!newestFirst);
    if (sortedReports) {
      return sortedReports.sort((a, b) => {
        return newestFirst
          ? new Date(a.report[0].latest_publish_date).getTime() - new Date(b.report[0].latest_publish_date).getTime()
          : new Date(b.report[0].latest_publish_date).getTime() - new Date(a.report[0].latest_publish_date).getTime();
      });
    }
    return sortedReports;
  };
  return (
    <ReportsContainer isVisible={isVisible} sx={{ borderRight: '1px solid rgba(255, 255, 255, 0.12)' }}>
      <Header isVisible={isVisible} onToggleReportsList={onToggleReportsList} onSortReports={sortReports} />
      <Divider />
      {isVisible && (
        <List sx={{ overflow: 'auto', maxHeight: '100%' }}>
          {sortedReports && sortedReports.length > 0 ? (
            sortedReports.map(report =>
              report.report.length > 1 ? (
                <ReportGroup
                  key={report.category}
                  category={report.category}
                  reports={report.report}
                  isOpen={open[report.category]}
                  onParentClick={() => onParentReportClick(report.category)}
                  onReportClick={onReportClick}
                  currentSelected={currentSelected}
                />
              ) : (
                <SingleReport
                  key={report.category}
                  category={report.category}
                  report={report.report[0]}
                  onReportClick={onReportClick}
                  isSelected={
                    currentSelected?.report_name === report.report[0].report_name &&
                    currentSelected?.report_link === report.report[0].report_link &&
                    currentSelected?.category === report.category
                  }
                />
              )
            )
          ) : (
            <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.secondary', padding: 2 }}>
              No reports to display
            </Typography>
          )}
        </List>
      )}
    </ReportsContainer>
  );
};
