import { streamV2ProductRisk } from '@services/context';
import { ProductRiskCallback } from '@services/v2/streamV2ProductRisk';
import { useCallback, useEffect, useRef } from 'react';

/**
 * A hook to subscribe to the product risk stream.
 *
 * @param callback - The callback to be called when the product risk data is received.
 * @param symbol - The symbol of the product to subscribe to.
 * @param accountId - The account ID of the product to subscribe to.
 * @param isReadyToSubscribe - A boolean to indicate if the stream is ready to subscribe.
 * @returns A callback to unsubscribe from the product risk stream manually or else the unmount
 *          of the component will unsubscribe from the stream automatically.
 */
export const useStreamV2ProductRisk = (callback: ProductRiskCallback, symbol: string, accountId: string, isReadyToSubscribe?: boolean) => {
  const target = useRef<ProductRiskCallback>(callback);

  /**
   * A callback to unsubscribe from the product risk stream.
   * The caller can use this to manually unsubscribe from the stream if needed or else
   * the unmount of the component will unsubscribe from the stream automatically.
   */
  const unsubscribeRisk = useCallback(() => {
    streamV2ProductRisk.unsubscribe({ product_symbol: symbol, account_id: accountId }, callback);
  }, [symbol, accountId, callback]);

  // Subscribe to the product risk stream.
  useEffect(() => {
    if (!symbol || !accountId || !isReadyToSubscribe) return;

    target.current = callback;
    streamV2ProductRisk.subscribe({ product_symbol: symbol, account_id: accountId }, callback);

    return () => {
      unsubscribeRisk();
    };
  }, [symbol, accountId, isReadyToSubscribe]);

  return unsubscribeRisk;
};
