import { useAdminApi } from '@hooks/useAdminApi';
import { useProductBySymbol } from '@hooks/useProductBySymbol';
import { Alert } from '@protos/alerts';
import { PricingDAGResponse } from '@protos/pricing';
import {
  ADMIN_PRODUCT_COMPOSITIONS_COLUMNS,
  ADMIN_PRODUCT_CONTRACTS_COLUMNS,
  ADMIN_PRODUCT_EXCHANGE_SYMBOLS_COLUMNS,
  ADMIN_PRODUCT_RELATED_COLUMNS,
  Product,
} from '@protos/product';
import { ADMIN_SETTLEMENTS_FOR_PRODUCT_COLUMNS, SettlementPriceWithDate } from '@protos/settlementProducts';
import { CellClassParams, CellClickedEvent } from 'ag-grid-enterprise';
import { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

const OnProductCellClickOptions = (navigate: NavigateFunction) => {
  return {
    onCellClicked(event: CellClickedEvent) {
      if (event.column.getColId() === 'symbol') {
        navigate(`/reference/products/${event.value}`);
      }
    },
    cellStyle(params: CellClassParams) {
      if (params.column.getColId() === 'symbol') {
        return { cursor: 'pointer' };
      }
      return null;
    },
  };
};

export const useProductPage = () => {
  const { apiClient } = useAdminApi();
  const { symbol } = useParams();
  const navigate = useNavigate();
  const { product } = useProductBySymbol(symbol!);

  const [relatedProductsData, setRelatedProductsData] = useState<Product[]>([]);
  const [settlementsRowData, setSettlementsRowData] = useState<SettlementPriceWithDate[]>([]);
  const [pricingDagData, setPricingDagData] = useState<PricingDAGResponse[]>([]);
  const [alertsData, setAlertsData] = useState<Alert[]>([]);
  const [isLoadingBlotters, setIsLoadingBlotters] = useState(true);

  const ProductContractsBlotters = useMemo(
    () => [
      {
        title: 'Compositions',
        data: product?.composition ?? [],
        columns: ADMIN_PRODUCT_COMPOSITIONS_COLUMNS,
        colDefOptions: OnProductCellClickOptions(navigate),
      },
      {
        title: 'Contracts',
        data: product?.contracts ?? [],
        columns: ADMIN_PRODUCT_CONTRACTS_COLUMNS,
      },
      {
        title: 'Settlements',
        data: settlementsRowData,
        columns: ADMIN_SETTLEMENTS_FOR_PRODUCT_COLUMNS,
        colDefOptions: OnProductCellClickOptions(navigate),
      },
    ],
    [product, settlementsRowData]
  );

  const ProductInfoBlotters = useMemo(
    () => [
      {
        title: 'Exchange Symbols',
        data: product?.exchange_symbols ?? [],
        columns: ADMIN_PRODUCT_EXCHANGE_SYMBOLS_COLUMNS,
        fullWidth: true,
      },
      {
        title: 'Related Products',
        data: relatedProductsData,
        columns: ADMIN_PRODUCT_RELATED_COLUMNS,
        colDefOptions: OnProductCellClickOptions(navigate),
      },
    ],
    [product, relatedProductsData]
  );

  useEffect(() => {
    async function fetchSettlements(symbol: string) {
      if (!apiClient || !product?.specification) return;
      const newSettlements = await apiClient?.getSettlementsForProduct(symbol);
      return newSettlements.prices;
    }

    async function fetchAlerts(symbol: string) {
      if (!apiClient) return;
      const alerts = await apiClient?.getAlertsForProduct(symbol);
      return alerts;
    }

    async function fetchRelatedProducts(symbol: string) {
      if (!apiClient) return;
      const relatedProducts = await apiClient?.getRelatedProductsForProduct(symbol);
      return relatedProducts;
    }

    async function fetchPricingDAG(symbol: string) {
      if (!apiClient) return;
      const pricingDAG = await apiClient?.getPricingDAGForProduct(symbol);
      return pricingDAG;
    }

    if (product && product.symbol) {
      setIsLoadingBlotters(true);
      Promise.allSettled([
        fetchSettlements(product.symbol),
        fetchAlerts(product.symbol),
        fetchRelatedProducts(product.symbol),
        fetchPricingDAG(product.symbol),
      ])
        .then(data => {
          if (data[0].status === 'fulfilled') setSettlementsRowData(data[0].value ?? []);
          if (data[1].status === 'fulfilled') setAlertsData(data[1].value ?? []);
          if (data[2].status === 'fulfilled') setRelatedProductsData(data[2].value ?? []);
          if (data[3].status === 'fulfilled') setPricingDagData(data[3].value ?? []);
        })
        .finally(() => setIsLoadingBlotters(false));
    }

    return () => {
      setSettlementsRowData([]);
      setAlertsData([]);
      setRelatedProductsData([]);
      setPricingDagData([]);
    };
  }, [apiClient, setSettlementsRowData, product]);

  return { product, symbol, ProductContractsBlotters, ProductInfoBlotters, alertsData, isLoadingBlotters, pricingDagData };
};
