import { Filter, FilterType } from '@protos/filter';
import { ContractPNL, ContractRisk, Execution, ExecutionReport } from '@protos/trading';
import { OrderType } from '@protos/v2/order';
import { priceFormatterService } from '@services/PriceFormatterService';
import { formatDatetimeWithMilliseconds } from '@utils/date';
import { formatNumberWithCommas } from '@utils/number';
import { ColDef } from 'ag-grid-enterprise';
import { TradingAdminABNReportsActionsCellRenderer } from './components/abn-reports/TradingAdminABNReportsActionsCellRenderer';
import { ExecutionReconcileCellRenderer } from './components/ExecutionReconcileCellRenderer';
import { RiskRecon } from './components/risk-recon/types';

export enum SymbolTypeEnum {
  UNSPECIFIED = 'unspecified',
  FLAT = 'flat',
  SPREAD = 'spread',
  BUTTERFLY = 'butterfly',
}

enum SideEnum {
  UNSPECIFIED = 'unspecified',
  BUY = 'buy',
  SELL = 'sell',
}

enum TradingGroupEnum {
  UNSPECIFIED = 'unspecified',
  OTC = 'otc',
  OTC_CLIENT = 'otc_client',
  EXCHANGE = 'exchange',
}

enum OrderStateEnum {
  UNSPECIFIED = 'unspecified',
  PENDING_CREATED = 'pending_created',
  CREATED = 'created',
  NEW = 'new',
  PARTIALLY_FILLED = 'partially_filled',
  CANCEL_CREATED = 'cancel_created',
  FILLED = 'filled',
  PENDING_UPDATE = 'pending_update',
  PENDING_CANCEL = 'pending_cancel',
  UPDATE_CREATED = 'update_created',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
  ERRORED = 'errored',
}

export const SYMBOL_TYPE_OPTIONS: SymbolTypeEnum[] = [
  SymbolTypeEnum.UNSPECIFIED,
  SymbolTypeEnum.FLAT,
  SymbolTypeEnum.SPREAD,
  SymbolTypeEnum.BUTTERFLY,
];

export const SIDE_OPTIONS: SideEnum[] = [SideEnum.UNSPECIFIED, SideEnum.BUY, SideEnum.SELL];

export const TRADING_GROUP_OPTIONS: TradingGroupEnum[] = [
  TradingGroupEnum.UNSPECIFIED,
  TradingGroupEnum.OTC,
  TradingGroupEnum.OTC_CLIENT,
  TradingGroupEnum.EXCHANGE,
];

export const ORDER_STATE_OPTIONS: OrderStateEnum[] = [
  OrderStateEnum.UNSPECIFIED,
  OrderStateEnum.PENDING_CREATED,
  OrderStateEnum.CREATED,
  OrderStateEnum.NEW,
  OrderStateEnum.PARTIALLY_FILLED,
  OrderStateEnum.CANCEL_CREATED,
  OrderStateEnum.FILLED,
  OrderStateEnum.PENDING_UPDATE,
  OrderStateEnum.PENDING_CANCEL,
  OrderStateEnum.UPDATE_CREATED,
  OrderStateEnum.CANCELLED,
  OrderStateEnum.EXPIRED,
  OrderStateEnum.ERRORED,
];

export const ORDER_TYPE_OPTIONS: OrderType[] = [
  OrderType.ALGO_CQG_ARRIVAL,
  OrderType.ALGO_QB,
  OrderType.FILL_OR_KILL,
  OrderType.INSTANT,
  OrderType.LIMIT,
  OrderType.LIMIT_MAKER,
  OrderType.LIQUIDATION,
  OrderType.MARKET,
  OrderType.MARKET_TO_LIMIT,
  OrderType.STOP,
  OrderType.STOP_LIMIT,
  OrderType.UNSPECIFIED,
  OrderType.VWAP,
];

export const TRADING_ADMIN_EXECUTIONS_COLUMNS: ColDef<Execution>[] = [
  {
    field: 'exchange_timestamp',
    headerName: 'Timestamp',
    valueFormatter: ({ value }) => (value ? formatDatetimeWithMilliseconds(value) : ''),
    headerTooltip: 'Exchange Timestamp',
  },
  {
    field: 'local_received_timestamp',
    headerName: 'DB Timestamp',
    valueFormatter: ({ value }) => (value ? formatDatetimeWithMilliseconds(value) : ''),
    headerTooltip: 'Local Received Timestamp',
  },
  {
    field: 'user.full_name',
    headerName: 'User Full Name',
    headerTooltip: 'User Full Name',
  },
  {
    field: 'side',
    headerName: 'Side',
    headerTooltip: 'Side',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
    cellStyle: ({ value }) => ({ color: value.toLowerCase().includes('buy') ? '#4189E8' : '#CD4B4B' }),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    headerTooltip: 'Amount',
    valueFormatter: ({ value }) => (value ? value.toLocaleString() : ''),
  },
  {
    field: 'symbol',
    headerName: 'Symbol',
    headerTooltip: 'Symbol',
    valueFormatter: ({ value }) => (value ? (typeof value === 'string' ? value.toUpperCase() : `${value.front} / ${value.back}`.toUpperCase()) : ''),
  },
  {
    field: 'product_symbol',
    headerName: 'Product',
    headerTooltip: 'Product Symbol',
  },
  {
    field: 'price',
    headerName: 'Price',
    headerTooltip: 'Price',
    valueFormatter: ({ value }) => (value === null || value === undefined ? '' : priceFormatterService.formatProductPrice(value)),
  },
  {
    field: 'order_id',
    headerName: 'Order ID',
    headerTooltip: 'Order ID',
  },
  {
    field: 'account_id',
    headerName: 'Account Id',
    headerTooltip: 'Account Id',
  },
  {
    field: 'trading_group',
    headerName: 'Venue',
    headerTooltip: 'Trading Group',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
  },
  {
    field: 'exchange',
    headerName: 'Exchange',
    headerTooltip: 'Exchange',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
  },
  {
    field: 'id',
    headerName: 'Execution ID',
    headerTooltip: 'Id',
  },
  {
    field: 'reconcile_timestamp',
    headerName: 'Reconcile Timestamp',
    valueFormatter: ({ value }) => (value ? formatDatetimeWithMilliseconds(value.toLocaleString()) : ''),
    headerTooltip: 'Reconcile Timestamp',
  },
  {
    field: 'exchange_execution_id',
    headerName: 'Exchange Execution ID',
    headerTooltip: 'Exchange Execution ID',
  },
  {
    field: 'report_id',
    headerName: 'Report ID',
    headerTooltip: 'Report ID',
    valueFormatter: ({ value }) => (value ? value.toLocaleString() : ''),
  },
  {
    field: 'report_ack_timestamp',
    headerName: 'Report ACK Timestamp',
    headerTooltip: 'Report ACK Timestamp',
    valueFormatter: ({ value }) => (value ? formatDatetimeWithMilliseconds(value.toLocaleString()) : ''),
  },
  {
    field: 'correlation_id',
    headerName: 'Correlation ID',
    headerTooltip: 'Correlation ID',
  },
  {
    headerName: 'Actions',
    cellRenderer: ExecutionReconcileCellRenderer,
    pinned: 'right',
  },
];

export const TRADING_ADMIN_EXECUTIONS_FILTERS: Filter[] = [
  { type: FilterType.AUTOCOMPLETE, name: 'side', label: 'Side', options: SIDE_OPTIONS },
  { type: FilterType.TEXTFIELD, name: 'order_id', label: 'Order Id' },
  { type: FilterType.AUTOCOMPLETE, name: 'trading_group', label: 'Trading Group', options: TRADING_GROUP_OPTIONS },
  { type: FilterType.DATETIMERANGE, label: 'Date Range', name: 'date_range' },
];

export const TRADING_ADMIN_PNL_FILTERS: Filter[] = [
  { type: FilterType.AUTOCOMPLETE, name: 'trading_group', label: 'Trading Group', options: TRADING_GROUP_OPTIONS, defaultValue: 'otc' },
  { type: FilterType.AUTOCOMPLETE, name: 'period', label: 'Period', options: ['hourly', 'daily'], defaultValue: 'daily' },
  { type: FilterType.DATETIMERANGE, label: 'Date Range', name: 'date_range' },
];

const getPriceCellClass = ({ value }) => (value ? (+value < 0 ? 'negative-value' : 'positive-value') : '');
export const TRADING_ADMIN_RISK_BLOTTER_COLUMNS: ColDef<ContractRisk>[] = [
  {
    field: 'tenorDisplay',
    headerName: 'Tenor',
    headerTooltip: 'Tenor Name',
    resizable: true,
    flex: 1,
  },
  {
    field: 'tenor',
    headerName: 'Total',
    headerTooltip: 'Total',
    resizable: true,
    valueFormatter: ({ value }) => (value ? formatNumberWithCommas(+value) : ''),
    cellClass: getPriceCellClass,
    flex: 1,
    type: 'rightAligned',
  },
  {
    field: 'cmeTenor',
    headerName: 'CME',
    headerTooltip: 'CME Exchange',
    resizable: true,
    valueFormatter: ({ value }) => (value ? formatNumberWithCommas(+value) : ''),
    cellClass: getPriceCellClass,
    flex: 1,
    type: 'rightAligned',
  },
  {
    field: 'iceTenor',
    headerName: 'ICE',
    headerTooltip: 'ICE Exchange',
    resizable: true,
    valueFormatter: ({ value }) => (value ? formatNumberWithCommas(+value) : ''),
    cellClass: getPriceCellClass,
    flex: 1,
    type: 'rightAligned',
  },
];

const getDiffCellClass = ({ value }) => (value === 0 ? 'positive-value' : 'negative-value');
export const TRADING_ADMIN_RISK_RECON_BLOTTER_COLUMNS: ColDef<RiskRecon>[] = [
  {
    field: 'tenorDisplay',
    headerName: 'Tenor',
    headerTooltip: 'Tenor Name',
    resizable: true,
    flex: 1,
  },
  {
    field: 'liveTenor',
    headerName: 'Live Total',
    headerTooltip: 'Live Total',
    resizable: true,
    valueFormatter: ({ value }) => (value ? formatNumberWithCommas(+value) : ''),
    cellClass: getPriceCellClass,
    flex: 1,
    type: 'rightAligned',
  },
  {
    field: 'dbTenor',
    headerName: 'DB Total',
    headerTooltip: 'Database Total',
    resizable: true,
    valueFormatter: ({ value }) => (value ? formatNumberWithCommas(+value) : ''),
    cellClass: getPriceCellClass,
    flex: 1,
    type: 'rightAligned',
  },
  {
    field: 'liveCmeTenor',
    headerName: 'Live CME',
    headerTooltip: 'Live CME Exchange',
    resizable: true,
    valueFormatter: ({ value }) => (value ? formatNumberWithCommas(+value) : ''),
    cellClass: getPriceCellClass,
    flex: 1,
    type: 'rightAligned',
  },
  {
    field: 'dbCmeTenor',
    headerName: 'DB CME',
    headerTooltip: 'Database CME Exchange',
    resizable: true,
    valueFormatter: ({ value }) => (value ? formatNumberWithCommas(+value) : ''),
    cellClass: getPriceCellClass,
    flex: 1,
    type: 'rightAligned',
  },
  {
    field: 'liveIceTenor',
    headerName: 'Live ICE',
    headerTooltip: 'Live ICE Exchange',
    resizable: true,
    valueFormatter: ({ value }) => (value ? formatNumberWithCommas(+value) : ''),
    cellClass: getPriceCellClass,
    flex: 1,
    type: 'rightAligned',
  },
  {
    field: 'dbIceTenor',
    headerName: 'DB ICE',
    headerTooltip: 'Database ICE Exchange',
    resizable: true,
    valueFormatter: ({ value }) => (value ? formatNumberWithCommas(+value) : ''),
    cellClass: getPriceCellClass,
    flex: 1,
    type: 'rightAligned',
  },
  {
    field: 'totalDiff',
    headerName: 'Total Diff',
    headerTooltip: 'Difference between Live Total and DB Total',
    resizable: true,
    valueFormatter: ({ value }) => (value ? formatNumberWithCommas(+value) : '-'),
    cellClass: getDiffCellClass,
    flex: 1,
    type: 'rightAligned',
  },
];

export const TRADING_ADMIN_PNL_BLOTTER_COLUMNS: ColDef<ContractPNL>[] = [
  {
    field: 'period',
    headerName: 'Period',
    headerTooltip: 'Period Name',
    flex: 1,
  },
  {
    field: 'total',
    headerName: 'Total',
    headerTooltip: 'Total',
    resizable: true,
    valueFormatter: ({ value }) => (value ? formatNumberWithCommas(+value) : ''),
    flex: 1,
    type: 'rightAligned',
    cellClass: getPriceCellClass,
  },
  {
    field: 'cme',
    headerName: 'CME',
    headerTooltip: 'CME Exchange',
    resizable: true,
    valueFormatter: ({ value }) => (value ? formatNumberWithCommas(+value) : ''),
    flex: 1,
    type: 'rightAligned',
    cellClass: getPriceCellClass,
  },
  {
    field: 'ice',
    headerName: 'ICE',
    headerTooltip: 'ICE Exchange',
    resizable: true,
    valueFormatter: ({ value }) => (value ? formatNumberWithCommas(+value) : ''),
    flex: 1,
    type: 'rightAligned',
    cellClass: getPriceCellClass,
  },
  {
    field: 'fees',
    headerName: 'Fees',
  },
];

export const TRADING_ADMIN_ABN_REPORT_COLUMNS: ColDef<ExecutionReport>[] = [
  {
    field: 'id',
    headerName: 'ID',
    headerTooltip: 'ID',
    valueFormatter: ({ value }) => (value ? value.toLocaleString() : ''),
    width: 90,
  },
  {
    field: 'version',
    headerName: 'Version',
    headerTooltip: 'Version',
    valueFormatter: ({ value }) => (value ? value.toLocaleString() : ''),
    width: 90,
  },
  {
    field: 'n_executions',
    headerName: 'No. of Executions',
    headerTooltip: 'Number of Executions',
    valueFormatter: ({ value }) => (value ? value.toLocaleString() : ''),
    width: 120,
  },
  {
    field: 'file_name',
    headerName: 'File Name',
    headerTooltip: 'File Name',
    cellStyle: value => (value.data?.file_url ? { cursor: 'pointer' } : null),
    flex: 1,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    headerTooltip: 'Created At',
    valueFormatter: ({ value }) => (value ? formatDatetimeWithMilliseconds(value) : ''),
    flex: 1,
  },
  {
    field: 'acknowledged_at',
    headerName: 'Acknowledged At',
    headerTooltip: 'Acknowledged At',
    valueFormatter: ({ value }) => (value ? formatDatetimeWithMilliseconds(value) : ''),
    flex: 1,
  },
  {
    headerName: 'Actions',
    cellRenderer: TradingAdminABNReportsActionsCellRenderer,
    pinned: 'right',
  },
];

export const TRADING_ADMIN_ABN_REPORT_FILTERS: Filter[] = [{ type: FilterType.TEXTFIELD, name: 'id', label: 'Report ID' }];
