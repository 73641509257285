import { Box, Paper, Typography } from '@mui/material';
import InlineGroup from '@shared/components/InlineGroup';
import MultilineGroup from '@shared/components/MultilineGroup';
import RoundedBox from '@shared/components/RoundedBox';
import { TextInputReadOnlyBox } from '@shared/components/TextInputReadOnlyBox';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { User } from '@shared/protos/user';
import { chunkArray } from '@shared/utils/array';
import React, { useEffect, useState } from 'react';
import { ApiTokenBox } from './components/ApiTokenBox';
import { EditButton } from './components/EditButton';
import { EditLadderLimitsDialog } from './components/EditLadderLimitsDialog';
import { EditTradeLimitsDialog } from './components/EditTradeLimitsDialog';
import { UserInfoList } from './components/UserInfoList';

type Props = {
  user: User;
};

const generateUserInfoAdmin = (user: User) => [
  {
    label: 'Id',
    value: user.id ? `${user.id}` : undefined,
  },
  {
    label: 'Full name',
    value: user.full_name ? `${user.full_name}` : undefined,
  },
  {
    label: 'Account name',
    value: user.account_name ? `${user.account_name}` : undefined,
  },
  {
    label: 'Email',
    value: user.email ? `${user.email}` : undefined,
  },
  {
    label: 'Whatsapp number',
    value: user.whatsapp_number ? `${user.whatsapp_number}` : undefined,
  },
  {
    label: 'Modified at',
    value: user.modified_at ? `${user.modified_at}` : undefined,
  },
  {
    label: 'License Expiry',
    value: user.license_expiry ? `${user.license_expiry}` : undefined,
  },
  {
    label: 'Websocket limit',
    value: user.websocket_limit ? `${user.websocket_limit}` : undefined,
  },
  {
    label: 'Slippage BP',
    value: user.slippage_bp ? `${user.slippage_bp}` : undefined,
  },
];

const getInputFieldsAdmin = (user: User) => {
  return generateUserInfoAdmin(user).map((data, idx) => (
    <TextInputReadOnlyBox label={data.label} value={data.value ?? '-'} key={`user_info_admin_${idx}`} />
  ));
};

export const UserAdminProfileForm: React.FC<Props> = ({ user: userProp }) => {
  const { isMobile } = useScreenSize();
  const userViewing = useUserContext();
  const numberOfColumns = isMobile ? 1 : 2;
  const [user, setUser] = useState(userProp);
  const [inputs, setInputs] = useState(getInputFieldsAdmin(userProp));
  const [isEditTradesDialogOpen, setIsEditTradesDialogOpen] = useState(false);
  const [isEditLadderDialogOpen, setIsEditLadderDialogOpen] = useState(false);
  const chunks = chunkArray(inputs, numberOfColumns);

  useEffect(() => {
    setInputs(getInputFieldsAdmin(user));
  }, [user]);

  return (
    <>
      <Box style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <Paper style={{ padding: 20 }}>
          <MultilineGroup>
            <Typography variant="h4" gutterBottom sx={{ marginBottom: '20px' }}>
              Flux User
            </Typography>
            {chunks.map((chunk, idx) => (
              <InlineGroup key={`group_${idx}`}>{chunk}</InlineGroup>
            ))}
          </MultilineGroup>
        </Paper>
        <Paper style={{ padding: 20 }}>
          {user.client_type_list.includes('api') && (
            <MultilineGroup>
              <InlineGroup>
                <ApiTokenBox label={'API token'} apiToken={user.api_token} key={'api_token'} />
              </InlineGroup>
            </MultilineGroup>
          )}
        </Paper>
        <Paper style={{ padding: 20 }}>
          <RoundedBox>
            <UserInfoList list={user.permissions} header={'Permissions'} showDivider={true} />
            <UserInfoList list={user.product_groups} header={'Product Groups'} showDivider={true} />
            <UserInfoList list={user.cot_product_groups} header={'COT Product Groups'} showDivider={true} />
            <UserInfoList list={user.client_type_list} header={'Client type'} showDivider={true} />
            <UserInfoList list={user.snapshot_periods} header={'Snapshot periods'} showDivider={true} />
            <UserInfoList
              list={user.ladder_limits.map(({ symbol, limit }) => `${symbol.toLocaleUpperCase()} : ${limit}`)}
              header={'Ladder Limits'}
              helperText={'Below is formatted as - "Symbol : Ladder Input Limit"'}
              showDivider={true}
              extraOptions={
                userViewing?.permissions.includes('trading_admin') && user?.permissions.includes('ladder') ? (
                  <EditButton onClick={() => setIsEditLadderDialogOpen(true)} />
                ) : undefined
              }
            />
            <UserInfoList list={user.abn_trad_accounts.map(elem => elem.toLocaleUpperCase())} header={'Trading Accounts'} showDivider={true} />
            <UserInfoList
              list={user.trade_limits.map(
                ({ symbol, limit, exchange }) => `${symbol.toLocaleUpperCase()} : ${limit} : ${String(exchange).toLocaleUpperCase()}`
              )}
              header={'Trading Limits'}
              helperText={'Below is formatted as - "Symbol : Trading Size Limit : Exchanges"'}
              extraOptions={
                userViewing?.permissions.includes('trading_admin') && user?.permissions.includes('trading') ? (
                  <EditButton onClick={() => setIsEditTradesDialogOpen(true)} />
                ) : undefined
              }
            />
          </RoundedBox>
        </Paper>
      </Box>
      <EditTradeLimitsDialog
        user={user}
        isOpen={isEditTradesDialogOpen}
        onClose={() => setIsEditTradesDialogOpen(false)}
        onUserUpdated={user => setUser(user)}
      />
      <EditLadderLimitsDialog
        user={user}
        isOpen={isEditLadderDialogOpen}
        onClose={() => setIsEditLadderDialogOpen(false)}
        onUserUpdated={user => setUser(user)}
      />
    </>
  );
};
