import { styled } from '@mui/material';
import IconButton, { iconButtonClasses } from '@mui/material/IconButton';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.text.primary,
  },
  [`&.${iconButtonClasses.disabled}`]: {
    color: '#09fb9d',
  },
}));
