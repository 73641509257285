import { useAdminApi } from '@hooks/useAdminApi';
import { useScreenSize } from '@hooks/useScreenSize';
import { useSnapshotsV2Streaming } from '@hooks/useSnapshotsV2Streaming';
import { useStreamV2ProductRisk } from '@hooks/useStreamV2ProductRisk';
import { Box, Divider, Stack } from '@mui/material';
import { ContractRisk } from '@protos/trading';
import { ProductRiskMessage } from '@protos/v2/productRisk';
import { Snapshot } from '@protos/v2/snapshots';
import { config } from '@services/context';
import { handleOrderIdCellClick } from '@services/TradingGridService';
import { Select } from '@shared/components/Select';
import { TradingOrdersGrid } from '@shared/components/TradingOrdersGrid/TradingOrdersGrid';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { CellClickedEvent } from 'ag-grid-enterprise';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAsync } from 'react-use';
import { transformRiskResponse } from '../../tradingAdminUtils';
import { TradingRiskChartAndMeta } from './TradingRiskChartAndMeta';

const DEFAULT_PRODUCT_SYMBOL = 'BRT';

export const TradingRisk = () => {
  // State Initialization
  const { tenors, loaded: isTenorsLoaded } = useUserProductsAndTenorsContext();
  const { apiClient } = useAdminApi();
  const { isMobileLandscape } = useScreenSize();
  const [data, setData] = useState<ContractRisk[]>([]);
  const [metaData, setMetaData] = useState<{ version: number; timestamp: string } | undefined>();
  const [snapshot, setSnapshot] = useState<Snapshot | undefined>();
  const [selectedProduct, setSelectedProduct] = useState<string>(() => {
    const product = localStorage.getItem('selectedProduct');
    return product || DEFAULT_PRODUCT_SYMBOL;
  });

  const navigate = useNavigate();

  const onProductChange = (product: string) => {
    setSelectedProduct(product);
    unsubscribeRisk();
    setData([]);
    setMetaData(undefined);
    setSnapshot(undefined);
    localStorage.setItem('selectedProduct', product);
  };

  const onRiskStreaming = useCallback(
    (risk: ProductRiskMessage) => {
      if (!isTenorsLoaded) return;

      const { version: currentVersion } = metaData || {};
      if (currentVersion && risk.version <= currentVersion) return;

      const { data, version, timestamp } = transformRiskResponse(risk, tenors);
      setData(data);
      if (version && timestamp) setMetaData({ version, timestamp });
    },
    [tenors, isTenorsLoaded, metaData, selectedProduct]
  );

  const onSnapshotStreaming = useCallback(
    (incomingSnapshot: Snapshot) => {
      if (incomingSnapshot && incomingSnapshot.product === selectedProduct.toLowerCase()) {
        setSnapshot(incomingSnapshot);
      }
    },
    [selectedProduct]
  );

  const productsState = useAsync(async () => {
    const products = await apiClient?.productsLoader({ tradable: true, calendar_type: 'unspecified' }).loadAllData();
    return products?.map(product => product.symbol.toUpperCase()) || [];
  }, [apiClient]);

  const unsubscribeRisk = useStreamV2ProductRisk(
    onRiskStreaming,
    selectedProduct.toLowerCase(),
    config.otcAccountId,
    !!config.otcAccountId && isTenorsLoaded && tenors.length > 0
  );

  useSnapshotsV2Streaming(onSnapshotStreaming, isTenorsLoaded && tenors.length > 0);

  const onCellClicked = useCallback((params: CellClickedEvent) => handleOrderIdCellClick(params, navigate), [navigate]);

  return (
    <Stack width="100%">
      <Select label="Product" options={productsState.value || []} onChange={onProductChange} value={selectedProduct} />
      <TradingRiskChartAndMeta
        selectedProduct={selectedProduct.toLowerCase()}
        data={data}
        metaData={metaData}
        snapshot={snapshot}
        isMobileLandscape={isMobileLandscape}
      />
      {!isMobileLandscape && (
        <Box display="flex">
          <Divider />
          <TradingOrdersGrid hideFilters maxHeight={300} selectedProduct={selectedProduct} onCellClicked={onCellClicked} />
        </Box>
      )}
    </Stack>
  );
};
