import { Box, Divider, Stack, Switch, Typography, useMediaQuery } from '@mui/material';
import { ContractRisk } from '@protos/trading';
import { Snapshot } from '@protos/v2/snapshots';
import theme from '@shared/themes/darkTheme';
import { useState } from 'react';
import { TradingRiskGraph } from './TradingRiskGraph';
import { TradingRiskGrid } from './TradingRiskGrid';
import { TradingRiskKillSwitch } from './TradingRiskKillSwitch';

interface TradingRiskChartAndMetaProps {
  data: ContractRisk[];
  metaData?: { version: number; timestamp: string };
  snapshot?: Snapshot;
  isMobileLandscape: boolean;
  selectedProduct: string;
}

export const TradingRiskChartAndMeta: React.FC<TradingRiskChartAndMetaProps> = ({ data, metaData, snapshot, isMobileLandscape, selectedProduct }) => {
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [showGraph, setShowGraph] = useState(true);

  return (
    <Box display="flex" flex={1}>
      {!isMdScreen ? (
        <Box display="flex" flexGrow={1} minHeight={0}>
          <TradingRiskGraph isMobileLandscape={isMobileLandscape} metaData={metaData} data={data} />
          <Divider orientation="vertical" />
          <Stack width={!isMobileLandscape ? '30%' : '100%'} height="100%">
            <TradingRiskKillSwitch snapshot={snapshot} />
            <TradingRiskGrid data={data} />
          </Stack>
        </Box>
      ) : (
        <Stack display="flex" flex={1} gap={1} padding={2}>
          <Box display="flex" gap={1}>
            <Typography variant="h7">Grid</Typography>
            <Switch
              key={`mobile-risk-switch`}
              id="risk"
              name="enabled"
              size="small"
              defaultChecked={showGraph}
              checked={showGraph}
              onChange={(_, val) => setShowGraph(val)}
            />
            <Typography variant="h7">Graph</Typography>
          </Box>
          <TradingRiskKillSwitch snapshot={snapshot} />
          {showGraph ? (
            <Box display="flex" flexGrow={1}>
              <TradingRiskGraph isMobileLandscape={isMobileLandscape} metaData={metaData} data={data} />
            </Box>
          ) : (
            <TradingRiskGrid data={data} />
          )}
        </Stack>
      )}
    </Box>
  );
};
