import { Box, Snackbar, Typography } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton';
import { useCallback, useState } from 'react';
import { copyToClipboard } from '../utils';
import { StyledIconButton } from './StyledIconButton';

type Props = {
  message: string;
  textToCopied: string;
} & IconButtonProps;

export const CopyButton: React.FC<Props> = ({ message, textToCopied, onClick, ...buttonProps }) => {
  const [open, setOpen] = useState(false);

  const copy = useCallback(e => {
    setOpen(true);
    copyToClipboard(textToCopied);
    if (onClick) onClick(e);
  }, []);

  return (
    <Box>
      <StyledIconButton {...buttonProps} onClick={copy} disabled={open}>
        <i className={open ? 'ri-check-double-fill' : 'ri-file-copy-line'} />
      </StyledIconButton>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        onClose={() => setOpen(false)}
        message={<Typography>{message}</Typography>}
        autoHideDuration={1000}
      />
    </Box>
  );
};
