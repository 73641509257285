import { Grid } from '@features/dashboard/widgets/grid';
import { ContractRisk } from '@protos/trading';
import { RowClassParams } from 'ag-grid-community';
import { TRADING_ADMIN_RISK_BLOTTER_COLUMNS } from '../../tradingAdminProtos';

const getRowId = ({ data }): string => data?.tenorCode;

type Props = {
  data: ContractRisk[];
};

const getRowStyle = (params: RowClassParams) => {
  if (params.data?.tenorCode === 'SUM') {
    return { borderBottom: '1px solid grey', borderTop: '1px solid grey', fontWeight: 'bold' };
  }

  return undefined;
};

export const TradingRiskGrid: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Grid<ContractRisk>
        rowData={data}
        getRowStyle={getRowStyle}
        columnDefs={TRADING_ADMIN_RISK_BLOTTER_COLUMNS}
        getRowId={getRowId}
        tooltipShowDelay={0}
        enableCellTextSelection
        defaultColDef={{ enableCellChangeFlash: true }}
        autoSizeStrategy={{ type: 'fitGridWidth' }}
      />
    </>
  );
};
