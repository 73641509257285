import { Month } from '@features/reference/calendar/components/Month';
import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Stack } from '@mui/material';
import { CalendarDay } from '@protos/calendar';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import { useMemo } from 'react';

interface YearViewProps {
  data: CalendarDay[];
}

export const YearView = ({ data }: YearViewProps) => {
  const { urlParams } = useURLSearchParams();

  const chosenYear = useMemo(() => (urlParams.year ? Number(urlParams.year) : new Date().getFullYear()), [urlParams.year]);

  return (
    <Stack display="flex" direction="row" justifyContent="center" flexWrap="wrap" sx={{ marginTop: 5 }}>
      {Array(12)
        .fill('')
        .map((_, id) => (
          <Month
            key={`month-${id}`}
            month={new Date(`${chosenYear}-${(id + 1).toString().padStart(2, '0')}-01`)}
            days={data}
            calendarType={urlParams.calendar ?? 'default'}
          />
        ))}
    </Stack>
  );
};
