import { Box } from '@mui/material';
import { CreateLadderRequest, LadderSettings } from '@protos/ladders';
import { Side } from '@protos/trading';
import { addSecondsToNow } from '@utils/date';
import { CustomCellEditorProps } from 'ag-grid-react';
import { debounce } from 'lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useLaddersApi } from '../hooks/useLaddersApi';
import NumberInput from './NumberInput';

interface OrderInputCellEditorProps extends CustomCellEditorProps {
  settings: LadderSettings;
  selectedAccount: string;
  onUpdateSettings: (symbol: string, tenorKey: string, values: { bid: number | ''; offer: number | '' }) => Promise<void>;
  lastAvailableSprTenor?: any;
}

export const OrderInputCellEditor = memo((props: OrderInputCellEditorProps) => {
  const { createLadder, expireLadder } = useLaddersApi();
  const bidInputRef = useRef<HTMLDivElement>(null);
  const offerInputRef = useRef<HTMLDivElement>(null);
  const { bid, offer } = props.value ? props.value : { bid: '', offer: '' };

  const [bidValue, setBidValue] = useState<string>(bid);
  const [offerValue, setOfferValue] = useState<string>(offer);

  const onKeyDown = useCallback(
    debounce(async (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      if (event.key === 'Tab') {
        event.preventDefault();

        const bidInputElement = bidInputRef.current?.querySelector('input');
        const offerInputElement = offerInputRef.current?.querySelector('input');

        if (event.target === bidInputElement) {
          offerInputElement?.focus();
          offerInputElement?.select();
        } else if (event.target === offerInputElement) {
          bidInputElement?.focus();
          bidInputElement?.select();
        }
      } else if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
        const currentFocusedCell = props.api.getFocusedCell();

        if (currentFocusedCell) {
          let nextRowIndex = currentFocusedCell.rowIndex;
          let nextColumn = currentFocusedCell.column;

          if (event.key === 'ArrowUp') {
            nextRowIndex -= 1;
          } else if (event.key === 'ArrowDown') {
            nextRowIndex += 1;
          } else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            const allColumns = props.api.getAllDisplayedColumns();
            const currentColumnIndex = allColumns.findIndex(column => column.getColId() === currentFocusedCell.column.getColId());

            if (event.key === 'ArrowLeft' && currentColumnIndex > 0) {
              nextColumn = allColumns[currentColumnIndex - 1];
            } else if (event.key === 'ArrowRight' && currentColumnIndex < allColumns.length - 1) {
              nextColumn = allColumns[currentColumnIndex + 1];
            }
          }

          if (nextRowIndex < 0 || nextRowIndex >= props.api.getDisplayedRowCount()) return;

          const futureCellRow = document.querySelector(`.ag-row[row-index="${nextRowIndex}"] .ag-cell[col-id="${nextColumn.getId()}"]`);

          if (futureCellRow?.classList.contains('no-content')) return;

          props.api.setFocusedCell(nextRowIndex, nextColumn.getId());
        }
      } else if (event.key === 'Escape') {
        props.stopEditing();
        props.api.clearFocusedCell();
      } else if (event.key === 'Enter' && props.colDef.field) {
        const dataKeyToUpdate = props.colDef.field;
        const newRow = {
          ...props.node.data,
          [dataKeyToUpdate]: { bid: bidValue !== '' ? +bidValue : '', offer: offerValue !== '' ? +offerValue : '' },
        };

        const baseSymbol = newRow.symbol.includes('spr') ? newRow.symbol.split('spr')[0] : newRow.symbol;

        const getForwardTenorForSpr = () => {
          if (dataKeyToUpdate === 'value') {
            const rowIndex = props?.api?.getFocusedCell()?.rowIndex;
            const totalRows = props.api.getDisplayedRowCount();
            const isLastRow = rowIndex === totalRows - 1 || rowIndex === undefined;

            // Use last available tenor for last row, otherwise get next row's data
            const nextRowData = isLastRow ? props?.lastAvailableSprTenor : props.api.getDisplayedRowAtIndex(rowIndex + 1)?.data;

            return newRow.symbol.includes('spr') ? nextRowData?.id : newRow.id;
          }
          return dataKeyToUpdate;
        };

        const forwardTenor = getForwardTenorForSpr();
        const fullSymbol = newRow.symbol.includes('spr') ? `${baseSymbol}${newRow.id}-${baseSymbol}${forwardTenor}` : `${baseSymbol}${forwardTenor}`;

        if (!bidValue && !offerValue) {
          await expireLadder(fullSymbol, props.node.data.account_id || '');
        } else {
          const ladderCreateRequestPayload: CreateLadderRequest = {
            symbol: fullSymbol,
            expiry_timestamp: addSecondsToNow(+newRow.validity),

            orders: [
              ...(bidValue !== '' ? [{ price: +bidValue, side: Side.BUY, amount: +newRow.size, hidden: false }] : []),
              ...(offerValue !== '' ? [{ price: +offerValue, side: Side.SELL, amount: +newRow.size, hidden: false }] : []),
            ],
            account_id: props.node.data.account_id || '',
          };

          const createLadderResponse = await createLadder(ladderCreateRequestPayload);
          if (!createLadderResponse) return;
        }
        const isSpreadGrid = dataKeyToUpdate !== 'value';
        const tenorKey = isSpreadGrid ? `${props.node.data.id}-${dataKeyToUpdate}` : props.node.data.id;

        await props.onUpdateSettings(props.node.data.symbol, tenorKey, {
          bid: bidValue !== '' ? +bidValue : '',
          offer: offerValue !== '' ? +offerValue : '',
        });
        props.api.applyTransactionAsync({
          update: [newRow],
        });

        const allColumns = props.api.getAllDisplayedColumns();
        const currentColumnIndex = allColumns.findIndex(column => column.getColId() === props.colDef.field);

        const nextColumn = currentColumnIndex < allColumns.length - 1 ? allColumns[currentColumnIndex + 1] : allColumns[currentColumnIndex];

        props.api.setFocusedCell(dataKeyToUpdate === 'value' ? (props.node.rowIndex || 0) + 1 : props.node.rowIndex || 0, nextColumn.getColId());
      }
    }, 100),
    [props, bidValue, offerValue, createLadder]
  );

  useEffect(() => {
    if (!props.value) return;

    const { bid, offer } = props.value;
    setBidValue(bid);
    setOfferValue(offer);
  }, [props.value]);

  useEffect(() => {
    const inputElement = bidInputRef.current?.querySelector('input');
    inputElement?.focus();
    inputElement?.select();
  }, []);

  return (
    <Box display="flex" width="100%" height="100%">
      <NumberInput
        ref={bidInputRef}
        value={bidValue === '-0' ? '-' : bidValue?.toString() || ''}
        onChange={(val: number | undefined) => {
          const strValue = Object.is(val, -0) ? '-' : val?.toString() || '';
          setBidValue(strValue);
        }}
        placeholder="BID"
        onKeyDown={onKeyDown}
        min={-99999}
        step={props.settings.tick_size}
        sx={{
          '& .MuiInputBase-input': {
            color: '#4189E8',
            fontSize: '11px',
            '--Input-minHeight': '0',
          },
          '& .MuiInputBase-root': {
            borderRadius: '0px',
            height: 20,
          },
        }}
        allowNegative={true}
      />
      <NumberInput
        ref={offerInputRef}
        value={offerValue === '-0' ? '-' : offerValue?.toString() || ''}
        onChange={(val: number | undefined) => {
          const strValue = Object.is(val, -0) ? '-' : val?.toString() || '';
          setOfferValue(strValue);
        }}
        placeholder="ASK"
        min={-99999}
        step={props.settings.tick_size}
        onKeyDown={onKeyDown}
        sx={{
          '& .MuiInputBase-input': {
            color: '#CD4B4B',
            fontSize: '11px',
            '--Input-minHeight': '0',
          },
          '& .MuiInputBase-root': {
            borderRadius: '0px',
            height: 20,
          },
        }}
        allowNegative={true}
      />
    </Box>
  );
});
