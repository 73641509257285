import { BlotterWidget } from '@shared/components/BotterWidget';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useApi } from '@shared/hooks/useApi';
import { PRODUCTS_FILTERS, PRODUCT_COLUMNS } from '@shared/protos/options';
import { ColDef } from 'ag-grid-enterprise';
import { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

const getRowId = ({ data }): string => data?.id?.toString();

const defaultColDef = (navigate: NavigateFunction) => {
  return {
    sortable: true,
    filter: false,
    floatingFilter: false,
    resizable: true,
    width: 150,
    onCellClicked(event) {
      if (event.column.getColId() === 'symbol') {
        navigate(`/reference/products/${event.data.symbol}`);
      }
    },
    cellStyle(params) {
      if (params.column.getColId() === 'symbol') {
        return { cursor: 'pointer' };
      }
      return null;
    },
  } as ColDef;
};

const Products = () => {
  const { apiClient } = useApi();
  const dataLoader = apiClient?.productsLoader();
  const navigate = useNavigate();
  const user = useUserContext();

  const isOnyxUser = useMemo(() => user?.permissions.includes('onyx_user'), [user]);

  if (!dataLoader) return null;

  return (
    <BlotterWidget
      dataLoader={dataLoader}
      columnDefs={PRODUCT_COLUMNS.filter(col => isOnyxUser || col.field !== 'mdx_name')}
      getRowId={getRowId}
      filters={PRODUCTS_FILTERS}
      defaultColDef={defaultColDef(navigate)}
      enableCellTextSelection
      autoSizeStrategy={{
        type: 'fitCellContents',
        colIds: ['full_name'],
      }}
    />
  );
};

export default Products;
