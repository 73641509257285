import { RollingRowSettings } from '@features/dashboard/widgets/shared/types';

export interface CellStyle {
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
}

export interface OCLTradingSettings {
  pricingSheet?: {
    selectedColumns?: string[];
    selectedRows?: string[];
    columnsOrder?: string[];
    userRollingRowSettings?: RollingRowSettings;
    isOverrideRolling?: boolean;
    pricesGridCellStyles?: Record<string, CellStyle>;
  };
  mixed?: {
    mixedGridData?: Record<string, any>;
  };
  BidOffer?: {
    nonSpreadGridData?: Record<string, any>;
    spreadGridData?: Record<string, any>;
  };
}

export enum OCLTradingType {
  PRICING = 'pricing',
  MIXED = 'mixed',
  BID_OFFER_ONLY = 'bid-offer-only',
  ORDER_ENTRY = 'order-entry',
  ALL_ORDERS = 'all-orders',
}
