import { IconButtonProps } from '@mui/material/IconButton';
import { StyledIconButton } from './StyledIconButton';

export const EditButton: React.FC<IconButtonProps> = ({ ...buttonProps }) => {
  return (
    <StyledIconButton {...buttonProps}>
      <i className={'ri-edit-line'} />
    </StyledIconButton>
  );
};
