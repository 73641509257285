import { Paper } from '@mui/material';
import { Order, Side } from '@protos/v2/order';
import InlineGroup from '@shared/components/InlineGroup';
import MultilineGroup from '@shared/components/MultilineGroup';
import { TextInputReadOnlyBox } from '@shared/components/TextInputReadOnlyBox';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import theme from '@shared/themes/darkTheme';
import { chunkArray } from '@shared/utils/array';
import { format } from 'date-fns';
import { ReactNode } from 'react';

type Props = {
  order: Order;
};

const getBooleanStyles = (value: boolean) => ({
  '& .MuiInputBase-input': { color: value ? theme.palette.success.main : theme.palette.error.main, fontWeight: 'bold' },
});

const getSideStyles = (value: Side) => ({
  '& .MuiInputBase-input': { color: value === 'buy' ? '#4189E8' : '#CD4B4B' },
});

const generateOrderInfo = (order: Order) => [
  {
    label: 'Account Id',
    value: order.account_id,
  },
  {
    label: 'Created Timestamp',
    value: format(new Date(order.created_timestamp), 'dd/MM/yyyy HH:mm'),
  },
  {
    label: 'Exchange Created Timestamp',
    value: format(new Date(order.exchange_created_timestamp), 'dd/MM/yyyy HH:mm'),
  },
  {
    label: 'Last Updated Timestamp',
    value: format(new Date(order.exchange_last_updated_timestamp), 'dd/MM/yyyy HH:mm'),
  },
  {
    label: 'Local Received Timestamp',
    value: format(new Date(order.local_received_timestamp), 'dd/MM/yyyy HH:mm'),
  },
  {
    label: 'Expiry Timestamp',
    value: order.expiry_timestamp ? format(new Date(order.expiry_timestamp), 'dd/MM/yyyy HH:mm') : '-',
  },
  {
    label: 'Client Order Id',
    value: order.client_order_id,
  },
  {
    label: 'Exchange',
    value: order.exchange?.toUpperCase(),
  },
  {
    label: 'Trading Group',
    value: order.trading_group?.toUpperCase(),
  },
  {
    label: 'Side',
    value: order.side.toUpperCase(),
    sx: getSideStyles(order.side),
  },
  {
    label: 'Symbol',
    value: typeof order.symbol === 'string' ? order.symbol.toUpperCase() : `${order.symbol.front} / ${order.symbol.back}`.toUpperCase(),
  },
  {
    label: 'Product Symbol',
    value: order.product_symbol?.toUpperCase(),
  },
  {
    label: 'Amount',
    value: order.amount,
  },
  {
    label: 'Original Amount',
    value: order.original_amount,
  },
  {
    label: 'Visible Amount',
    value: order.visible_amount ?? '',
  },
  {
    label: 'Executed Amount',
    value: order.executed_amount ?? '',
  },
  {
    label: 'Price',
    value: order.price,
  },
  {
    label: 'Executed Avg Price',
    value: order.executed_avg_price ?? '',
  },
  {
    label: 'Order Type',
    value: order.order_type?.toUpperCase(),
  },
  {
    label: 'Order State',
    value: order.order_state?.toUpperCase(),
  },
  {
    label: 'User ID',
    value: order.user.id,
  },
  {
    label: 'User Account Name',
    value: order.user.account_name,
  },
  {
    label: 'User Full Name',
    value: order.user.full_name,
  },
  {
    label: 'User Email',
    value: order.user.email,
  },
  {
    label: 'Reduce only',
    value: order.reduce_only,
    sx: getBooleanStyles(order.reduce_only),
  },
  {
    label: 'Hidden',
    value: order.hidden,
    sx: getBooleanStyles(order.reduce_only),
  },
  {
    label: 'Duration [Seconds]',
    value: order.duration_seconds ?? '',
  },
  {
    label: 'Params Version',
    value: order.params_version ?? '',
  },
  {
    label: 'Algo Order Mode',
    value: order.algo_order_mode,
  },
  {
    label: 'Correlation ID',
    value: order.correlation_id,
  },
  {
    label: 'Exchange Order ID',
    value: order.exchange_order_id,
  },
  {
    label: 'Exchange Order State',
    value: order.exchange_order_state?.toUpperCase(),
  },
];

const getInputFields = (order: Order) => {
  return generateOrderInfo(order).map((data, idx) => (
    <TextInputReadOnlyBox label={data.label} value={`${data.value}`} key={`order_${order.id}_info_${idx}`} sx={data.sx} />
  ));
};

export const OrderInfo: React.FC<Props> = ({ order }) => {
  const { isMobile } = useScreenSize();

  const inputs = getInputFields(order);

  const numberOfColumns = isMobile ? 1 : 6;

  const chunks = chunkArray(inputs, numberOfColumns);

  return (
    <Paper
      style={{
        background: 'transparent',
      }}
    >
      <MultilineGroup>
        {chunks.map((chunk, idx) => {
          if (chunk.every(item => item === null)) return null;
          return <InlineGroup key={`${order.id}_group_${idx}`}>{chunk as ReactNode[]}</InlineGroup>;
        })}
      </MultilineGroup>
    </Paper>
  );
};
