import { orderMatchesFilters } from '@features/admin/trading/tradingAdminUtils';
import { useAdminApi } from '@hooks/useAdminApi';
import { useApi } from '@hooks/useApi';
import { useStreamV2Orders } from '@hooks/useStreamV2Orders';
import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Filter } from '@protos/filter';
import { Order } from '@protos/v2/order';
import { formatParams } from '@services/ApiUtils';
import { priceFormatterService } from '@services/PriceFormatterService';
import { BlotterWidget } from '@shared/components/BotterWidget';
import { generateEnvBaseURL } from '@utils/url';
import { CellClickedEvent, ColumnVisibleEvent, GridApi, SideBarDef, ToolPanelVisibleChangedEvent } from 'ag-grid-enterprise';
import { useCallback, useState } from 'react';
import { useAsync } from 'react-use';
import { TRADING_ADMIN_ORDER_COLUMNS, TRADING_ADMIN_ORDER_FILTERS } from './TradingOrderGridConsts';

const getRowId = ({ data }): string => data?.id?.toString();

const defaultColDef = {
  sortable: true,
  filter: false,
  floatingFilter: false,
  resizable: true,
  width: 150,
  enableCellChangeFlash: true,
};

const generateCSVDownloadUrl = (params?: any) => {
  return `${generateEnvBaseURL()}/orders${params ? formatParams(params) : ''}`;
};

interface TradingOrdersProps {
  hideFilters?: boolean;
  accountId?: string;
  maxHeight?: number;
  selectedProduct?: string;
  enableCSVDownload?: boolean;
  onCellClicked?: (params: CellClickedEvent) => void;
  domLayout?: 'normal' | 'autoHeight';
  filters?: Filter[];
  sideBar?: SideBarDef;
  onColumnVisible?: (event: ColumnVisibleEvent) => void;
  hiddenColumns?: string[];
  onToolPanelVisibilityChanged?: (event: ToolPanelVisibleChangedEvent) => void;
  isAdmin?: boolean;
  limitedToAccounts?: string[];
}

interface OrdersLoaderParams {
  account_id?: string;
  product_symbol?: string;
}

export const TradingOrdersGrid = ({
  hideFilters = false,
  maxHeight,
  enableCSVDownload = false,
  selectedProduct,
  onCellClicked,
  domLayout = 'autoHeight',
  filters,
  sideBar,
  onColumnVisible,
  hiddenColumns,
  onToolPanelVisibilityChanged,
  isAdmin = true,
  limitedToAccounts,
}: TradingOrdersProps) => {
  const { apiClient } = isAdmin ? useAdminApi() : useApi();
  const dataLoaderParams = () => {
    const params: OrdersLoaderParams = {};
    if (selectedProduct) params.product_symbol = selectedProduct.toLowerCase();
    return params;
  };
  const dataLoader = apiClient?.ordersLoader(dataLoaderParams());

  const { urlParams } = useURLSearchParams();

  const [blotterApi, setBlotterApi] = useState<GridApi<Order | null>>();
  const [isReadyToSubscribe, setIsReadyToSubscribe] = useState<boolean>(false);

  const onGetGridApi = useCallback((gridApi: GridApi<Order | null>) => {
    if (gridApi) {
      setBlotterApi(gridApi);
    }
  }, []);

  useAsync(async () => {
    if (!blotterApi || !selectedProduct) return;

    await blotterApi.setColumnFilterModel('product_symbol', {
      filterType: 'text',
      type: 'startsWith',
      filter: selectedProduct,
    });
    blotterApi.onFilterChanged();
  }, [blotterApi, selectedProduct]);

  const onDataRendered = useCallback(() => {
    setIsReadyToSubscribe(true);
    if (blotterApi && hiddenColumns?.length) {
      blotterApi.setColumnsVisible(hiddenColumns, false);
    }
  }, [blotterApi, hiddenColumns]);

  const onStreamOrder = useCallback(
    (order: Order) => {
      if (!blotterApi) return;

      const isRowInGrid = !!blotterApi.getRowNode(order.id.toString());
      if (!isRowInGrid && !orderMatchesFilters(order, urlParams, limitedToAccounts)) {
        return;
      }
      const transformedOrder: Order = {
        ...order,
        amount: priceFormatterService.removeTrailZeros(order.amount),
        executed_amount: order.executed_amount ? priceFormatterService.removeTrailZeros(order.executed_amount) : '',
        price: order.price ? priceFormatterService.removeTrailZeros(order.price) : '',
        executed_avg_price: order.executed_avg_price ? priceFormatterService.removeTrailZeros(order.executed_avg_price) : '',
      };
      blotterApi.applyTransaction(isRowInGrid ? { update: [transformedOrder] } : { add: [transformedOrder] });

      setTimeout(() => {
        if (!isRowInGrid) {
          const newRowNode = blotterApi.getRowNode(order.id.toString());
          if (newRowNode) blotterApi.flashCells({ rowNodes: [newRowNode], fadeDuration: 2000 });
        }
      }, 200);
    },
    [blotterApi, urlParams]
  );

  useStreamV2Orders(onStreamOrder, isReadyToSubscribe);

  if (!dataLoader) return null;

  return (
    <BlotterWidget
      getGridApi={onGetGridApi}
      selectedProduct={selectedProduct}
      dataLoader={dataLoader}
      columnDefs={TRADING_ADMIN_ORDER_COLUMNS}
      getRowId={getRowId}
      defaultColDef={defaultColDef}
      onCellClicked={onCellClicked}
      onFirstDataRendered={onDataRendered}
      animateRows={true}
      domLayout={domLayout}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      {...(hideFilters ? undefined : { filters: filters ?? TRADING_ADMIN_ORDER_FILTERS })}
      maxHeight={maxHeight}
      csvDownloadUrl={enableCSVDownload ? generateCSVDownloadUrl(urlParams) : undefined}
      sideBar={sideBar}
      onColumnVisible={onColumnVisible}
      onToolPanelVisibleChanged={onToolPanelVisibilityChanged}
    />
  );
};
