import { CircularProgress, Stack, Typography } from '@mui/material';
import { ProductTenor } from '@protos/product';
import { TradeLimit } from '@protos/user';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { useTradingTenorContext } from '@shared/contexts/TradingTenorProvider';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useEffect, useMemo, useRef } from 'react';
import { useTradingForm } from '../hooks/useTradingForm';
import { TradingPanel, useTradingContext } from '../TradingContext';
import { TradeSizeInput } from './TradeSizeInput';
import { TradingButtons } from './TradingButtons';
import { TradingFormControls } from './TradingFormControls';

interface TradingPanelProps {
  id: string;
  payload: TradingPanel;
  userTradeLimits: TradeLimit[];
}

export const TradingPanelComponent = ({ id, payload, userTradeLimits }: TradingPanelProps) => {
  const sizeInputRef = useRef<HTMLDivElement>(null);
  const { editWidgetPayloadById } = useDashboardContext();
  const { tenors } = useUserProductsAndTenorsContext();
  const monthlyTenors = useMemo(() => tenors.filter(tenor => tenor.frequency === 'monthly'), [tenors]);
  const {
    subscriptionSymbol,
    subscriptionSize,
    subscriptionExchange,
    setSubscriptionSymbol,
    setSubscriptionSize,
    setSubscriptionExchange,
    setPrices,
    unsubscribeSymbol,
  } = useTradingContext();
  const { getAllUsedTenors, setWidgetTenorMap, widgetTenorMap } = useTradingTenorContext();

  const currentWidgetTenorMap = useMemo(() => widgetTenorMap[id], [widgetTenorMap, id]);

  const {
    tradingForm,
    rawTradingAmount,
    isLoadingPanel: tradingFormLoadingPanel,
    tenorsNeedUpdate: tradingTenorsLoading,
    isTradeLimitBreached: tradingFormTradeLimitBreached,
    showUserInputError,
    allowedTradingProducts: tradingFormAllowedTradingProducts,
    availableTradingTenors,
    validTenors,
    validBackTenors,
    onTradingProductChange,
    onTenorChange,
    onExchangeChange,
    onTradeSizeChange,
    setIsLoadingPanel,
    setValidTenors,
    setValidBackTenors,
    getAvailableRightTenors,
  } = useTradingForm({ id, payload, userTradeLimits, monthlyTenors });

  //Highlight Size input field on widget load
  useEffect(() => {
    if (sizeInputRef.current) {
      sizeInputRef.current?.focus();
    }
  }, [sizeInputRef.current]);

  //Save widget based on user input in trading form and update widgetTenorMap
  useEffect(() => {
    const { selectedProduct, selectedTenor, tradeSize, selectedExchange, selectedTradingAccount } = tradingForm;
    if (!selectedProduct || !selectedTenor || !tradeSize || !selectedExchange || !selectedTradingAccount || tradingTenorsLoading) return;

    const rfqSubscriptionSymbol =
      typeof selectedTenor === 'string'
        ? `${selectedProduct}${selectedTenor}`
        : `${selectedProduct.split('spr')[0]}${selectedTenor.left}-${selectedProduct.split('spr')[0]}${selectedTenor.right}`;

    if (subscriptionSymbol === rfqSubscriptionSymbol && subscriptionSize === tradeSize && subscriptionExchange === selectedExchange) return;

    const isSpread = typeof selectedTenor === 'object';
    const tenorsUsed = isSpread
      ? [
          {
            left: availableTradingTenors.find(tenor => tenor?.code === selectedTenor?.left),
            right: availableTradingTenors.find(tenor => tenor?.code === selectedTenor?.right),
          },
        ]
      : [availableTradingTenors.find(tenor => tenor?.code === selectedTenor)];
    const nonUndefinedTenorsUsed = tenorsUsed.filter(tenor => tenor !== undefined) as ProductTenor[];

    setWidgetTenorMap(prev => ({
      ...prev,
      [id]: { isSpread, exchange: selectedExchange, tenorsUsed: nonUndefinedTenorsUsed, product: selectedProduct },
    }));
    setPrices({ buy: undefined, sell: undefined });

    const currentSubscriptionSymbolElements = `${subscriptionSymbol}-${subscriptionExchange}`.split('-');

    if (currentSubscriptionSymbolElements.length !== `${rfqSubscriptionSymbol}-${selectedExchange}`.split('-').length) {
      unsubscribeSymbol();
    } else {
      if (currentSubscriptionSymbolElements.length === 3 && typeof selectedTenor !== 'string') {
        if (
          `${selectedProduct.split('spr')[0]}${selectedTenor.left}` !== currentSubscriptionSymbolElements[0] ||
          `${selectedProduct.split('spr')[0]}${selectedTenor.right}` !== currentSubscriptionSymbolElements[1] ||
          currentSubscriptionSymbolElements[2] !== selectedExchange
        )
          unsubscribeSymbol();
      } else if (
        `${selectedProduct}${selectedTenor}` !== currentSubscriptionSymbolElements[0] ||
        selectedExchange !== currentSubscriptionSymbolElements[1]
      ) {
        unsubscribeSymbol();
      }
    }

    if (subscriptionSymbol !== rfqSubscriptionSymbol) setSubscriptionSymbol(rfqSubscriptionSymbol);
    if (subscriptionSize !== tradeSize) setSubscriptionSize(tradeSize);
    if (subscriptionExchange !== selectedExchange) setSubscriptionExchange(selectedExchange);

    editWidgetPayloadById(id, {
      selectedProduct,
      selectedTenor,
      tradeSize,
      selectedTradingAccount,
      selectedExchange,
    });

    if (tradingFormLoadingPanel) setIsLoadingPanel(false);
  }, [tradingForm, id, subscriptionSymbol, setWidgetTenorMap, availableTradingTenors, tradingTenorsLoading, subscriptionSize, subscriptionExchange]);

  const isTenorsFiltered = useMemo(() => validTenors.length !== availableTradingTenors.length, [validTenors.length, availableTradingTenors.length]);

  // Update Tenors based on WidgetTenorMap changes
  useEffect(() => {
    if (!currentWidgetTenorMap) return;

    if (currentWidgetTenorMap.isSpread) {
      const allUsedTenors = getAllUsedTenors(id, true, currentWidgetTenorMap.exchange, currentWidgetTenorMap.product) as {
        left: ProductTenor;
        right: ProductTenor;
      }[];
      const currentWidgetTenor = currentWidgetTenorMap.tenorsUsed[0] as { left: ProductTenor; right: ProductTenor };
      if (isTenorsFiltered) setValidTenors(availableTradingTenors);
      if (!currentWidgetTenor || !currentWidgetTenor.left || !currentWidgetTenor.right) return;

      const usedSpreadProductTenors = allUsedTenors.filter(t => t.left?.code === currentWidgetTenor.left?.code);
      const usedRightTenorCodes = usedSpreadProductTenors.map(t => t.right?.code);
      const availableRightTenors = getAvailableRightTenors(currentWidgetTenor.left?.code, usedRightTenorCodes);

      setValidBackTenors(availableRightTenors);
    } else {
      const allUsedTenors = getAllUsedTenors(id, false, currentWidgetTenorMap.exchange, currentWidgetTenorMap.product) as ProductTenor[];
      const availableNonSprTenors = availableTradingTenors.filter(tenor => !allUsedTenors.find(t => t?.code === tenor?.code));

      setValidTenors(availableNonSprTenors);
    }
  }, [currentWidgetTenorMap, id, availableTradingTenors, getAllUsedTenors, isTenorsFiltered]);

  // Remove widgetTenorMap when widget unmounts
  useEffect(() => {
    return () => {
      setWidgetTenorMap(prev => {
        const newWidgetTenorMap = { ...prev };
        delete newWidgetTenorMap[id];
        return newWidgetTenorMap;
      });
    };
  }, []);

  // Unsubscribe from subscription when widget unmounts
  useEffect(() => {
    return () => {
      if (subscriptionSymbol) {
        unsubscribeSymbol();
      }
    };
  }, [subscriptionSymbol, unsubscribeSymbol]);

  // Initialize or update the widgetTenorMap for this widget
  useEffect(() => {
    const { selectedProduct, selectedTenor, selectedExchange } = tradingForm;
    if (!selectedProduct || !selectedTenor || !selectedExchange) return;

    const isSpread = typeof selectedTenor === 'object';
    const tenorsUsed: any = isSpread
      ? [
          {
            left: availableTradingTenors.find(tenor => tenor?.code === selectedTenor?.left),
            right: availableTradingTenors.find(tenor => tenor?.code === selectedTenor?.right),
          },
        ]
      : [availableTradingTenors.find(tenor => tenor?.code === selectedTenor)];
    const nonUndefinedTenorsUsed = tenorsUsed.filter(tenor => (isSpread ? tenor?.left && tenor?.right : tenor !== undefined)) as ProductTenor[];

    setWidgetTenorMap(prev => ({
      ...prev,
      [id]: {
        isSpread,
        exchange: selectedExchange,
        tenorsUsed: nonUndefinedTenorsUsed,
        product: selectedProduct,
      },
    }));
  }, [id, tradingForm, availableTradingTenors]);

  if (tradingFormLoadingPanel)
    return (
      <Stack direction="column" flex={1} alignItems="center" justifyContent="center">
        <Typography fontSize={11} color="textSecondary" tabIndex={-1}>
          Loading...
        </Typography>
      </Stack>
    );

  return (
    <Stack display="flex" flexDirection="column" flex={1} alignItems="center" padding={1} justifyContent="space-between">
      <TradingFormControls
        tradingForm={tradingForm}
        allowedTradingProducts={tradingFormAllowedTradingProducts}
        validTenors={validTenors}
        validBackTenors={validBackTenors}
        userTradeLimits={userTradeLimits}
        onProductChange={onTradingProductChange}
        onTenorChange={onTenorChange}
        onExchangeChange={onExchangeChange}
      />

      <TradeSizeInput
        sizeInputRef={sizeInputRef}
        tradeSize={tradingForm.tradeSize}
        isTradeLimitBreached={tradingFormTradeLimitBreached}
        showUserInputError={showUserInputError}
        onTradeSizeChange={onTradeSizeChange}
        rawTradingAmount={rawTradingAmount}
      />

      {subscriptionSymbol && subscriptionSize ? (
        <TradingButtons
          rfqSubscriptionSymbol={subscriptionSymbol}
          rfqSubscriptionSize={subscriptionSize}
          rfSubscriptionExchange={subscriptionExchange}
          isDisabled={showUserInputError}
          tradingAccount={payload.selectedTradingAccount}
          tradeSize={tradingForm.tradeSize}
        />
      ) : (
        <CircularProgress size={10} />
      )}
    </Stack>
  );
};
