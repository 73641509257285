import { useCallback, useEffect, useState } from 'react';
import { ResearchReportsResponse, ReportItem } from '../types';
import { useAsync } from 'react-use';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { useApi } from '@hooks/useApi';

export const useReports = (widgetId: string, selectedReport?: ReportItem) => {
  const [availableReports, setAvailableReports] = useState<ResearchReportsResponse>();
  const [currentReport, setCurrentReport] = useState<string>('');
  const [currentSelected, setCurrentSelected] = useState<ReportItem | undefined>(selectedReport);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<Record<string, boolean>>({});
  const { editWidgetPayloadById } = useDashboardContext();

  const { apiClient } = useApi();

  useEffect(() => {
    if (selectedReport) {
      setCurrentSelected(selectedReport);
      setCurrentReport(selectedReport.report_link);

      if (availableReports) {
        const parentCategory = availableReports.find(
          category =>
            category.category === selectedReport.category &&
            category.report.some(report => report.report_name === selectedReport.report_name && report.report_link === selectedReport.report_link)
        );

        if (parentCategory) {
          setOpen(prev => ({ ...prev, [parentCategory.category]: true }));
        }
      }
    }
  }, [selectedReport, availableReports]);

  const onReportClick = (report: ReportItem) => {
    const isSameReportLink = report.report_link === currentReport;
    const isSameCategory = report.category === currentSelected?.category;

    setCurrentSelected(report);

    if (isSameReportLink && isSameCategory) return;
    editWidgetPayloadById(widgetId, { selectedReport: report });

    if (!isSameReportLink) {
      setIsLoading(true);
      setCurrentReport(report.report_link);
    }
  };

  const onParentReportClick = useCallback(
    (report: string) => {
      if (open[report]) {
        setOpen({ ...open, [report]: false });
      } else {
        setOpen({ ...open, [report]: true });
      }
    },
    [open]
  );

  useAsync(async () => {
    const reports = await apiClient?.getResearchReports();
    if (reports) {
      setAvailableReports(reports);
    }
  }, [apiClient]);

  const onToggleReportsList = () => {
    setIsVisible(!isVisible);
  };

  const onIframeLoad = () => {
    setIsLoading(false);
  };

  return {
    availableReports,
    currentReport,
    currentSelected,
    isVisible,
    isLoading,
    onReportClick,
    onToggleReportsList,
    onIframeLoad,
    onParentReportClick,
    open,
  };
};
