import { Box, Stack, styled } from '@mui/material';
import { CalendarDay, CALENDAR_COLUMNS } from '@protos/calendar';
import { filterColumnChooserFromMainMenu } from '@utils/contextMenuUtils';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 26px)',
    height: 'calc(100vh - 250px)',
  },
}));

const defaultColDef = {
  sortable: false,
  filter: false,
  floatingFilter: false,
  resizable: true,
} as ColDef;

interface HolidayViewProps {
  data: CalendarDay[];
}

export const HolidayView = ({ data }: HolidayViewProps) => {
  return (
    <Stack direction="column" flex={1} position="relative" sx={{ width: '100%', height: '100%' }}>
      <StyledBox className="ag-theme-balham-dark custom-grid">
        <AgGridReact
          autoSizeStrategy={{ type: 'fitGridWidth' }}
          suppressDragLeaveHidesColumns
          suppressColumnMoveAnimation
          columnDefs={[{ field: 'Date', headerName: 'Date' }, ...CALENDAR_COLUMNS]}
          defaultColDef={defaultColDef}
          getMainMenuItems={filterColumnChooserFromMainMenu}
          rowData={data.filter(row =>
            Object.values(row)
              .slice(1)
              .some(day => day && day !== 'Weekend')
          )}
          containerStyle={{ height: 'calc(100vh - 100px)', width: '100%' }}
        />
      </StyledBox>
    </Stack>
  );
};
