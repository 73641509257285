import { streamV2OrderBookTop } from '@services/context';
import { OrderBookTopCallback } from '@services/v2/streamV2OrderBookTopService';
import { useCallback, useEffect, useRef } from 'react';

export const useStreamV2OrderBookTopQuotes = (callback: OrderBookTopCallback, allProductsToBeSubscribed: string[]) => {
  const currentSubscribed = useRef<string[]>([]);
  const target = useRef<OrderBookTopCallback>(callback);

  const updateSubscriptions = useCallback(() => {
    const toUnsubscribe = currentSubscribed.current.filter(symbol => !allProductsToBeSubscribed.includes(symbol));
    const toSubscribe = allProductsToBeSubscribed.filter(symbol => !currentSubscribed.current.includes(symbol));

    if (toUnsubscribe.length > 0) {
      streamV2OrderBookTop.unsubscribe(toUnsubscribe, target.current);
      currentSubscribed.current = currentSubscribed.current.filter(symbol => !toUnsubscribe.includes(symbol));
    }

    if (toSubscribe.length > 0) {
      streamV2OrderBookTop.subscribe(toSubscribe, target.current);
      currentSubscribed.current = [...currentSubscribed.current, ...toSubscribe];
    }
  }, [allProductsToBeSubscribed]);

  useEffect(() => {
    target.current = callback;
  }, [callback]);

  useEffect(() => {
    updateSubscriptions();
    return () => {
      if (currentSubscribed.current.length > 0) {
        streamV2OrderBookTop.unsubscribe(currentSubscribed.current, target.current);
        currentSubscribed.current = [];
      }
    };
  }, [allProductsToBeSubscribed, updateSubscriptions]);

  const unsubscribeProducts = useCallback((products: string[]) => {
    const toUnsubscribe = products.filter(product => currentSubscribed.current.includes(product));

    if (toUnsubscribe.length > 0) {
      streamV2OrderBookTop.unsubscribe(toUnsubscribe, target.current);
      currentSubscribed.current = currentSubscribed.current.filter(symbol => !toUnsubscribe.includes(symbol));
    }
  }, []);

  const unsubscribeAllSymbols = useCallback(() => {
    if (currentSubscribed.current.length > 0) {
      streamV2OrderBookTop.unsubscribe(currentSubscribed.current, target.current);
      currentSubscribed.current = [];
    }
  }, []);

  return { unsubscribeProducts, unsubscribeAllSymbols };
};
