import { ProductAlertsActionsCellRenderer } from '@features/reference/products/components/ProductAlertsActionsCellRenderer';
import { ProductContractsActionsCellRenderer } from '@features/reference/products/components/ProductContractsActionsCellRenderer';
import { getAlertLevelStyles } from '@shared/styles/alertStyles';
import { formatDatetime } from '@utils/date';
import { ColDef } from 'ag-grid-enterprise';
import { Alert } from './alerts';

type Settlement = {
  source: string;
  code: string;
  desc?: string;
};

export type FlatProduct = {
  contract_size: number;
  min_size: number;
  max_size: number;
  barrels_per_metric_ton: number;
  barrels_per_mmbtu: number | null;
  holiday_calendar: string;
  option_strike: number;
  option_type: FlatProductOptionType;
  settlement: FlatProductSettlement;
};

type FlatProductOptionType = 'unspecified' | 'call' | 'put';
type FlatProductSettlement = { source: string; code: string; desc: string };

export type ExchangeSymbol = {
  exchange: string;
  symbol: string;
  cqg_symbol: string;
  tt_symbol: string;
  clearing_venue: string;
  is_balmo: boolean;
  name: string;
  contract_size: number;
  min_size: number;
  max_size: number;
  holiday_calendar: string;
};

export type ProductWeight = {
  product: Product;
  weight: number;
};

export type Contract = {
  product_symbol: string;
  symbol: string;
  expiry: string;
  first_settlement: string;
  last_settlement: string;
  id: number;
  product_id: number;
  tenor_code: string;
};

export type Composition = {
  weight: number;
  product: Product;
};

type DefaultExchange = 'cme' | 'ice' | 'unspecified' | 'onyx';

export type Product = {
  symbol: string;
  full_name: string;
  short_name: string;
  id: number;
  product_type: string;
  product_group: string;
  calendar_type: string;
  mdx_name: string;
  currency: string;
  multiplier: number;
  currency_unit: string;
  underlying_symbol: string;
  exchange_symbols: ExchangeSymbol[];
  settlement?: Settlement;
  specification: FlatProduct | null;
  tenor_frequency: string;
  quantity_unit: string;
  pricing_tenors: number;
  composition: Composition[];
  contracts: Contract[];
  tick_size: number;
  enabled: boolean;
  private: boolean;
  tradable: boolean;
  default_exchange: DefaultExchange | null;
};

export type TenorFrequency = 'daily' | 'weekly' | 'monthly' | 'quarterly' | 'yearly';

export type ProductTenor = {
  code: string;
  display: string;
  frequency: TenorFrequency;
  spread_code?: string;
};

export const productMaps = (products: Product[], tenors: ProductTenor[]) => {
  const productMap = products.reduce((acc, product) => {
    acc[product.symbol] = product;
    return acc;
  }, {});

  const tenorMap = tenors.reduce((acc, tenor) => {
    acc[tenor.code] = tenor;
    if (tenor.spread_code) acc[tenor.spread_code] = tenor;
    return acc;
  }, {});

  return {
    productMap,
    tenorMap,
  };
};

export const ADMIN_PRODUCT_COMPOSITIONS_COLUMNS: ColDef<Composition>[] = [
  {
    field: 'weight',
    headerName: 'Weight',
    resizable: true,
    headerTooltip: 'Weight',
  },
  {
    colId: 'symbol',
    field: 'product.symbol',
    headerName: 'Symbol',
    headerTooltip: 'Symbol',
    flex: 1,
  },
  {
    field: 'product.full_name',
    headerName: 'Name',
    resizable: true,
    flex: 1,
    headerTooltip: 'Product Full Name',
  },
];

export const ADMIN_PRODUCT_CONTRACTS_COLUMNS: ColDef<Contract>[] = [
  {
    field: 'symbol',
    headerName: 'Symbol',
    headerTooltip: 'Symbol',
    width: 110,
  },
  {
    field: 'expiry',
    headerName: 'Expiry',
    resizable: true,
    headerTooltip: 'Product Symbol Expiry',
    valueFormatter: ({ value }) => (value ? formatDatetime(value) : ''),
    flex: 1,
  },
  {
    headerName: 'Actions',
    cellRenderer: ProductContractsActionsCellRenderer,
    pinned: 'right',
  },
];

export const ADMIN_PRODUCT_EXCHANGE_SYMBOLS_COLUMNS: ColDef<ExchangeSymbol>[] = [
  {
    field: 'exchange',
    headerName: 'Exchange',
    headerTooltip: 'Exchange',
    width: 90,
  },
  {
    field: 'symbol',
    headerName: 'Symbol',
    headerTooltip: 'Symbol',
    width: 90,
  },
  {
    field: 'cqg_symbol',
    headerName: 'CQG Symbol',
    headerTooltip: 'CQG Symbol',
    width: 90,
  },
  {
    field: 'tt_symbol',
    headerName: 'TT Symbol',
    headerTooltip: 'TT Symbol',
    width: 90,
  },
  {
    field: 'clearing_venue',
    headerName: 'Clearing Venue',
    headerTooltip: 'Clearing Venue',
    width: 110,
  },
  {
    field: 'is_balmo',
    headerName: 'Is Balmo',
    headerTooltip: 'Is Balmo',
    cellDataType: 'text',
    width: 90,
  },
  {
    field: 'name',
    headerName: 'Name',
    headerTooltip: 'Name',
    width: 90,
  },
  {
    field: 'contract_size',
    headerName: 'Contract Size',
    headerTooltip: 'Contract Size',
    width: 90,
  },
  {
    field: 'min_size',
    headerName: 'Min Size',
    headerTooltip: 'Min Size',
    width: 90,
  },
  {
    field: 'max_size',
    headerName: 'Max size',
    headerTooltip: 'Max size',
    width: 90,
  },
  {
    field: 'holiday_calendar',
    headerName: 'Holiday Calendar',
    headerTooltip: 'Holiday Calendar',
    width: 120,
  },
];

export const ADMIN_PRODUCT_ALERTS_COLUMNS: ColDef<Alert>[] = [
  {
    field: 'group',
    headerName: 'Group',
    headerTooltip: 'Group',
    width: 90,
  },
  {
    field: 'counter',
    headerName: 'Counter',
    headerTooltip: 'Counter',
    width: 90,
  },
  {
    field: 'level',
    headerName: 'Level',
    headerTooltip: 'Level',
    width: 90,
    cellStyle: ({ value }) => {
      const { color } = getAlertLevelStyles(value);
      return { backgroundColor: color };
    },
  },
  {
    field: 'message',
    headerName: 'Message',
    headerTooltip: 'Message',
    flex: 1,
  },
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    headerTooltip: 'Timestamp',
    valueFormatter: ({ value }) => (value ? formatDatetime(value) : ''),
    width: 150,
  },
  {
    headerName: 'Actions',
    cellRenderer: ProductAlertsActionsCellRenderer,
    cellStyle: { display: 'flex', justifyContent: 'center' },
    pinned: 'right',
  },
];

export const ADMIN_PRODUCT_RELATED_COLUMNS: ColDef<Product>[] = [
  {
    field: 'symbol',
    headerName: 'Symbol',
    headerTooltip: 'Symbol',
    width: 90,
  },
  {
    field: 'product_group',
    headerName: 'Product Group',
    headerTooltip: 'Product Group',
    width: 90,
  },
  {
    field: 'product_type',
    headerName: 'Product Type',
    headerTooltip: 'Product Type',
    width: 90,
  },
  {
    field: 'full_name',
    headerName: 'Product Name',
    headerTooltip: 'Full porduct Name',
    flex: 1,
  },
];
