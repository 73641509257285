import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton } from '@mui/material';
import { Order } from '@protos/v2/order';
import theme from '@shared/themes/darkTheme';
import { ICellRendererParams } from 'ag-grid-community';
import { isNotLiveOrder } from '../constants/AllOrdersGridConsts';
import { useLaddersApi } from '../hooks/useLaddersApi';
import { useCallback } from 'react';
import React from 'react';

const iconButtonStyle = {
  marginBottom: '2px',
  height: '15px',
  lineHeight: 1,
};

const cancelIconStyle = {
  fontSize: 15,
  padding: 0,
};

export const CancelOrderCellRenderer = React.memo((props: ICellRendererParams<Order> & { accountId: string }) => {
  const { expireLadder } = useLaddersApi();
  const isNotLive = props.node.data && isNotLiveOrder(props.node.data);

  const handleCancel = useCallback(async () => {
    const symbol = typeof props.data?.symbol === 'string' ? props.data.symbol : `${props.data?.symbol['front']}-${props.data?.symbol['back']}`;

    try {
      await expireLadder(symbol, props.accountId);
    } catch (e) {
      console.error(e);
    }
  }, [expireLadder, props.accountId, props.data?.symbol]);

  return (
    <IconButton color="error" onClick={handleCancel} disabled={isNotLive} sx={iconButtonStyle}>
      <CancelIcon
        sx={{
          ...cancelIconStyle,
          color: theme.palette.background.sell,
          '&:hover': { color: 'white' },
          '.Mui-disabled &': { color: 'gray' },
        }}
      />
    </IconButton>
  );
});
