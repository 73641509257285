import { useAdminApi } from '@hooks/useAdminApi';
import { useStreamV2ProductRisk } from '@hooks/useStreamV2ProductRisk';
import { Stack } from '@mui/material';
import { ContractRisk } from '@protos/trading';
import { ProductRiskMessage } from '@protos/v2/productRisk';
import { config } from '@services/context';
import { Select } from '@shared/components/Select';
import { useTradableProductSymbolsContext } from '@shared/contexts/TradableProductSymbolsProvider';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useCallback, useEffect, useState } from 'react';
import { transformRiskResponse } from '../../tradingAdminUtils';
import { TradingRiskReconGrid } from './TradingRiskReconGrid';

const DEFAULT_PRODUCT_SYMBOL = 'BRT';

export const TradingRiskRecon = () => {
  const { apiClient } = useAdminApi();
  const { tenors, loaded: isTenorsLoaded } = useUserProductsAndTenorsContext();
  const products = useTradableProductSymbolsContext();
  const [liveRiskdata, setLiveRiskData] = useState<ContractRisk[]>([]);
  const [dbRiskdata, setDbRiskData] = useState<ContractRisk[]>([]);
  const [metaData, setMetaData] = useState<{ version: number; timestamp: string } | undefined>();
  const [selectedProduct, setSelectedProduct] = useState<string>('');

  useEffect(() => {
    const product = localStorage.getItem('selectedProduct');
    setSelectedProduct(product || DEFAULT_PRODUCT_SYMBOL);
  }, []);

  useEffect(() => {
    fetchDbRisk();
    const interval = setInterval(() => {
      fetchDbRisk();
    }, 5000);

    return () => clearInterval(interval);
  }, [apiClient, isTenorsLoaded, selectedProduct]);

  const onProductChange = (product: string) => {
    setSelectedProduct(product);
    unsubscribeRisk();
    setLiveRiskData([]);
    setDbRiskData([]);
    setMetaData(undefined);
    localStorage.setItem('selectedProduct', product);
  };

  const onRiskStreaming = useCallback(
    (risk: ProductRiskMessage) => {
      if (!isTenorsLoaded) return;

      const { version: currentVersion } = metaData || {};
      if (currentVersion && risk.version <= currentVersion) return;

      const { data, version, timestamp } = transformRiskResponse(risk, tenors);
      setLiveRiskData(data);
      if (version && timestamp) setMetaData({ version, timestamp });
    },
    [tenors, isTenorsLoaded, metaData, selectedProduct]
  );

  const fetchDbRisk = async () => {
    if (!apiClient || !isTenorsLoaded) return;
    try {
      const dbRisk = await apiClient.getTradingAdminRisk(config.otcAccountId, selectedProduct.toLowerCase());
      const { data } = transformRiskResponse(dbRisk, tenors);
      setDbRiskData(data);
    } catch (e) {
      console.error(e);
    }
  };

  const unsubscribeRisk = useStreamV2ProductRisk(
    onRiskStreaming,
    selectedProduct.toLowerCase(),
    config.otcAccountId,
    !!config.otcAccountId && isTenorsLoaded && tenors.length > 0
  );

  return (
    <Stack width="100%">
      <Select label="Product" options={products.map(p => p.toUpperCase())} onChange={onProductChange} value={selectedProduct} />
      <TradingRiskReconGrid liveData={liveRiskdata} dbData={dbRiskdata} />
    </Stack>
  );
};
