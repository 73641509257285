import { Filter, FilterType } from '@protos/filter';
import { handleOrderIdCellClick } from '@services/TradingGridService';
import { TRADING_ADMIN_ORDER_FILTERS } from '@shared/components/TradingOrdersGrid/TradingOrderGridConsts';
import { TradingOrdersGrid } from '@shared/components/TradingOrdersGrid/TradingOrdersGrid';
import { useTradableProductSymbolsContext } from '@shared/contexts/TradableProductSymbolsProvider';
import { CellClickedEvent } from 'ag-grid-enterprise';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export const TradingOrders = () => {
  const navigate = useNavigate();
  const products = useTradableProductSymbolsContext();
  const nonSpreadProducts = useMemo(() => products.filter(product => !product.includes('spr')), [products]);

  const filters: Filter[] = useMemo(
    () => [
      { type: FilterType.AUTOCOMPLETE, name: 'product_symbol', label: 'Product Symbol', options: nonSpreadProducts },
      ...TRADING_ADMIN_ORDER_FILTERS,
    ],
    [nonSpreadProducts]
  );

  const onCellClicked = useCallback((params: CellClickedEvent) => handleOrderIdCellClick(params, navigate), [navigate]);

  if (filters[0].options?.length === 0) return null;

  return <TradingOrdersGrid maxHeight={1000} filters={filters} enableCSVDownload={true} onCellClicked={onCellClicked} />;
};
