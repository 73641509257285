import { SnapshotCallback } from '@protos/v2/snapshots';
import { ChannelType, StreamEvent, StreamV2, Subscription } from '../StreamV2';

export const streamV2SnapshotsService = (stream: StreamV2) => {
  const eventCallbacks = new Set<SnapshotCallback>();

  stream.onEvent(ChannelType.Snapshots, (event: StreamEvent) => {
    const snapshot = event.asSnapshots();
    if (snapshot) eventCallbacks.forEach(callback => callback(snapshot));
  });

  const subscribe = (callback: SnapshotCallback) => {
    stream.subscribe(Subscription.snapshots());
    eventCallbacks.add(callback);
  };

  const unsubscribe = (callback: SnapshotCallback) => {
    eventCallbacks.delete(callback);
    if (eventCallbacks.size === 0) stream.unsubscribe(Subscription.snapshots());
  };

  return {
    subscribe,
    unsubscribe,
  };
};
