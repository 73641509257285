import { useAdminApi } from '@hooks/useAdminApi';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { LadderLimit, User } from '@protos/user';
import { TextField } from '@shared/components/TextField';
import { TextInputSelectBox } from '@shared/components/TextFieldSelectBox';
import { useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import { StyledIconButton } from './StyledIconButton';

interface EditLadderLimitsDialogProps {
  user: User;
  isOpen: boolean;
  onClose: () => void;
  onUserUpdated: (user: User) => void;
}

const NumberRegex = new RegExp('^[0-9]*$');

export const EditLadderLimitsDialog: React.FC<EditLadderLimitsDialogProps> = ({ user, isOpen, onClose, onUserUpdated }) => {
  const { apiClient } = useAdminApi();
  const [ladderLimits, setLadderLimits] = useState<LadderLimit[]>([...user.ladder_limits]);
  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
  }, [ladderLimits]);

  const state = useAsync(async () => {
    const response = await apiClient?.productsLoader({ has_input: true }).loadAllData();
    return response?.map(product => product.symbol) || [];
  }, [apiClient]);

  const onSave = async () => {
    setError('');
    if (apiClient && user) {
      try {
        await apiClient.updateUserLimits(user.id, { ladder_limits: ladderLimits }).then(data => {
          setLadderLimits(data.ladder_limits);
          onUserUpdated(data);
        });
      } catch (e) {
        setError('Some input values are not valid.');
        return;
      }
    }
    onClose();
  };

  const onLadderLimitAdd = () => {
    setLadderLimits([...ladderLimits, { symbol: '', limit: 0 }]);
  };

  const onLadderLimitRemove = (ladderId: number) => {
    const updatedLadders = [...ladderLimits];
    updatedLadders.splice(ladderId, 1);
    setLadderLimits(updatedLadders);
  };

  const onLadderLimitUpdate = (ladderId: number, updatedValue: LadderLimit) => {
    const updatedLadders = [...ladderLimits];
    updatedLadders.splice(ladderId, 1, updatedValue);
    setLadderLimits(updatedLadders);
  };

  useEffect(() => {
    setLadderLimits(user.ladder_limits);
  }, [user, isOpen]);

  if (state.loading || !state.value) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      disableEnforceFocus={false}
      disableAutoFocus={false}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          onSave();
        },
      }}
    >
      <DialogTitle>Edit Ladder Limits</DialogTitle>
      <DialogContent>
        <Box display="flex" marginRight={2} gap={1}>
          <Stack direction="column" alignItems="flex-start">
            {ladderLimits.map((ladderLimit, id) => (
              <Stack key={`ladder_limit_${ladderLimit.symbol}`} direction="row">
                <TextInputSelectBox
                  label="Symbol"
                  value={ladderLimit.symbol}
                  sx={{ width: '100px' }}
                  onChange={(value: string) => onLadderLimitUpdate(id, { ...ladderLimit, symbol: value })}
                  options={state.value.filter(p => p === ladderLimit.symbol || !ladderLimits.map(t => t.symbol).includes(p))}
                />
                <div style={{ margin: '0 15px' }}>
                  <TextField
                    label="Limit"
                    value={ladderLimit.limit.toString()}
                    error={ladderLimit.limit === 0}
                    sx={{ width: '100px' }}
                    onChange={(value: string) => {
                      if (NumberRegex.test(value)) onLadderLimitUpdate(id, { ...ladderLimit, limit: Number(value) });
                    }}
                  />
                </div>
                <StyledIconButton onClick={() => onLadderLimitRemove(id)} size={'small'} style={{ marginLeft: '5px' }}>
                  <i className={'ri-delete-bin-line'} />
                </StyledIconButton>
              </Stack>
            ))}
            <StyledIconButton onClick={onLadderLimitAdd}>
              <i className={'ri-add-line'} />
            </StyledIconButton>
            {error && (
              <Typography color="red" fontSize={11} style={{ alignSelf: 'center' }}>
                {error}
              </Typography>
            )}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} size="small" style={{ fontSize: 11 }}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" size="small" style={{ fontSize: 11 }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
