import { useApi } from '@hooks/useApi';
import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Box, Divider, Stack } from '@mui/material';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import NumberInput from '../components/NumberInput';
import { MixedGrid } from './MixedGrid';
import { SharedTenorSettings } from './SharedTenorSettings';
import { SharedTenorSettingsProvider } from './SharedTenorSettingsContext';

interface MixedProps {
  selectedAccount: string;
  setSelectedAccount: (account: string) => void;
  isLive: boolean;
}

interface GridData {
  [gridId: string]: {
    [tenorId: string]: {
      value: string;
      spread: string;
      size: string;
    };
  };
}

export const Mixed = ({ selectedAccount, setSelectedAccount }: MixedProps) => {
  const { apiClient } = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [gridData, setGridData] = useState<GridData>({});
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);
  const [midPriceValidity, setMidPriceValidity] = useState('86400');
  const user = useUserContext();
  const { urlParams, resetURLSearchParams } = useURLSearchParams();

  const grid1Ref = useRef<AgGridReact>(null);
  const grid2Ref = useRef<AgGridReact>(null);
  const grid3Ref = useRef<AgGridReact>(null);

  const handleNavigateToNextGrid = useCallback((gridId: string) => {
    return (rowIndex: number) => {
      setSelectedRowIndex(rowIndex);
      const column = allMixedGrids
        .find(grid => grid.id === gridId)
        ?.gridRef.current?.api.getAllDisplayedColumns()[0]
        ?.getColId();
      allMixedGrids.find(grid => grid.id === gridId)?.gridRef.current?.api.setFocusedCell(rowIndex, column || '');
    };
  }, []);

  const handleNavigateToPreviousGrid = useCallback((gridId: string) => {
    return (rowIndex: number) => {
      setSelectedRowIndex(rowIndex);
      const column = allMixedGrids
        .find(grid => grid.id === gridId)
        ?.gridRef.current?.api.getAllDisplayedColumns()
        .slice(-1)[0]
        ?.getColId();
      allMixedGrids.find(grid => grid.id === gridId)?.gridRef.current?.api.setFocusedCell(rowIndex, column || '');
    };
  }, []);

  const allMixedGrids = [
    {
      id: 'grid1',
      isShowTenors: true,
      gridRef: grid1Ref,
      onNavigateToGrid: handleNavigateToNextGrid('grid2'),
      onNavigateToPreviousGrid: handleNavigateToPreviousGrid('grid3'),
      selectedRowIndex,
      onRowIndexChange: setSelectedRowIndex,
    },
    {
      id: 'grid2',
      isShowTenors: false,
      gridRef: grid2Ref,
      onNavigateToGrid: handleNavigateToNextGrid('grid3'),
      onNavigateToPreviousGrid: handleNavigateToPreviousGrid('grid1'),
      selectedRowIndex,
      onRowIndexChange: setSelectedRowIndex,
    },
    {
      id: 'grid3',
      isShowTenors: false,
      gridRef: grid3Ref,
      onNavigateToGrid: handleNavigateToNextGrid('grid1'),
      onNavigateToPreviousGrid: handleNavigateToPreviousGrid('grid2'),
      selectedRowIndex,
      onRowIndexChange: setSelectedRowIndex,
    },
  ];

  useEffect(() => {
    if (selectedAccount || !user?.abn_trad_accounts.length) return;

    if (urlParams?.account_id && user.abn_trad_accounts.includes(urlParams.account_id)) {
      setSelectedAccount(urlParams.account_id);
    } else {
      setSelectedAccount(user.abn_trad_accounts[0]);
    }
  }, [urlParams, selectedAccount, user, setSelectedAccount]);

  useEffect(() => {
    if (selectedAccount) {
      const newParams = {
        ...urlParams,
        account_id: selectedAccount,
      };
      resetURLSearchParams(newParams);
    }
  }, [selectedAccount, urlParams, resetURLSearchParams]);

  useEffect(() => {
    if (!apiClient) return;

    const loadMixedGridSettings = async () => {
      const settings = await apiClient.getUserSettings();
      if (settings?.ocl_trading?.mixed?.mixedGridData) {
        setGridData(settings.ocl_trading.mixed.mixedGridData);
      }
      setIsLoading(false);
    };

    loadMixedGridSettings();
  }, [apiClient]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Stack width="100%" height="100%" padding={1} gap={1}>
      <SharedTenorSettingsProvider>
        <Box display="flex" flex={1} alignItems="center" justifyContent="space-between" sx={{ padding: 1 }}>
          <Box display="flex" alignItems="center" gap={1}>
            <NumberInput
              value={midPriceValidity}
              onChange={e => setMidPriceValidity(e?.toString() ?? '')}
              label="Mid Validity (s)"
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: '11px',
                  '--Input-minHeight': '0',
                },
                '& .MuiFormLabel-root': {
                  fontSize: '11px',
                  whiteSpace: 'nowrap',
                  transform: 'translate(8px, -12px) scale(0.75)',
                },
                '& .MuiInputBase-root': {
                  borderRadius: '0px',
                  height: 25,
                },
              }}
            />
          </Box>
          <SharedTenorSettings />
        </Box>
        <Divider />

        <Box display="flex" flex={1} width="100%" height="calc(100vh - 150px)" gap={1} alignItems="start">
          {allMixedGrids.map(grid => (
            <MixedGrid
              key={grid.id}
              gridId={grid.id}
              midPriceValidity={midPriceValidity}
              isShowTenors={grid.isShowTenors}
              selectedAccount={selectedAccount}
              savedData={gridData[grid.id]}
              onNavigateToGrid={grid.onNavigateToGrid}
              onNavigateToPreviousGrid={grid.onNavigateToPreviousGrid}
              gridRef={grid.gridRef}
              selectedRowIndex={grid.selectedRowIndex}
              onRowIndexChange={grid.onRowIndexChange}
            />
          ))}
        </Box>
      </SharedTenorSettingsProvider>
    </Stack>
  );
};
