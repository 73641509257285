import { CardContent, Typography, useMediaQuery } from '@mui/material';
import theme from '@shared/themes/darkTheme';

type Props = {
  header: string;
  value?: string;
};

export const TradingVarCardContent: React.FC<Props> = ({ header, value }) => {
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <CardContent style={{ padding: isMdScreen ? 8 : 25, marginTop: isMdScreen ? 5 : 13, textAlign: 'center', textWrap: 'nowrap' }}>
      <Typography variant={'h6'}>{header}</Typography>
      <Typography variant={'h3'}>{value || '...'}</Typography>
    </CardContent>
  );
};
