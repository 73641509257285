import { useUserPage } from '@features/admin/users/hooks/useUserPage';
import { UserAdminProfileForm } from '@features/user-account/manage/UserAdminProfileForm';
import { Box } from '@mui/material';
import React from 'react';

const UserPage: React.FC = () => {
  const { user } = useUserPage();

  if (!user) return null;
  return (
    <Box margin={2} paddingTop={2} marginTop={0}>
      <UserAdminProfileForm user={user} />
    </Box>
  );
};

export default UserPage;
