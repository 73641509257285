import { ProductTenor } from '@protos/product';
import { OtcExchange } from '@protos/v2/otcQuote';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

type TradingTenorState = {
  widgetTenorMap: Record<
    string,
    { isSpread: boolean; exchange: OtcExchange; tenorsUsed: (ProductTenor | { left: ProductTenor; right: ProductTenor })[]; product: string }
  >;
  setWidgetTenorMap: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        {
          isSpread: boolean;
          exchange: OtcExchange;
          tenorsUsed: (ProductTenor | { left: ProductTenor; right: ProductTenor })[];
          product: string;
        }
      >
    >
  >;
  getAllUsedTenors: (
    id: string,
    isSpread: boolean,
    currentExchange: OtcExchange,
    currentProduct: string
  ) => (ProductTenor | { left: ProductTenor; right: ProductTenor })[];
};

type Props = {
  children: ReactNode;
};

const TradingTenorContext = createContext<TradingTenorState | undefined>(undefined);
TradingTenorContext.displayName = 'TradingTenorContext';

export default function TradingTenorProvider({ ...otherProps }: Props) {
  const [widgetTenorMap, setWidgetTenorMap] = useState<
    Record<
      string,
      { isSpread: boolean; exchange: OtcExchange; tenorsUsed: (ProductTenor | { left: ProductTenor; right: ProductTenor })[]; product: string }
    >
  >({});

  const getAllUsedTenors = useCallback(
    (id: string, isSpread: boolean, currentExchange: OtcExchange, currentProduct: string) => {
      const allUsedTenors: Set<ProductTenor | { left: ProductTenor; right: ProductTenor }> = new Set();
      Object.entries(widgetTenorMap).forEach(([widgetId, { isSpread: spread, tenorsUsed, exchange, product }]) => {
        if (widgetId === id) return;

        if (spread === isSpread && exchange === currentExchange && product === currentProduct) {
          tenorsUsed.forEach(tenor => allUsedTenors.add(tenor));
        }
      });

      return Array.from(allUsedTenors);
    },
    [widgetTenorMap]
  );

  const value: TradingTenorState = useMemo(
    () => ({
      widgetTenorMap,
      setWidgetTenorMap,
      getAllUsedTenors,
    }),
    [widgetTenorMap, getAllUsedTenors]
  );

  return <TradingTenorContext.Provider value={value} {...otherProps} />;
}

export function useTradingTenorContext(): TradingTenorState {
  const context = useContext(TradingTenorContext);

  if (!context) {
    throw new Error('useTradingTenorContext must be used within a TradingTenorProvider');
  }

  return context;
}
