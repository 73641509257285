import { SnapshotCallback } from '@protos/v2/snapshots';
import { streamV2Snapshots } from '@services/context';
import { useEffect, useRef } from 'react';

export const useSnapshotsV2Streaming = (callback: SnapshotCallback, isReadyToSubscribe: boolean) => {
  const target = useRef<SnapshotCallback>(callback);

  useEffect(() => {
    if (!isReadyToSubscribe) return;

    target.current = callback;
    streamV2Snapshots.subscribe(target.current);

    return () => {
      streamV2Snapshots.unsubscribe(callback);
    };
  }, [isReadyToSubscribe, callback]);
};
